// Divider component

export default function Divider() {
  // Default styles for the divider
  const defaultStyle = {
    width: '100%',
    height: '1px',
    backgroundColor: '#2c393f',
    margin: '0 auto',
    opacity: 0.5,
    marginTop: '1rem',
    marginBottom: '1rem'
  };

  // Combine default styles with any custom styles passed as props
  const combinedStyle = { ...defaultStyle };

  return <div style={combinedStyle}></div>;
}
