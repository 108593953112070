import {
  EmptyBox,
  formatDatetime,
  formatPercentage,
  LineChart as LineChartComponent
} from '@blockanalitica/ui';
import { ChartPoint } from '@blockanalitica/ui/package/esm/src/components/client-components/charts/ChartJS';
import { EmptyBoxProps } from '@blockanalitica/ui/package/esm/src/components/client-components/EmptyBox/EmptyBox';
import { ChartConfiguration, ChartType } from 'chart.js';
import { useMemo, useState } from 'react';
import { DeepPartial } from 'utility-types';

type Field = {
  label: string;
  field: string;
  backgroundColor?: string | number;
};

type LineChartData = {
  date: string;
} & {
  [key in string as Exclude<key, 'date'>]: number;
};

type LineChartOptions = {
  emptyBoxProps?: EmptyBoxProps;
};

interface LineChartProps {
  data: LineChartData[];
  fields: Field[];
  currency?: string;
  switchValue?: string;
  aspectRatio?: number;
  daysAgo?: number;
  options?: LineChartOptions;
}

export default function PercentageLineChart({
  data,
  fields,
  aspectRatio = 4,
  daysAgo = 30,
  options
}: LineChartProps) {
  const [oldAspectRatio, setOldAspectRatio] = useState<number | null>(null);
  let unit = 'week';
  let dateFormat = 'MMM d';
  let dateFormatKPI = 'MMM d, yyyy';
  switch (Number(daysAgo)) {
    case 1:
      unit = 'hour';
      dateFormat = 'H:mm';
      dateFormatKPI = 'MMM d H:mm';
      break;
    case 7:
      unit = 'day';
      break;
    case 365:
      unit = 'week';
      dateFormat = 'MMM d';
      break;
  }
  const chartData = useMemo(() => {
    if (data.length) {
      return {
        datasets: fields.map((fieldInfo) => ({
          label: fieldInfo.label,
          data: data.map((item) => ({
            x: Number(daysAgo) === 1 ? item.datetime : item.date,
            y: item[fieldInfo.field],
            label: fieldInfo.label
          })),
          borderColor:
            fieldInfo.backgroundColor && fieldInfo.backgroundColor !== ''
              ? fieldInfo.backgroundColor
              : '#15ffab',
          borderWidth: 4,
          backgroundColor: function (context) {
            // Check if there is exactly one fieldInfo
            if (fields.length === 1) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return null;
              }
              const gradient = ctx.createLinearGradient(
                0,
                chartArea.bottom,
                0,
                chartArea.top
              );
              gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
              gradient.addColorStop(1, 'rgba(21, 225, 171, .4)');

              return gradient;
            } else {
              // Return transparent when there are multiple fields
              return 'rgba(0, 0, 0, 0)';
            }
          }
        }))
      };
    }

    return null;
  }, [data, fields, daysAgo]);

  const kpisConfig = useMemo(() => {
    const valueFormatter = (value: number) => (
      <span className="text-mono">{formatPercentage(value)}</span>
    );

    if (chartData) {
      const defaultData = chartData.datasets.map((ds) => ({
        y: ds.data[ds.data.length - 1].y,
        x: ds.data[ds.data.length - 1].x,
        label: ds.data[ds.data.length - 1].label
      }));
      return {
        valueFormatter: valueFormatter,
        subValueFormatter: (value: string | number) => (
          <span> {formatDatetime(value, { format: dateFormatKPI })}</span>
        ),
        defaultData: defaultData
      };
    }

    return null;
  }, [chartData, dateFormatKPI]);

  const config: DeepPartial<
    ChartConfiguration<ChartType, number[] | ChartPoint[], string>
  > = useMemo(
    () => ({
      options: {
        aspectRatio: aspectRatio,
        onResize: (chart) => {
          const windowWidth = window.innerWidth;
          if (windowWidth < 576 && chart.options.aspectRatio !== 1.5) {
            if (oldAspectRatio === null) {
              setOldAspectRatio(chart.options.aspectRatio);
            }
            chart.options.aspectRatio = 1.5;
          } else if (windowWidth >= 576 && oldAspectRatio !== null) {
            chart.options.aspectRatio = oldAspectRatio;
            setOldAspectRatio(null); // Reset oldAspectRatio
          }
          chart.update();
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: unit
            },
            ticks: {
              callback: (value: number) =>
                formatDatetime(value, { format: dateFormat })
            }
          },
          y: {
            type: 'linear',
            stacked: false,
            ticks: {
              format: { notation: 'compact' },
              callback: (value: number) => formatPercentage(value)
            }
          }
        }
      }
    }),
    [aspectRatio, oldAspectRatio, dateFormat, unit]
  );

  if (chartData && kpisConfig) {
    return (
      <LineChartComponent
        data={chartData}
        kpisConfig={kpisConfig}
        config={config}
      />
    );
  }

  return <EmptyBox {...options?.emptyBoxProps} />;
}
