import { useApi } from '@/api';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import {
  CurrencySwitchBarChart,
  CurrencySwitchLineChart
} from '@/components/charts';
import { useSearchParams } from '@/hooks';
import {
  CardLoader,
  CurrencyFilter,
  Flex,
  useTableProps,
  useThemeCustomProperties
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function ChartTabsLong({ daysAgo, currency }) {
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'long'
  );
  const { marketId } = useParams();
  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const displayOptions = [
    { key: 'long', value: 'Open longs' },
    { key: 'volume', value: 'Trading Volume' },
    { key: 'openClose', value: 'Open/Close' }
  ];

  const [data, error] = useApi({
    url: `/markets/${marketId}/snapshots`,
    queryParams: {
      days_ago: daysAgo
    }
  });
  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });
  const switchValue = filters.filtersApplied.currency;
  const colors = useThemeCustomProperties([
    'color-open',
    'color-close',
    'color-primary'
  ]);
  const tabsContent = useMemo(
    () => ({
      long: (
        <CurrencySwitchLineChart
          key={activeDisplayOption + currency + daysAgo}
          data={data}
          fields={[
            {
              field: 'total_long',
              label: 'Open long'
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          daysAgo={daysAgo}
          options={{ dynamic: true }}
        />
      ),
      volume: (
        <CurrencySwitchBarChart
          key={activeDisplayOption + currency + daysAgo}
          data={data}
          fields={[
            {
              field: `volume_long`,
              label: 'Trading Volume',
              backgroundColor: colors.colorPrimary
            }
          ]}
          switchValue={switchValue}
          currency={currency}
          aspectRatio={6}
          options={{ dynamic: true }}
          daysAgo={daysAgo}
        />
      ),
      openClose: (
        <CurrencySwitchBarChart
          key={activeDisplayOption + currency + daysAgo}
          data={data}
          fields={[
            {
              field: `volume_open_long`,
              label: 'Add',
              backgroundColor: colors.colorOpen
            },
            {
              field: `volume_close_long`,
              label: 'Remove',
              backgroundColor: colors.colorClose
            }
          ]}
          currency={currency}
          aspectRatio={6}
          switchValue={switchValue}
          daysAgo={daysAgo}
          options={{
            mode: 'index',
            plugins: false,
            dynamic: true
          }}
        />
      )
    }),
    [data, currency, daysAgo, activeDisplayOption, colors, switchValue]
  );

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Flex direction="column" gap="space-m">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={handleTabClick}
          />
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="1.65rem"
            currency2={{ value: 'token', displayValue: 'Token' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>

        {tabsContent[activeDisplayOption]}
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
