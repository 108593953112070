import { CurrencyValue, Flex } from '@blockanalitica/ui';

const EventFormatter = ({ type, data }) => {
  let content;

  switch (type) {
    case 'OpenLong':
    case 'OpenShort':
    case 'CloseLong':
    case 'CloseShort':
      content = (
        <Flex
          direction={'row'}
          alignContent={'center'}
          alignItems={'flex-start'}
          gap="space-xs">
          <Flex direction={'column'} gap="space-3xs">
            <span className="text-muted">{'Base Amount'}</span>
            <CurrencyValue
              value={data.amount}
              options={{
                currency: data.asset_symbol,
                iconOpacity: 0.7,
                leadingCurrencySymbol: false,
                iconSize: 'xSmall'
              }}
            />
          </Flex>
          <Flex direction={'column'} gap="space-3xs">
            <span className="text-muted">{'Size'}</span>
            <CurrencyValue
              value={data.alt_amount}
              options={{
                currency: data.asset_symbol,
                iconOpacity: 0.7,
                leadingCurrencySymbol: false,
                iconSize: 'xSmall'
              }}
            />
          </Flex>
        </Flex>
      );
      break;
    case 'AddLiquidity':
    case 'RemoveLiquidity':
      content = (
        <Flex
          direction={'row'}
          alignContent={'center'}
          alignItems={'flex-start'}
          gap="space-xs">
          <Flex direction={'column'} gap="space-3xs">
            <span className="text-muted">Base Amount</span>
            <CurrencyValue
              value={data.amount}
              options={{
                currency: data.asset_symbol,
                iconOpacity: 0.7,
                leadingCurrencySymbol: false,
                iconSize: 'xSmall'
              }}
            />
          </Flex>
          <Flex direction={'column'} gap="space-3xs">
            <span className="text-muted">LP Amount</span>
            <CurrencyValue
              value={data.alt_amount}
              options={{
                currency: data.asset_symbol,
                iconOpacity: 0.7,
                leadingCurrencySymbol: false,
                iconSize: 'xSmall'
              }}
            />
          </Flex>
        </Flex>
      );
      break;

    default:
    // pass
  }

  return <>{content}</>;
};

export default EventFormatter;
