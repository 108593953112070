import { useSearchParams } from '@/hooks';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  Flex,
  useToggle
} from '@blockanalitica/ui';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { useSearchParams as useRRSearchParams } from 'react-router-dom';

import { Fragment, useCallback, useEffect, useMemo } from 'react';
import NewLongPositionPnlEstimator from './NewLongPositionPnlEstimator/NewLongPositionPnlEstimator';
import NewShortPositionPnlEstimator from './NewShortPositionPnlEstimator/NewShortPositionPnlEsimator';
import NewLpPositionPnlEstimator from './NewLpPositionPnlEstimator/NewLpPositionPnlEstimator';
import LegalDisclaimer from '@/pages/components/LegalDisclaimer';
const displayOptions = [
  { key: 'long', value: 'Long' },
  { key: 'short', value: 'Short' },
  { key: 'LP', value: 'LP' }
];

const lpDisplayOptions = [
  { key: 'simple', value: 'Simple' },
  { key: 'advanced', value: 'Advanced' }
];

export default function PositionPnlEstimator() {
  const [open, toggle] = useToggle();
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'type',
    'long'
  );
  const [lpActiveDisplayOption, setLpActiveDisplayOption] = useSearchParams(
    'lpType',
    'simple'
  );
  const [, setRouterSearchParams] = useRRSearchParams();

  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
      const newSearchParams = new URLSearchParams();
      newSearchParams.set('type', key);
      setRouterSearchParams(newSearchParams);
    },

    [setActiveDisplayOption, setRouterSearchParams]
  );

  const handleLpTabClick = useCallback(
    (key) => {
      setLpActiveDisplayOption(key);
      const newSearchParams = new URLSearchParams();
      newSearchParams.set('lpType', key);
      newSearchParams.set('type', activeDisplayOption);
      setRouterSearchParams(newSearchParams);
    },
    [setLpActiveDisplayOption, setRouterSearchParams, activeDisplayOption]
  );

  const tabsContent = useMemo(() => {
    if (activeDisplayOption == 'long') {
      return <NewLongPositionPnlEstimator />;
    } else if (activeDisplayOption == 'short') {
      return <NewShortPositionPnlEstimator />;
    } else if (activeDisplayOption == 'LP') {
      return <NewLpPositionPnlEstimator />;
    }
  }, [activeDisplayOption]);

  useEffect(() => {
    if (open) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [open, activeDisplayOption]);

  return (
    <Flex direction="column" gap="space-m">
      <Flex gap="space-xs" alignItems={'center'}>
        <h4>Position PnL Estimator</h4>
        {open ? (
          <Flex gap="space-m" alignItems={'center'}>
            <ChevronUpIcon
              cursor={'pointer'}
              onClick={() => toggle(false)}
              size={18}
              color="var(--color-primary)"
            />
          </Flex>
        ) : (
          <ChevronDownIcon
            cursor={'pointer'}
            onClick={() => toggle(true)}
            size={18}
            color="var(--color-onSurface400)"
          />
        )}
      </Flex>

      {open ? (
        <Fragment>
          <Flex
            gap="space-xs"
            alignItems={'center'}
            direction={'row'}
            justifyContent={'space-between'}>
            <Flex
              direction={['row', 'row', 'column']}
              alignItems={['flex-start', 'flex-start', 'center']}
              justifyContent="space-between"
              gap="space-xs">
              <DisplaySwitch
                key="display-switch"
                options={displayOptions}
                activeOption={activeDisplayOption}
                onChange={handleTabClick}
              />
            </Flex>
            {activeDisplayOption === 'LP' && (
              <Flex
                direction={['row', 'row', 'column']}
                alignItems={['flex-start', 'flex-start', 'center']}
                justifyContent="space-between"
                gap="space-xs">
                <DisplaySwitch
                  key="lp-display-switch"
                  options={lpDisplayOptions}
                  activeOption={lpActiveDisplayOption}
                  onChange={handleLpTabClick}
                />
              </Flex>
            )}
          </Flex>
          {tabsContent}
          <LegalDisclaimer />
        </Fragment>
      ) : null}
    </Flex>
  );
}
