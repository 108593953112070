import { useApi } from '@/api';
import { useSearchParams } from '@/hooks';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CurrencyFilter,
  Flex,
  useTableProps,
  useThemeCustomProperties,
  useToggle
} from '@blockanalitica/ui';

import {
  useParams,
  useSearchParams as useRRSearchParams
} from 'react-router-dom';
import PositionPnlEstimatorForm from './components/PositionPnlEstimatorForm';
import { Fragment, useCallback, useEffect, useState } from 'react';
import CurrencySwitchLineChart, {
  LineChartData
} from '@/components/charts/Linechart/CurrencySwitchLineChart';
import LegalDisclaimer from '@/pages/components/LegalDisclaimer';
export type PositionType = 'Long' | 'Short';

type PositionPnlEstimatorProps = {
  currency: string;
  type: PositionType;
  maturityTime: number;
};

export default function PositionPnlEstimator({
  currency,
  type,
  maturityTime
}: PositionPnlEstimatorProps) {
  const [open, toggle] = useToggle();
  const [assetId] = useSearchParams('asset_id', '');
  const { marketId, walletAddress, positionType } = useParams();

  const [daysToClose] = useSearchParams('days_to_close');
  const [rateAtClose] = useSearchParams('rate_at_close');
  const [avgVarRate] = useSearchParams('avg_var_rate');
  const [, setRouterSearchParams] = useRRSearchParams();

  const updateSearchParams = useCallback(
    (searchParams: Record<string, string>) => {
      setRouterSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);

        Object.entries(searchParams).forEach(([key, value]) => {
          if (value !== undefined) {
            updatedSearchParams.set(key, String(value));
          }
        });

        return updatedSearchParams;
      });
    },
    [setRouterSearchParams]
  );

  const [data, error] = useApi({
    url: `/markets/${marketId}/positions/${walletAddress}/${positionType}/pnl-projection/`,
    queryParams: {
      asset_id: assetId,
      daysToClose: daysToClose,
      rateAtClose: rateAtClose,
      avgVarRate: type === 'Short' ? avgVarRate : undefined
    }
  });

  const [cachedData, setCachedData] = useState(undefined);

  useEffect(() => {
    if (data !== undefined) {
      setCachedData(data);
    }
  }, [data]);

  const colors = useThemeCustomProperties(['color-primary']);

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  if (error) {
    throw error;
  }

  return (
    <Flex direction="column" gap="space-m">
      <Flex gap="space-xs" alignItems={'center'}>
        <h4>PnL Estimator</h4>
        {open ? (
          <Flex gap="space-m" alignItems={'center'}>
            <CurrencyFilter
              key="currency-filter"
              width="8rem"
              height="1.65rem"
              currency2={{ value: 'token', displayValue: 'Token' }}
              currency1={{ value: 'USD', displayValue: 'USD' }}
              {...filters}
            />
            <ChevronUpIcon
              cursor={'pointer'}
              onClick={() => toggle(false)}
              size={18}
              color="var(--color-primary)"
            />
          </Flex>
        ) : (
          <ChevronDownIcon
            cursor={'pointer'}
            onClick={() => toggle(true)}
            size={18}
            color="var(--color-onSurface400)"
          />
        )}
      </Flex>

      {open ? (
        <Fragment>
          <CurrencySwitchLineChart
            key="pnl-estimator"
            data={cachedData ? (cachedData['results'] as LineChartData[]) : []}
            fields={[
              {
                field: `pnl`,
                label: 'PnL',
                backgroundColor: colors.colorPrimary
              }
            ]}
            currency={currency}
            switchValue={filters.filtersApplied.currency}
            aspectRatio={6}
            options={{
              dynamic: true,
              fill: false
            }}
          />

          <PositionPnlEstimatorForm
            loading={data === undefined}
            maturityTime={maturityTime}
            type={type}
            currentRates={cachedData?.['stats']}
            onSubmit={updateSearchParams}
            initialValues={{
              daysToClose,
              rateAtClose,
              avgVarRate
            }}
          />
          <LegalDisclaimer />
        </Fragment>
      ) : null}
    </Flex>
  );
}
