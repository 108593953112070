import { Flex } from '@blockanalitica/ui';
import GlobalTimeFilter, {
  TimeOptions
} from './components/GlobalTimeFilter/GlobalTimeFilter';

export default function GlobalFilters({ isTimeSwitch }) {
  return (
    <Flex gap="space-3xs">
      {isTimeSwitch && <GlobalTimeFilter defaultValue={TimeOptions['7D']} />}
      {/* Add more filters here */}
    </Flex>
  );
}
