import {
  BarChart as BarChartComponent,
  EmptyBox,
  formatDatetime
} from '@blockanalitica/ui';
import { EmptyBoxProps } from '@blockanalitica/ui/package/esm/src/components/client-components/EmptyBox/EmptyBox';
import { ReactNode, useMemo } from 'react';

type Field = {
  label: string;
  field: string;
  backgroundColor: string | number;
};

type BarChartData = {
  date: string;
} & {
  [key in string as Exclude<key, 'date'>]: number;
};

type BarChartOptions = {
  emptyBoxProps?: EmptyBoxProps;
  dynamic?: boolean;
  defaultValue?: string;
  defaultSubValue?: string;
};

interface BarChartProps {
  data: BarChartData[];
  fields: Field[];
  valueFormatter: (value: string | number) => ReactNode;
  aspectRatio?: number;
  xScaleValueFormatter?: (value: string | number) => string;
  yScaleValueFormatter?: (value: string | number) => string;
  daysAgo?: number;
  options?: BarChartOptions;
}

export default function BarChart({
  data,
  fields,
  valueFormatter,
  aspectRatio = 4,
  xScaleValueFormatter = undefined,
  yScaleValueFormatter = undefined,
  daysAgo = 30,
  options
}: BarChartProps) {
  let unit = 'week';
  let dateFormat = 'MMM d';
  let dateFormatKPI = 'MMM d, yyyy';
  switch (Number(daysAgo)) {
    case 1:
      unit = 'hour';
      dateFormat = 'H:mm';
      dateFormatKPI = 'MMM d H:mm';
      break;
    case 7:
      unit = 'day';
      break;
    case 365:
      unit = 'week';
      dateFormat = 'MMM d';
      break;
  }
  const xLabel = daysAgo > 1 ? 'date' : 'datetime';
  const chartData = useMemo(() => {
    if (data.length) {
      return {
        datasets: fields.map((fieldInfo) => ({
          label: fieldInfo.label,
          data: data.map((item) => ({
            x: item[xLabel],
            y: item[fieldInfo.field],
            label: fieldInfo.label
          })),
          backgroundColor: String(fieldInfo.backgroundColor)
        }))
      };
    }

    return null;
  }, [data, fields, xLabel]);

  const kpisConfig = useMemo(() => {
    if (chartData) {
      const defaultData = chartData.datasets.map((ds) => ({
        y:
          options?.defaultValue !== undefined
            ? options?.defaultValue
            : ds.data[ds.data.length - 1].y,
        x:
          options?.defaultValue !== undefined
            ? 'custom'
            : ds.data[ds.data.length - 1].x,
        label: ds.data[ds.data.length - 1].label
      }));
      return {
        valueFormatter: valueFormatter,
        subValueFormatter: (value: string | number) => (
          <span>
            {value === 'custom'
              ? options?.defaultSubValue
              : formatDatetime(value, { format: dateFormatKPI })}
          </span>
        ),
        defaultData: defaultData
      };
    }

    return null;
  }, [chartData, valueFormatter, dateFormatKPI, options]);

  const config = useMemo(
    () => ({
      options: {
        aspectRatio: aspectRatio,
        // @ts-ignore
        onResize: (chart) => {
          const ratio = chart.config.options.aspectRatio;
          if (window.innerWidth < 576 && ratio !== 1.5) {
            chart.config.options.oldAspectRatio = ratio;
            chart.config.options.aspectRatio = 1.5;
          } else if (
            window.innerWidth >= 576 &&
            chart.config.options.oldAspectRatio > 0 &&
            ratio !== chart.config.options.oldAspectRatio
          ) {
            chart.config.options.aspectRatio =
              chart.config.options.oldAspectRatio;
          }
        },
        scales: {
          x: {
            stacked: true,
            type: 'time',
            time: {
              unit: unit
            },
            ticks: {
              callback: xScaleValueFormatter
                ? xScaleValueFormatter
                : (value: string | number) =>
                    formatDatetime(value, { format: dateFormat })
            }
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            stacked: true,
            ticks: {
              format: { notation: 'compact' },
              callback: yScaleValueFormatter
                ? yScaleValueFormatter
                : (value: number) => value
            }
          }
        }
      }
    }),
    [aspectRatio, xScaleValueFormatter, yScaleValueFormatter, dateFormat, unit]
  );

  if (chartData && kpisConfig) {
    return (
      <BarChartComponent
        data={chartData}
        kpisConfig={kpisConfig}
        config={config}
      />
    );
  }

  return <EmptyBox {...options?.emptyBoxProps} />;
}
