import { useApi } from '@/api';
import { Link } from '@/components';
import Table from '@/components/table/Table';
import { useSearchParams } from '@/hooks';
import {
  CurrencyValue,
  PercentageValue,
  WalletAddress,
  useTableProps
} from '@blockanalitica/ui';

export default function WalletsTable({ searchText }) {
  //   const [searchText, setSearchText] = useSearchParams('search', '');
  const { sorting, pagination } = useTableProps({
    pageSize: 25,
    initialSorting: '-tvl'
  });
  const [daysAgo] = useSearchParams('days_ago', '7');

  const [data, error] = useApi({
    url: '/wallets/',
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      search: searchText,
      days_ago: daysAgo,
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'WALLET ADDRESS',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        )
      },
      {
        header: 'TVL',
        getCell: (row) => {
          const tvlValue = Number(row.tvl);
          const displayValue =
            tvlValue !== 0 && Math.abs(tvlValue) < 0.01 ? (
              tvlValue < 0 ? (
                '>$-0.01'
              ) : (
                '<$0.01'
              )
            ) : (
              <CurrencyValue value={tvlValue} options={{ currency: 'USD' }} />
            );

          return displayValue;
        },
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.tvl_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'tvl'
      },
      {
        header: 'PNL',
        getCell: (row) => {
          const pnlValue = Number(row.pnl);
          const displayValue =
            pnlValue !== 0 && Math.abs(pnlValue) < 0.01 ? (
              pnlValue < 0 ? (
                '>$-0.01'
              ) : (
                '<$0.01'
              )
            ) : (
              <CurrencyValue value={pnlValue} options={{ currency: 'USD' }} />
            );

          return displayValue;
        },
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.pnl_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'pnl'
      },
      {
        header: 'LONG AMOUNT',
        getCell: (row) => {
          const longValue = Number(row.total_long);
          const displayValue =
            longValue !== 0 && Math.abs(longValue) < 0.01 ? (
              longValue < 0 ? (
                '>$-0.01'
              ) : (
                '<$0.01'
              )
            ) : (
              <CurrencyValue value={longValue} options={{ currency: 'USD' }} />
            );

          return displayValue;
        },
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.total_long_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'total_long'
      },
      {
        header: 'SHORT AMOUNT',
        getCell: (row) => {
          const shortValue = Number(row.total_bond_amount_balance_short);
          const displayValue =
            shortValue !== 0 && Math.abs(shortValue) < 0.01 ? (
              shortValue < 0 ? (
                '>$-0.01'
              ) : (
                '<$0.01'
              )
            ) : (
              <CurrencyValue value={shortValue} options={{ currency: 'USD' }} />
            );

          return displayValue;
        },
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.total_short_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'total_short'
      },
      {
        header: 'LP AMOUNT',
        getCell: (row) => {
          const lpValue = Number(row.total_lp);
          const displayValue =
            lpValue !== 0 && Math.abs(lpValue) < 0.01 ? (
              lpValue < 0 ? (
                '>$-0.01'
              ) : (
                '<$0.01'
              )
            ) : (
              <CurrencyValue value={lpValue} options={{ currency: 'USD' }} />
            );

          return displayValue;
        },
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.total_lp_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'total_lp'
      }
    ];

    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => `${row.address}${row.wallet_address}`}
        getLinkWrapper={(row) => (
          <Link daysAgo={true} to={`${row.wallet_address}`} />
        )}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return null;
}
