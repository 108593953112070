import { Card as UICard } from '@blockanalitica/ui';
import { CardProps } from '@blockanalitica/ui/package/esm/src/components/server-components/Card/Card';

export default function Card({ children, ...rest }: CardProps) {
  return (
    <UICard borderless={true} {...rest}>
      {children}
    </UICard>
  );
}
