import { useApi } from '@/api';
import { Link } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import Table from '@/components/table/Table';
import {
  CryptoIcon,
  CurrencyValue,
  Flex,
  PercentageValue,
  RelativeDateTime,
  WalletAddress
} from '@blockanalitica/ui';

// @ts-ignore
export default function LPTable({
  daysAgo,
  searchText,
  filters,
  sorting,
  pagination
}) {
  const [data, error] = useApi({
    url: '/positions/',
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      days_ago: daysAgo,
      order: sorting.currentSorting,
      type: 'lp',
      search: searchText,
      market: filters.filtersApplied.marketAddress
    }
  });

  if (error) {
    throw error;
  }
  if (data) {
    const columns = [
      {
        header: 'WALLET',
        getCell: (row) => (
          <WalletAddress
            options={{
              size: 'xSmall'
            }}
            address={row.wallet_address}
          />
        )
      },
      {
        header: 'MARKET',
        getCell: (row) => (
          <Flex gap="space-3xs" alignItems="center">
            <DoubleSymbol
              symbol1={<CryptoIcon name={row.asset_symbol} extension="png" />}
              symbol2={
                <CryptoIcon
                  name={row.yield_source ? row.yield_source : ''}
                  extension="png"
                />
              }
            />
            {row.asset_symbol} {row.market_maturity_time / 86400}d
          </Flex>
        )
      },
      {
        header: 'LP VALUE',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.balance_usd}
                options={{ currency: 'USD' }}
              />
            ) : (
              <CurrencyValue
                value={row.balance}
                options={{
                  dynamic: false,
                  currency: row.asset_symbol,
                  leadingCurrencySymbol: false,
                  iconOpacity: 0.7,
                  iconSize: 'xSmall'
                }}
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.balance_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField:
          filters.filtersApplied.currency === 'USD' ? 'balance_usd' : 'balance'
      },
      {
        header: 'LATEST ACTIVITY',
        getCell: (row) => <RelativeDateTime value={row.last_activity} />,
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'last_activity'
      }
    ];
    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => `${row.address}${row.wallet_address}`}
        getLinkWrapper={(row) => (
          <Link
            daysAgo={true}
            to={`/markets/${row.address}/positions/${row.wallet_address}/lp`}
          />
        )}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return null;
}
