import {
  EmptyBox,
  Flex,
  LineChart as LineChartComponent,
  formatDatetime,
  useThemeCustomProperties
} from '@blockanalitica/ui';
import { EmptyBoxProps } from '@blockanalitica/ui/package/esm/src/components/client-components/EmptyBox/EmptyBox';
import { FlexProps } from '@blockanalitica/ui/package/esm/src/components/server-components/layout/Flex/Flex';
import { ReactNode, useMemo } from 'react';

type LineChartData = {
  date: string;
} & {
  [field: string]: number;
};

type LineChartOptions = {
  emptyBoxProps?: EmptyBoxProps;
};

interface LineChartProps extends Partial<FlexProps> {
  data: LineChartData[];
  field: string;
  valueFormatter: (value: string | number) => ReactNode;
  aspectRatio?: number;
  xScaleValueFormatter?: (value: string | number) => string;
  yScaleValueFormatter?: (value: string | number) => string;
  switchValue?: string;
  daysAgo?: number;
  options?: LineChartOptions;
}

export default function LineChart({
  data,
  field,
  valueFormatter,
  aspectRatio = 3.4,
  xScaleValueFormatter = undefined,
  yScaleValueFormatter = undefined,
  switchValue = 'token',
  daysAgo = 30,
  options,
  ...rest
}: LineChartProps) {
  let unit = 'week';
  let dateFormat = 'MMM d';
  let dateFormatKPI = 'MMM d, yyyy';
  switch (Number(daysAgo)) {
    case 1:
      unit = 'hour';
      dateFormat = 'H:mm';
      dateFormatKPI = 'MMM d H:mm';
      break;
    case 7:
      unit = 'day';
      break;
    case 365:
      unit = 'week';
      dateFormat = 'MMM d';
      break;
  }
  const colors = useThemeCustomProperties(['color-primary', 'color-secondary']);
  const switchField = switchValue == 'token' ? field : `${field}_usd`;
  const xLabel = daysAgo > 1 ? 'date' : 'datetime';
  const chartData = useMemo(() => {
    if (data.length) {
      return {
        datasets: [
          {
            data: data.map((item) => ({
              x: item[xLabel],
              y: item[switchField],
              label: 'Total'
            })),
            borderColor: colors.colorPrimary,
            borderWidth: 4,
            // @ts-ignore
            backgroundColor: function (context) {
              const chart = context.chart;
              const { ctx, chartArea } = chart;

              if (!chartArea) {
                // This case happens on initial chart load
                return null;
              }
              const gradient = ctx.createLinearGradient(
                0,
                chartArea.bottom,
                0,
                chartArea.top
              );
              gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
              gradient.addColorStop(1, 'rgba(21, 225, 171, .4)');

              return gradient;
            }
          }
        ]
      };
    }

    return null;
  }, [data, switchField, colors.colorPrimary, xLabel]);

  const kpisConfig = useMemo(() => {
    if (chartData) {
      return {
        valueFormatter: valueFormatter,
        subValueFormatter: (value: string | number) => (
          <span className="text-muted">
            {formatDatetime(value, { format: dateFormatKPI })}
          </span>
        ),
        defaultData: [
          chartData?.datasets[0].data[chartData?.datasets[0].data.length - 1]
        ]
      };
    }

    return null;
  }, [chartData, valueFormatter, dateFormatKPI]);

  const config = useMemo(
    () => ({
      options: {
        fill: true,
        aspectRatio: aspectRatio,
        // @ts-ignore
        onResize: (chart) => {
          const ratio = chart.config.options.aspectRatio;
          if (window.innerWidth < 576 && ratio !== 1.5) {
            chart.config.options.oldAspectRatio = ratio;
            chart.config.options.aspectRatio = 1.5;
          } else if (
            window.innerWidth >= 576 &&
            chart.config.options.oldAspectRatio > 0 &&
            ratio !== chart.config.options.oldAspectRatio
          ) {
            chart.config.options.aspectRatio =
              chart.config.options.oldAspectRatio;
          }
        },
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: unit
            },
            ticks: {
              callback: xScaleValueFormatter
                ? xScaleValueFormatter
                : (value: number) =>
                    formatDatetime(value, { format: dateFormat })
            }
          },
          y: {
            type: 'linear',
            ticks: {
              format: { notation: 'compact' },
              callback: yScaleValueFormatter
                ? yScaleValueFormatter
                : (value: number) => value
            }
          }
        }
      }
    }),
    [aspectRatio, xScaleValueFormatter, yScaleValueFormatter, unit, dateFormat]
  );

  if (
    chartData &&
    kpisConfig &&
    chartData.datasets[0].data[0].y !== undefined
  ) {
    return (
      <Flex direction="column" key={switchField} {...rest}>
        <LineChartComponent
          data={chartData}
          kpisConfig={kpisConfig}
          config={config}
        />
      </Flex>
    );
  }

  return <EmptyBox {...options?.emptyBoxProps} />;
}
