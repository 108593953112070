import { Card } from '@/components';

import { EmptyBox, Flex, Table as TableComponent } from '@blockanalitica/ui';
import styles from './Table.module.scss';

export default function Table({
  rows,
  columns,
  getKey,
  getLinkWrapper,
  ...rest
}) {
  const formattedData = rows.map((data) => ({
    ...data,
    getKey,
    getLinkWrapper
  }));

  const useDoubleCell = columns.find((element) => element.getCellBottom);

  const parsedColumns = columns.map((column) => ({
    ...column,
    getCell: (row) => (
      <>
        {useDoubleCell ? (
          <Flex
            direction="column"
            gap="space-3xs"
            justifyContent="center"
            alignItems={column.cellAlign}
            style={{ minHeight: '2.4rem' }}>
            {column.getCell(row)}
            {column.getCellBottom ? column.getCellBottom(row) : null}
          </Flex>
        ) : (
          column.getCell(row)
        )}
      </>
    )
  }));

  if (formattedData && formattedData.length > 0) {
    return (
      <TableComponent
        classNames={{ table: styles.table }}
        data={formattedData}
        columns={parsedColumns}
        {...rest}
      />
    );
  }
  return (
    <Card>
      <EmptyBox />
    </Card>
  );
}
