import { Flex } from '@blockanalitica/ui';
import classnames from 'classnames';
import styles from './ChartKpis.module.scss';

type KpiItem = {
  label: string;
  color: string; // Ensure this property exists in data passed to kpisConfig
  disabled?: boolean;
};

type ChartKpisProps = {
  kpisData: KpiItem[];
  onClick: (index: number) => void;
};

export default function ChartKpis({ kpisData, onClick }: ChartKpisProps) {
  return (
    <Flex gap="space-xs">
      {kpisData.map((kpi, index) => (
        <Flex
          className={classnames(styles.kpiItemContainer, {
            [styles.disabled]: kpi.disabled
          })}
          key={kpi.label}
          alignItems="center"
          alignContent="flex-start"
          wrap="wrap"
          gap="space-xs"
          onClick={() => onClick(index)}>
          <div
            className={styles.filledCircle}
            style={{ backgroundColor: kpi.color }}
          />
          <span className="text-2">{kpi.label}</span>
        </Flex>
      ))}
    </Flex>
  );
}
