import { ErrorBoundary, GlobalFilters } from '@/components';
import { Flex, HomeIcon, useIsMobile } from '@blockanalitica/ui';
import * as Sentry from '@sentry/react';
import { ReactNode, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '../Breadcrumbs/Breadcrumbs';
import ExternalLinkButton from '../forms/ExternalLinkButton';
import PageHeader from './components/PageHeader';

type PageProps = {
  title?: string | ReactNode;
  logo?: ReactNode;
  filters?: object;
  actions?: ReactNode;
  children: ReactNode;
  isTimeSwitch?: boolean;
};

export default function Page({
  title,
  actions,
  children,
  isTimeSwitch = true,
  logo = false
}: PageProps) {
  const { pathname } = useLocation();

  const isMobile = useIsMobile();

  const crumbs = useMemo(() => {
    const pathArray = pathname.split('/').filter((v) => v.length > 0);
    const crumbsFromPathArray = pathArray.map((subPath, index) => {
      const href = '/' + pathArray.slice(1, index + 1).join('/');
      const text = subPath[0].toUpperCase() + subPath.slice(1);
      const force = false;

      return { href, text, force };
    });

    const crumbsList = [
      { href: `/`, text: <HomeIcon size={16} />, force: true },
      ...crumbsFromPathArray
    ];

    return crumbsList;
  }, [pathname]);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <Flex direction="column" gap="space-xs">
        {isMobile && logo && logo}
        <PageHeader>
          <Flex
            gap="space-3xs"
            direction={['row']}
            flexBasis="100%"
            alignItems="center"
            justifyContent={[
              'space-between',
              'space-between',
              'space-between',
              'flex-end'
            ]}>
            {!isMobile && logo ? (
              logo
            ) : !isMobile && crumbs.length > 0 ? (
              <Breadcrumbs crumbs={crumbs} />
            ) : null}
            <GlobalFilters isTimeSwitch={isTimeSwitch} />
            {!isMobile && (
              <ExternalLinkButton to="https://app.hyperdrive.box/">
                Launch APP
              </ExternalLinkButton>
            )}
          </Flex>

          {actions ? <Flex alignItems="center">{actions}</Flex> : null}
        </PageHeader>

        {typeof title === 'string' ? <h1>{title}</h1> : title}
        {children}
      </Flex>
    </Sentry.ErrorBoundary>
  );
}
