import {
  Flex,
  Kpi,
  KpiBody,
  KpiFooter,
  KpiTitle,
  PercentageValue,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@blockanalitica/ui';

import classnames from 'classnames';
import { ReactNode } from 'react';
import { KpiCardProps } from './KpiCardProps';

type KpiPercentageCardProps = Partial<KpiCardProps> & {
  data: Record<string, ReactNode>;
  field: ReactNode;
  trend?: boolean;
  footer?: ReactNode;
  kpiIcon?: ReactNode;
};

export default function KpiPercentageCard({
  title,
  data,
  field,
  footer = null,
  kpiIcon = null,
  options,
  ...rest
}: KpiPercentageCardProps) {
  const fieldBase = field;
  const trendBase = `${fieldBase}_change`;
  const trendContent = (
    <div className={classnames(options?.trendSize ?? 'text-3', 'text-mono')}>
      <Flex>
        <Tooltip>
          <TooltipTrigger>
            <PercentageValue
              value={Number(data[trendBase])}
              options={{
                trend: true,
                formatPercentageOptions: {
                  maximumFractionDigits: options?.fractionDigits ?? 5
                }
              }}
            />
          </TooltipTrigger>
          <TooltipContent>
            <PercentageValue
              value={Number(data[trendBase])}
              options={{
                formatPercentageOptions: {
                  maximumFractionDigits: options?.fractionDigits ?? 5
                }
              }}
            />
          </TooltipContent>
        </Tooltip>
      </Flex>
    </div>
  );

  return (
    <Kpi {...rest} border="none">
      <Flex
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Flex direction={'column'} flexBasis="80%" gap="space-2xs">
          <KpiTitle className="text-muted text-2">{title}</KpiTitle>
          <KpiBody
            gap="space-xs"
            alignItems="baseline"
            justifyContent="flex-start">
            <div
              className={classnames(
                options?.valueSize ?? 'text-4',
                'text-mono'
              )}>
              <PercentageValue
                value={Number(data[fieldBase])}
                options={{
                  trend: false,
                  tooltip: false,
                  formatPercentageOptions: {
                    maximumFractionDigits: options?.fractionDigits ?? 2,
                    notation: 'compact'
                  }
                }}
              />
            </div>
            {trendContent}
          </KpiBody>
          <KpiFooter gap="space-3xs" alignItems="baseline">
            {footer}
          </KpiFooter>
        </Flex>
        {kpiIcon && <Flex style={{ opacity: 0.2 }}>{kpiIcon}</Flex>}
      </Flex>
    </Kpi>
  );
}
