import { useApi } from '@/api';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import {
  CurrencySwitchBarChart,
  CurrencySwitchLineChart
} from '@/components/charts';
import { useSearchParams } from '@/hooks';
import { timeMapping } from '@/utils';
import { CardLoader, Flex, useThemeCustomProperties } from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';

const displayOptions = [
  { key: 'tvl', value: 'TVL' },
  { key: 'liquidity', value: 'Liquidity' },
  { key: 'volume', value: 'Trading Volume' }
];

export default function OverallChartTabs({ daysAgo, trading }) {
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'tvl'
  );

  const [data, error] = useApi({
    url: `/snapshots/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const colors = useThemeCustomProperties(['color-primary']);

  let yField = 'volume_1d';

  if (Number(daysAgo) === 1) {
    yField = 'volume';
  }

  const tabsContent = useMemo(
    () => ({
      volume: (
        <CurrencySwitchBarChart
          key={activeDisplayOption + daysAgo}
          data={data}
          fields={[
            {
              field: yField,
              label: 'Trading Volume',
              backgroundColor: colors.colorPrimary
            }
          ]}
          currency={'DAI'}
          switchValue={'USD'}
          aspectRatio={6}
          options={{
            dynamic: true,
            defaultValue: trading,
            defaultSubValue: `Trading Volume ${timeMapping[daysAgo]}`
          }}
          daysAgo={daysAgo}
        />
      ),
      tvl: (
        <CurrencySwitchLineChart
          key={activeDisplayOption + daysAgo}
          data={data}
          fields={[
            {
              field: 'tvl',
              label: 'Tvl'
            }
          ]}
          currency={'USD'}
          switchValue={'USD'}
          aspectRatio={6}
          daysAgo={daysAgo}
          options={{ dynamic: true }}
        />
      ),
      liquidity: (
        <CurrencySwitchLineChart
          key={activeDisplayOption + daysAgo}
          data={data}
          fields={[
            {
              field: 'liquidity',
              label: 'Liquidity'
            }
          ]}
          currency={'USD'}
          switchValue={'USD'}
          aspectRatio={6}
          daysAgo={daysAgo}
          options={{ dynamic: true }}
        />
      )
    }),
    [activeDisplayOption, data, daysAgo, colors, trading]
  );

  if (data) {
    return (
      <Flex direction="column" gap="space-m">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={handleTabClick}
          />
        </Flex>

        {tabsContent[activeDisplayOption]}
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
