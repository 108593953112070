import { Page } from '@/components';
import {
  CoinsStackIcon,
  CurrencyFilter,
  Flex,
  useTableProps
} from '@blockanalitica/ui';
import TokensTable from './components/TokensTable';

export default function TokensPage() {
  const { filters, sorting, pagination } = useTableProps({
    pageSize: 5,
    initialFilters: { currency: 'token' },
    filtersDebounceTime: 50,
    initialSorting: '-tvl'
  });
  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <CoinsStackIcon />
          <h1>Tokens</h1>
        </Flex>
      }>
      <Flex direction="row" justifyContent="flex-end">
        <CurrencyFilter
          key="tokens-currency-filter"
          width="8rem"
          height="auto"
          style={{ minHeight: '1.6rem' }}
          currency1={{ value: 'token', displayValue: 'TOKEN' }}
          currency2={{ value: 'USD', displayValue: 'USD' }}
          {...filters}
        />
      </Flex>
      <TokensTable
        filters={filters}
        sorting={sorting}
        pagination={pagination}
      />
    </Page>
  );
}
