import { formatCurrency } from '@/utils';
import {
  CurrencyValue,
  EmptyBox,
  Flex,
  formatDatetime,
  LineChart
} from '@blockanalitica/ui';
import { EmptyBoxProps } from '@blockanalitica/ui/package/esm/src/components/client-components/EmptyBox/EmptyBox';
import _ from 'lodash';
import { memo, useMemo } from 'react';

type Field = {
  label: string;
  field: string;
  backgroundColor?: string;
};

export type LineChartData = {
  date: string;
} & {
  [key in string as Exclude<key, 'date'>]: number;
};

type LineChartOptions = {
  emptyBoxProps?: EmptyBoxProps;
  dynamic?: boolean;
  fill?: boolean;
  notation?: 'compact' | 'standard' | 'scientific' | 'engineering';
};

interface LineChartProps {
  data: LineChartData[];
  fields: Field[];
  currency: string;
  switchValue: string;
  aspectRatio?: number;
  daysAgo?: number;
  options?: LineChartOptions;
  kpiFractionDigits?: number;
}

const CurrencySwitchLineChart = memo(
  function CurrencySwitchLineChart({
    data,
    fields,
    currency,
    switchValue,
    aspectRatio = 3.4,
    options,
    daysAgo = 30,
    kpiFractionDigits = 2
  }: LineChartProps) {
    let unit = 'week';
    let dateFormat = 'MMM d';
    let dateFormatKPI = 'MMM d, yyyy';
    switch (Number(daysAgo)) {
      case 1:
        unit = 'hour';
        dateFormat = 'H:mm';
        dateFormatKPI = 'MMM d H:mm';
        break;
      case 7:
        unit = 'day';
        break;
      case 365:
        unit = 'week';
        dateFormat = 'MMM d';
        break;
    }

    const chartData = useMemo(() => {
      if (data.length) {
        return {
          datasets: fields.map((fieldInfo) => {
            const fieldName =
              switchValue === 'token'
                ? fieldInfo.field
                : `${fieldInfo.field}_usd`;
            return {
              label: fieldInfo.label,
              data: data.map((item) => ({
                x: Number(daysAgo) === 1 ? item.datetime : item.date,
                y: item[fieldName],
                label: fieldInfo.label
              })),
              borderColor:
                fieldInfo.backgroundColor && fieldInfo.backgroundColor !== ''
                  ? fieldInfo.backgroundColor
                  : '#15ffab',
              borderWidth: 4,
              backgroundColor: function (context) {
                if (fields.length === 1) {
                  const chart = context.chart;
                  const { ctx, chartArea } = chart;

                  if (!chartArea) {
                    return null;
                  }
                  const gradient = ctx.createLinearGradient(
                    0,
                    chartArea.bottom,
                    0,
                    chartArea.top
                  );
                  gradient.addColorStop(0, 'rgba(0, 0, 0, 0)');
                  gradient.addColorStop(1, 'rgba(21, 225, 171, .4)');

                  return gradient;
                } else {
                  return 'rgba(0, 0, 0, 0)';
                }
              }
            };
          })
        };
      }

      return null;
    }, [data, fields, switchValue, daysAgo]);

    const kpisConfig = useMemo(() => {
      const valueFormatter = (value: number) =>
        switchValue === 'token' ? (
          <span className="text-mono">
            <CurrencyValue
              value={value}
              options={{
                currency,
                dynamic: options?.dynamic ?? false,
                FormattedCurrencyOptions: {
                  maximumFractionDigits: kpiFractionDigits
                }
              }}
            />
          </span>
        ) : (
          <span className="text-mono">
            <CurrencyValue
              value={value}
              options={{
                currency: 'USD',
                dynamic: options?.dynamic ?? false,
                notation: options?.notation ?? 'compact',
                FormattedCurrencyOptions: {
                  maximumFractionDigits: kpiFractionDigits
                }
              }}
            />
          </span>
        );

      if (chartData) {
        const defaultData = chartData.datasets.map((ds) => ({
          y: ds.data[ds.data.length - 1].y,
          x: ds.data[ds.data.length - 1].x,
          label: ds.data[ds.data.length - 1].label
        }));

        return {
          valueFormatter,
          subValueFormatter: (value: string | number) => (
            <span>{formatDatetime(value, { format: dateFormatKPI })}</span>
          ),
          defaultData
        };
      }

      return null;
    }, [
      chartData,
      switchValue,
      currency,
      kpiFractionDigits,
      options,
      dateFormatKPI
    ]);

    const config = useMemo(
      () => ({
        options: {
          aspectRatio,
          fill: options?.fill ?? true,
          // @ts-ignore
          onResize: (chart) => {
            const ratio = chart.config.options.aspectRatio;
            if (window.innerWidth < 576 && ratio !== 1.5) {
              chart.config.options.oldAspectRatio = ratio;
              chart.config.options.aspectRatio = 1.5;
            } else if (
              window.innerWidth >= 576 &&
              chart.config.options.oldAspectRatio > 0 &&
              ratio !== chart.config.options.oldAspectRatio
            ) {
              chart.config.options.aspectRatio =
                chart.config.options.oldAspectRatio;
            }
          },
          scales: {
            x: {
              stacked: true,
              type: 'time',
              time: {
                unit: unit
              },
              ticks: {
                callback: (value: number) =>
                  formatDatetime(value, { format: dateFormat })
              }
            },
            y: {
              type: 'linear',
              stacked: false,
              ticks: {
                format: { notation: 'compact' },
                callback: (value: number) =>
                  switchValue === 'token'
                    ? formatCurrency(value, currency)
                    : formatCurrency(value, 'USD')
              }
            }
          }
        }
      }),
      [aspectRatio, switchValue, currency, dateFormat, unit, options]
    );

    if (chartData && kpisConfig) {
      return (
        <Flex direction="column">
          <LineChart
            data={chartData}
            kpisConfig={kpisConfig}
            // @ts-ignore
            config={config}
            key={switchValue}
          />
        </Flex>
      );
    }

    return <EmptyBox {...options?.emptyBoxProps} />;
  },
  (prevProps, nextProps) => {
    return _.isEqual(prevProps, nextProps);
  }
);

export default CurrencySwitchLineChart;
