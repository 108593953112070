import { useApi } from '@/api';
import { Card, Divider, KpiCard, Page } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';
import KpiPercentageCard from '@/components/Kpi/KpiPercentageCard';
import { useSearchParams } from '@/hooks';
import { CryptoIcon, Flex, WalletAddress } from '@blockanalitica/ui';
import { default as classNames, default as classnames } from 'classnames';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MarketPositionEventsTable from './components/MarketPositionEventsTable';
import PositionPnlEstimator from './components/PositionPnlEstimator/PositionPnlEstimator';

import { useLocation } from 'react-router-dom';
import { smartLocationParts } from '../../../../../utils/url.js';
export default function MarketPositionsWalletAddressTypePage() {
  const location = useLocation();
  const network = smartLocationParts(location);
  const [daysAgo] = useSearchParams('days_ago', '7');
  const [assetId] = useSearchParams('asset_id', '');

  const { marketId, walletAddress, positionType } = useParams();
  const [data, error] = useApi({
    url: `/markets/${marketId}/positions/${walletAddress}/${positionType}/`,
    queryParams: {
      days_ago: daysAgo,
      asset_id: assetId
    }
  });

  // get days difference between passed timestamp and now()
  function parseTimestamp(timestamp) {
    let days = DateTime.fromSeconds(timestamp).diff(
      DateTime.now(),
      'days'
    ).days;
    if (days > 0) {
      return Math.floor(days);
    } else {
      return Math.ceil(days);
    }
  }

  const titleOptions = useMemo(() => {
    if (data) {
      if (data.status != 'closed') {
        let options = {
          currentValue: 'Current Value',
          positionSize: 'Position Size',
          pnl: 'Current PnL',
          maturityTime: 'Maturity Time'
        };
        if (positionType === 'long') {
          options.positionSize = 'Position Size (Value at Maturity)';
        }
        return options;
      } else {
        return {
          currentValue: 'Closing Value',
          positionSize: 'Bond Amount Closed',
          pnl: 'Realized PnL',
          maturityTime: 'Closed'
        };
      }
    }
  }, [data, positionType]);

  const maturityKPIContent = useMemo(() => {
    if (data) {
      if (data.status == 'open' || data.status == 'matured') {
        const maturityTime = parseTimestamp(data.maturity_time);

        if (maturityTime >= 0) {
          return (
            <Flex direction="row" gap="space-2xs" alignItems="baseline">
              <span className={classNames('text-3', 'text-muted')}>in</span>
              <span className={classNames('text-5')}>{maturityTime}</span>
              <span className={classNames('text-3', 'text-muted')}>days</span>
            </Flex>
          );
        } else {
          return (
            <Flex direction="row" gap="space-2xs" alignItems="baseline">
              <span className={classNames('text-5')}>
                {Math.abs(maturityTime)}
              </span>
              <span className={classNames('text-3', 'text-muted')}>
                days ago
              </span>
            </Flex>
          );
        }
      } else {
        return (
          <Flex direction="row" gap="space-2xs" alignItems="baseline">
            <span className={classNames('text-5')}>
              {Math.abs(parseTimestamp(Date.parse(data.last_activity) / 1000))}
            </span>
            <span className={classNames('text-3', 'text-muted')}>days ago</span>
          </Flex>
        );
      }
    }
  }, [data]);

  if (error) {
    throw error;
  }

  if (data) {
    const position_type =
      data.position.charAt(0).toUpperCase() + data.position.slice(1);

    return (
      <Page
        title={
          <Flex direction="row" gap="space-xs">
            <DoubleSymbol
              symbol1={
                <CryptoIcon
                  name={data.asset_symbol}
                  size={40}
                  extension="png"
                />
              }
              symbol2={
                <CryptoIcon
                  name={data.yield_source ? data.yield_source : ''}
                  size={40}
                  extension="png"
                />
              }
            />
            <h2>
              {data.name} {position_type}
            </h2>
          </Flex>
        }>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Card style={{ width: '100%' }}>
              <Flex gap="space-xs" direction="column" alignItems="flex-start">
                <Flex>
                  <span className={classnames('text-2', 'text-muted')}>
                    Wallet
                  </span>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <WalletAddress
                    address={data.wallet_address}
                    options={{
                      size: 'small',
                      showEthScanLink: true,
                      showDebankLink: true,
                      showZapperLink: true,
                      showCopyBtn: true,
                      network: network
                    }}
                  />
                </Flex>
              </Flex>
            </Card>
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiPercentageCard
              title="Fixed rate at opening"
              data={data}
              field="initial_var_rate"
              options={{
                valueSize: 'text-5',
                footerSize: 'text-2',
                fractionDigits: 2
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCard
              title={titleOptions.maturityTime}
              value={maturityKPIContent}
            />
          </Flex>
        </Flex>

        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title={titleOptions.positionSize}
              data={data}
              field="bond_balance"
              currency={data.asset_symbol}
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large'
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title={titleOptions.currentValue}
              data={data}
              field="balance"
              currency={data.asset_symbol}
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large'
              }}
            />
          </Flex>

          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title={titleOptions.pnl}
              data={data}
              field="pnl"
              currency={data.asset_symbol}
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large'
              }}
            />
          </Flex>
        </Flex>
        {data.status == 'open' && (
          <>
            <Divider />
            <PositionPnlEstimator
              currency={data.asset_symbol}
              type={position_type}
              maturityTime={parseTimestamp(data.maturity_time)}
            />
          </>
        )}
        <Divider />
        <MarketPositionEventsTable />
      </Page>
    );
  }

  return null;
}
