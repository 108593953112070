export const SLASH_REGEX = /^\/?|\/?$/g;

export const smartLocationParts = (location) => {
  const pathname = location.pathname.replace(SLASH_REGEX, '');
  const paths = pathname.split('/');
  let network = paths[0];
  return network;
};

export const smartEtherscanUrl = (location) => {
  const network = smartLocationParts(location);

  let url = '';
  switch (network) {
    case 'gnosis': {
      url = 'https://gnosisscan.io/';
      break;
    }
    case 'sepolia': {
      url = 'https://sepolia.etherscan.io/';
      break;
    }
    case 'linea': {
      url = 'https://lineascan.build/';
      break;
    }
    case 'base': {
      url = 'https://basescan.org/';
      break;
    }
    default: {
      url = 'https://etherscan.io/';
      break;
    }
  }
  return url;
};
