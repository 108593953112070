import Slider from '@/components/forms/Slider';
import { snakeToSpaceCase } from '@/utils';
import { Flex, Select, Text, formatCurrency } from '@blockanalitica/ui';
import classnames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import styles from './LpPositionPnlEstimatorForm.module.scss';

export type DailyVolumeOptions =
  | 'average_volume_1d'
  | 'average_volume_7d'
  | 'average_volume_30d'
  | 'average_volume_90d'
  | 'average_volume_365d';

export type IdleLiquidityOptions =
  | 'average_idle_liquidity_1d'
  | 'average_idle_liquidity_7d'
  | 'average_idle_liquidity_30d'
  | 'average_idle_liquidity_90d'
  | 'average_idle_liquidity_365d';

export type LpPositionPnlEstimatorFormValues = {
  daysToClose: string | number;
  rateAtClose: string | number;
  avgVarRate: string | number;
  idleLiquidityPercentage: string | number;
  dailyVolume: string | number;
  avgLpApy: string | number;
  dailyVolumeOptions: DailyVolumeOptions;
  idleLiquidityOptions: IdleLiquidityOptions;
  currentRates: Array<{
    var_apy: string | number;
    fixed_rate: string | number;
    lp_apy: string | number;
  }>;
};

type LpPositionPnlEstimatorFormProps = {
  loading: boolean;
  onSubmit: (values: Record<string, string>) => void;
  initialValues?: LpPositionPnlEstimatorFormValues;
  advanced?: boolean;
};

const ApplyButton = styled.button`
  padding: var(--space-2xs);
  background-color: ${({ disabled }) =>
    disabled ? 'var(--color-background)' : 'var(--color-primary)'};
  color: ${({ disabled }) =>
    disabled ? 'var(--color-primary)' : 'var(--color-background)'};
  border-radius: var(--size--4);
  border: 1px solid var(--color-primary);
  opacity: 0.7;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    &:hover {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
`;

export default function LpPositionPnlEstimatorForm({
  loading,
  onSubmit,
  initialValues,
  advanced
}: LpPositionPnlEstimatorFormProps) {
  const [daysToClose, setDaysToClose] = useState<number>(
    Number(initialValues.daysToClose ?? 1)
  );

  const [rateAtClose, setRateAtClose] = useState<number>(
    Number(initialValues.rateAtClose ?? 0.01)
  );
  const [avgVarRate, setAvgVarRate] = useState<number>(
    Number(initialValues.avgVarRate ?? 0.01)
  );
  const [idleLiquidityPercentage, setIdleLiquidityPercentage] =
    useState<number>(Number(initialValues.idleLiquidityPercentage ?? 0.01));

  const [dailyVolume, setDailyVolume] = useState<number>(
    Number(initialValues.dailyVolume ?? 0)
  );
  const [avgLpApy, setAvgLpApy] = useState<number>(
    Number(initialValues.dailyVolume ?? 0.01)
  );
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [showIdleLiquiditySlider, setShowIdleLiquiditySlider] = useState(false);
  const [showDailyVolumeSlider, setShowDailyVolumeSlider] = useState(false);

  const resetForm = useCallback(() => {
    setDaysToClose(1);
    setRateAtClose(0.01);
    setAvgVarRate(0.01);
    setIdleLiquidityPercentage(0.01);
    setDailyVolume(0);
    setAvgLpApy(0.01);
    setIsFormUpdated(false);
  }, []);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanced]);

  const handleDaysToCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDaysToClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleRateAtCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRateAtClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleAvgVarRateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAvgVarRate(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleIdleLiquidityPercentageChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'custom') {
        setShowIdleLiquiditySlider(true);

        return;
      }
      setIdleLiquidityPercentage(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleIdleLiquidityPercentageCustomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIdleLiquidityPercentage(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleDailyVolumeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'custom') {
        setShowDailyVolumeSlider(true);

        return;
      }

      setDailyVolume(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleDailyVolumeCustomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDailyVolume(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleAvgLpApyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAvgLpApy(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      onSubmit({
        days_to_close:
          String(daysToClose) !== String(initialValues.daysToClose)
            ? String(daysToClose)
            : undefined,
        rate_at_close:
          String(rateAtClose) !== String(initialValues.rateAtClose)
            ? String(rateAtClose)
            : undefined,
        avg_var_rate:
          String(avgVarRate) !== String(initialValues.avgVarRate)
            ? String(avgVarRate)
            : undefined,
        idle_liquidity_percentage:
          String(idleLiquidityPercentage) !==
          String(initialValues.idleLiquidityPercentage)
            ? String(idleLiquidityPercentage)
            : undefined,
        daily_volume:
          String(dailyVolume) !== String(initialValues.dailyVolume)
            ? String(dailyVolume)
            : undefined,
        avg_lp_apy:
          String(avgLpApy) !== String(initialValues.avgLpApy)
            ? String(avgLpApy)
            : undefined
      });

      setIsFormUpdated(false);
    },
    [
      avgVarRate,
      daysToClose,
      initialValues.avgVarRate,
      initialValues.daysToClose,
      initialValues.rateAtClose,
      onSubmit,
      idleLiquidityPercentage,
      dailyVolume,
      rateAtClose,
      avgLpApy,
      initialValues.idleLiquidityPercentage,
      initialValues.dailyVolume,
      initialValues.avgLpApy
    ]
  );

  if (advanced) {
    return (
      <form onSubmit={submit}>
        <Flex
          gap={'space-s'}
          direction={['row', 'column', 'column']}
          backgroundColor="color-background"
          borderRadius="size--4"
          padding={'space-xs'}
          justifyContent={'space-between'}>
          <Flex
            justifyContent={'space-between'}
            gap="space-s"
            direction={'column'}
            alignItems={'stretch'}
            flex="1">
            <Flex
              gap="space-s"
              justifyContent={'space-between'}
              alignContent={'center'}
              direction={['row', 'column', 'column']}>
              <Flex
                direction="column"
                flex={'0 0 33%'}
                gap="space-xs"
                justifyContent={'flex-start'}
                alignItems={'flex-start'}>
                <Flex
                  gap="space-2xs"
                  justifyContent={'center'}
                  alignItems={'center'}>
                  <label className={advanced ? 'text-2' : 'text-3'}>
                    {daysToClose}
                  </label>
                  <label className="text-1 text-muted">days to Close</label>
                </Flex>
                <Slider
                  value={daysToClose}
                  onChange={handleDaysToCloseChange}
                  min={1}
                  max={365}
                />
              </Flex>

              <Flex
                direction="column"
                flex={'0 0 33%'}
                gap="space-xs"
                justifyContent={'center'}
                alignItems={'flex-start'}>
                <Flex
                  gap="space-2xs"
                  justifyContent={'center'}
                  alignItems={'center'}>
                  <label className="text-2">{rateAtClose}%</label>
                  <label className="text-1 text-muted">
                    Expected Fixed Rate at Close
                  </label>
                </Flex>

                <Flex
                  width={'100%'}
                  direction="column"
                  gap="space-3xs"
                  alignItems={'center'}>
                  <Slider
                    value={rateAtClose}
                    onChange={handleRateAtCloseChange}
                    isPercentage={true}
                    min={0.01}
                    max={100}
                    step={'0.01'}
                  />
                  <Text className="text-1 text-muted">
                    Current Fixed Rate:{' '}
                    {initialValues.currentRates['fixed_rate']}%
                  </Text>
                </Flex>
              </Flex>

              <Flex
                direction="column"
                flex={'0 0 33%'}
                gap="space-xs"
                justifyContent={'center'}
                alignItems={'flex-start'}>
                <Flex
                  gap="space-2xs"
                  justifyContent={'center'}
                  alignItems={'flex-start'}>
                  <label className="text-2">{avgVarRate}%</label>
                  <label className="text-1 text-muted">
                    Expected Avg. Variable Rate
                  </label>
                </Flex>

                <Flex
                  width={'100%'}
                  direction="column"
                  gap="space-3xs"
                  alignItems={'center'}>
                  <Slider
                    value={avgVarRate}
                    onChange={handleAvgVarRateChange}
                    isPercentage={true}
                    min={0.01}
                    max={100}
                    step={'0.01'}
                  />
                  <Text className="text-1 text-muted">
                    Current Var. Rate: {initialValues.currentRates['var_apy']}%
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <Flex
              gap="space-s"
              justifyContent={'flex-start'}
              alignItems={'center'}
              direction={['row', 'column', 'column']}>
              {showIdleLiquiditySlider ? (
                <Flex
                  direction={'column'}
                  alignItems={'center'}
                  gap={'space-3xs'}
                  flex="0 0 33%">
                  <Slider
                    label="Idle Liquidity %"
                    value={idleLiquidityPercentage}
                    onChange={handleIdleLiquidityPercentageCustomChange}
                    isPercentage={true}
                    min={0.01}
                    max={100}
                    step={'0.01'}
                  />

                  <Text
                    className="text-1"
                    color={'color-link'}
                    opacity={0.7}
                    hover={{
                      opacity: '1',
                      cursor: 'pointer'
                    }}
                    onClick={() => setShowIdleLiquiditySlider(false)}>
                    select option
                  </Text>
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  alignItems={'flex-start'}
                  gap="space-xs"
                  justifyContent={'center'}
                  flex="0 0 33%">
                  <Flex
                    gap="space-2xs"
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <label className="text-2">{idleLiquidityPercentage}%</label>
                    <label className="text-1 text-muted">
                      Idle Liquidity %
                    </label>
                  </Flex>
                  <Select
                    placeholder="Select Liquidity"
                    options={[
                      ...Object.keys(initialValues.idleLiquidityOptions).map(
                        (key) => ({
                          label: snakeToSpaceCase(key),
                          value: String(initialValues.idleLiquidityOptions[key])
                        })
                      ),
                      { label: 'Custom', value: 'custom' }
                    ]}
                    onChange={handleIdleLiquidityPercentageChange}
                    className={classnames(
                      styles.lpPositionEstimator,
                      styles.select,
                      styles.focused
                    )}
                  />
                </Flex>
              )}

              {showDailyVolumeSlider ? (
                <Flex
                  flex="0 0 33%"
                  direction={'column'}
                  alignItems={'center'}
                  gap={'space-3xs'}>
                  <Slider
                    label="daily volume"
                    value={dailyVolume}
                    onChange={handleDailyVolumeCustomChange}
                    min={0}
                    max={999000000}
                    step={'1000000'}
                    isCurrency={true}
                  />

                  <Text
                    className="text-1"
                    color={'color-link'}
                    opacity={0.7}
                    hover={{
                      opacity: '1',
                      cursor: 'pointer'
                    }}
                    onClick={() => setShowDailyVolumeSlider(false)}>
                    select option
                  </Text>
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  alignItems={'flex-start'}
                  flex="0 0 33%"
                  gap="space-xs"
                  justifyContent={'center'}>
                  <Flex
                    gap="space-2xs"
                    justifyContent={'center'}
                    alignItems={'center'}>
                    <label className="text-2">
                      {formatCurrency(dailyVolume)}
                    </label>
                    <label className="text-1 text-muted">daily volume</label>
                  </Flex>
                  <Select
                    placeholder="Select Volume"
                    options={[
                      ...Object.keys(initialValues.dailyVolumeOptions).map(
                        (key) => ({
                          label: snakeToSpaceCase(key),
                          value: String(initialValues.dailyVolumeOptions[key])
                        })
                      ),
                      { label: 'Custom', value: 'custom' }
                    ]}
                    onChange={handleDailyVolumeChange}
                    className={classnames(
                      styles.lpPositionEstimator,
                      styles.select,
                      styles.focused
                    )}
                  />
                </Flex>
              )}
            </Flex>
          </Flex>

          <ApplyButton type="submit" disabled={!isFormUpdated}>
            {loading ? 'Loading...' : 'Apply'}
          </ApplyButton>
        </Flex>
      </form>
    );
  }

  return (
    <form onSubmit={submit}>
      <Flex
        justifyContent={'space-between'}
        gap="space-s"
        direction={['row', 'column', 'column']}
        backgroundColor="color-background"
        borderRadius="size--4"
        padding={'space-xs'}
        alignItems={['flex-start', 'flex-start', 'center']}>
        <Flex
          direction="column"
          width={'100%'}
          gap="space-xs"
          justifyContent={'center'}
          alignItems={'flex-start'}>
          <Flex gap="space-2xs" justifyContent={'center'} alignItems={'center'}>
            <label className={advanced ? 'text-2' : 'text-3'}>
              {daysToClose}
            </label>
            <label className="text-1 text-muted">days to Close</label>
          </Flex>
          <Slider
            value={daysToClose}
            onChange={handleDaysToCloseChange}
            min={1}
            max={365}
          />
        </Flex>

        <Flex
          direction="column"
          width={'100%'}
          gap="space-xs"
          justifyContent={'flex-start'}>
          <Flex gap="space-2xs" alignItems={'center'}>
            <label className="text-3">{avgLpApy}%</label>
            <label className="text-1 text-muted">Expected Average LP APY</label>
          </Flex>

          <Flex
            width={'100%'}
            direction="column"
            gap="space-3xs"
            alignItems={'center'}>
            <Slider
              value={avgLpApy}
              onChange={handleAvgLpApyChange}
              isPercentage={true}
              min={0.01}
              max={100}
              step={'0.01'}
            />
            <Text className="text-1 text-muted">
              Current LP APY: {initialValues.currentRates['lp_apy']}%
            </Text>
          </Flex>
        </Flex>

        <ApplyButton type="submit" disabled={!isFormUpdated}>
          {loading ? 'Loading...' : 'Apply'}
        </ApplyButton>
      </Flex>
    </form>
  );
}
