import { useApi } from '@/api';
import { useSearchParams } from '@/hooks';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';
import { Flex, Box } from '@blockanalitica/ui';
import {
  useParams,
  useSearchParams as useRRSearchParams
} from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import NewLpPositionPnlEstimatorForm from './components/NewLpPositionPnlEstimatorForm';

const initialData: Partial<MarketLPData> = {
  results: {
    pnl: '0',
    pnl_usd: '0',
    starting_balance: '0',
    starting_balance_usd: '0',
    ending_balance: '0',
    ending_balance_usd: '0'
  },
  dropdown_data: {
    volume: {
      average_volume_1d: 0,
      average_volume_7d: 0,
      average_volume_30d: 0,
      average_volume_90d: 0,
      average_volume_365d: 0
    },
    idle_liquidity: {
      average_idle_liquidity_1d: '0',
      average_idle_liquidity_7d: '0',
      average_idle_liquidity_30d: '0',
      average_idle_liquidity_90d: '0',
      average_idle_liquidity_365d: '0'
    }
  },
  stats: {
    fixed_rate: '0',
    var_apy: '0',
    lp_apy: '0'
  }
};

export type LPMarketStat = {
  var_apy: string;
  fixed_rate: string;
  lp_apy: string;
};

type DailyVolumeOptions =
  | 'average_volume_1d'
  | 'average_volume_7d'
  | 'average_volume_30d'
  | 'average_volume_90d'
  | 'average_volume_365d';

export type LPMarketVolume = Record<DailyVolumeOptions, number>;

type IdleLiquidityOptions =
  | 'average_idle_liquidity_1d'
  | 'average_idle_liquidity_7d'
  | 'average_idle_liquidity_30d'
  | 'average_idle_liquidity_90d'
  | 'average_idle_liquidity_365d';

export type LPIdleLiquidity = Record<IdleLiquidityOptions, string>;

type MarketLPData = {
  results: {
    pnl: string;
    pnl_usd: string;
    starting_balance: string;
    starting_balance_usd: string;
    ending_balance: string;
    ending_balance_usd: string;
  };
  dropdown_data: {
    volume: LPMarketVolume;
    idle_liquidity: LPIdleLiquidity;
  };
  stats: LPMarketStat;
  asset_symbol: 'ETH';
};

export default function NewLpPositionPnlEstimator() {
  const [assetId] = useSearchParams('asset_id', '');
  const { marketId } = useParams();
  const [daysToClose] = useSearchParams('days_to_close');
  const [type] = useSearchParams('lpType', 'simple');
  const [rateAtClose] = useSearchParams('rate_at_close');
  const [positionSize] = useSearchParams('position_size');
  const [avgLpApy] = useSearchParams('avg_lp_apy');
  const [avgVarRate] = useSearchParams('avg_var_rate');
  const [dailyVolume] = useSearchParams('daily_volume');
  const [idleLiquidityPercentage] = useSearchParams(
    'idle_liquidity_percentage'
  );

  const [, setRouterSearchParams] = useRRSearchParams();

  const updateSearchParams = useCallback(
    (searchParams: Record<string, string>) => {
      setRouterSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);

        Object.entries(searchParams).forEach(([key, value]) => {
          if (value !== undefined) {
            updatedSearchParams.set(key, String(value));
          }
        });

        return updatedSearchParams;
      });
    },
    [setRouterSearchParams]
  );

  const [data, error] = useApi<MarketLPData>({
    url: `/markets/${marketId}/open-lp/`,
    queryParams: {
      asset_id: assetId,
      daysToClose: daysToClose,
      rateAtClose: rateAtClose,
      positionSize: positionSize,
      avgVarRate: avgVarRate,
      dailyVolume: dailyVolume,
      idleLiquidityPercentage: idleLiquidityPercentage,
      lpApy: avgLpApy,
      type: type
    }
  });

  const [cachedData, setCachedData] =
    useState<Partial<MarketLPData>>(initialData);

  useEffect(() => {
    if (data !== undefined) {
      setCachedData((prevState) => ({ ...prevState, ...data }));
    }
  }, [data]);

  const resetData = useCallback(() => setCachedData(initialData), []);

  if (error) {
    throw error;
  }

  return (
    <Flex direction="column" gap="space-m">
      <Flex direction={['row', 'column', 'column']} gap="space-xs">
        <Box flexBasis={['33%', '100%', '100%']}>
          <KpiCurrencyCard
            title="Estimated Starting Balance"
            data={cachedData['results']}
            field="starting_balance"
            trend={false}
            currency={cachedData?.asset_symbol}
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              iconSize: 'large',
              dynamic: false
            }}
          />
        </Box>
        <Box flexBasis={['33%', '100%', '100%']}>
          <KpiCurrencyCard
            title="Estimated Closing Balance"
            data={cachedData['results']}
            field="ending_balance"
            trend={false}
            currency={cachedData?.asset_symbol}
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              iconSize: 'large',
              dynamic: false
            }}
          />
        </Box>
        <Box flexBasis={['33%', '100%', '100%']}>
          <KpiCurrencyCard
            title="Estimated PnL"
            data={cachedData['results']}
            field="pnl"
            trend={false}
            currency={cachedData?.asset_symbol}
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              iconSize: 'large',
              dynamic: false
            }}
          />
        </Box>
      </Flex>

      <NewLpPositionPnlEstimatorForm
        loading={data === undefined}
        onSubmit={updateSearchParams}
        onReset={resetData}
        advanced={type === 'advanced'}
        initialValues={{
          daysToClose,
          rateAtClose,
          positionSize,
          avgVarRate,
          avgLpApy,
          dailyVolume,
          idleLiquidityPercentage,
          dailyVolumeOptions: cachedData
            ? cachedData['dropdown_data']['volume']
            : {},
          idleLiquidityOptions: cachedData
            ? cachedData['dropdown_data']['idle_liquidity']
            : {},
          currentRates: cachedData ? cachedData['stats'] : {}
        }}
      />
    </Flex>
  );
}
