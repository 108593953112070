import {
  Box,
  Flex,
  formatCurrency,
  formatPercentage
} from '@blockanalitica/ui';
import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledSlider = styled.input<SliderProps>`
  background-color: ${(props) => props.trackColor};
  opacity: 0.7;
  accent-color: ${(props) => props.fillColor};
  width: 100%;
  max-width: 400px;
  height: 4px;
  border-radius: 5px;
  outline: none;
  transition: opacity 0.2s;
  position: relative;

  &::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    width: var(--size--1);
    height: var(--size--1);
    border-radius: 50%;
    background: ${(props) => props.thumbColor || 'var(--color-primary)'};
    cursor: pointer;
    border: none;
  }

  /* Firefox */
  &::-moz-range-progress {
    background-color: ${(props) => props.fillColor};
  }

  &::-moz-range-track {
    background-color: ${(props) => props.trackColor};
  }

  &::-moz-range-thumb {
    width: var(--size--1);
    height: var(--size--1);
    border-radius: 50%;
    background: ${(props) => props.thumbColor || 'var(--color-primary)'};
    cursor: pointer;
    border: none;
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;

    &::-webkit-slider-thumb {
      cursor: not-allowed;
    }

    &::-moz-range-thumb {
      cursor: not-allowed;
    }
  }
`;

interface SliderProps extends Omit<InputHTMLAttributes<'range'>, 'onChange'> {
  value: number | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
  trackColor?: string;
  fillColor?: string;
  isPercentage?: boolean;
  isCurrency?: boolean;
  thumbColor?: string;
  step?: string;
  label?: string;
  limitsLabels?: boolean;
}

export default function Slider({
  value,
  onChange,
  min = 0,
  max = 100,
  trackColor = 'var(--color-onSurface400)',
  fillColor = 'var(--color-primary)',
  thumbColor = 'var(--color-primary)',
  isPercentage = false,
  isCurrency = false,
  step,
  label,
  limitsLabels,
  ...rest
}: SliderProps) {
  let minValue = String(min);
  let maxValue = String(max);

  if (isPercentage) {
    minValue = formatPercentage(min / 100);
    maxValue = formatPercentage(max / 100);
  }
  if (isCurrency) {
    minValue = formatCurrency(min);
    maxValue = formatCurrency(max);
  }
  return (
    <Flex
      gap="space-2xs"
      alignItems={'center'}
      width="100%"
      justifyContent={'center'}>
      {limitsLabels ? (
        <Box
          className="text-muted text-1"
          alignSelf={'flex-end'}
          padding="var(--space-3xs) 0">
          {minValue}
        </Box>
      ) : null}

      <Flex
        direction="column"
        gap="space-2xs"
        alignItems={'flex-start'}
        justifyContent={'flex-end'}
        width="100%">
        {label ? (
          <Flex
            gap="space-3xs"
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <label className="text-2">
              {' '}
              {isCurrency ? formatCurrency(Number(value)) : value}
              {isPercentage ? '%' : ''}
            </label>
            <label className="text-1 text-muted">{label}</label>
          </Flex>
        ) : null}

        <Box padding="var(--space-3xs) 0" width="100%">
          <StyledSlider
            type="range"
            min={min}
            max={max}
            step={step}
            value={Number(value)}
            onChange={onChange}
            trackColor={trackColor}
            fillColor={fillColor}
            thumbColor={thumbColor}
            {...rest}
          />
        </Box>
      </Flex>

      {limitsLabels ? (
        <Box
          className="text-muted text-1"
          alignSelf={'flex-end'}
          padding="var(--space-3xs) 0">
          {maxValue}
        </Box>
      ) : null}
    </Flex>
  );
}
