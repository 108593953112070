import { useApi } from '@/api';
import EventName from '@/components/events/EventName';
import SelectFilter from '@/components/filters/SelectFilter';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { useSearchParams } from '@/hooks';

import { EVENT_TYPE_MAPPING, POSITION_EVENTS } from '@/constants';
import {
  CurrencyValue,
  EtherScanPictogram,
  Flex,
  RelativeDateTime,
  useTableProps
} from '@blockanalitica/ui';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { smartEtherscanUrl } from '../../../../../../utils/url.js';

export default function MarketPositionEventsTable() {
  const location = useLocation();
  const etherscanUrl = smartEtherscanUrl(location);

  const selectFilterOptions = useMemo(() => {
    let options = [{ label: 'All events', value: '' }];
    options.push(
      ...POSITION_EVENTS.map((event) => ({
        label: EVENT_TYPE_MAPPING[event],
        value: event
      }))
    );
    return options;
  }, []);
  const { sorting, filters, pagination } = useTableProps({
    pageSize: 25,
    filtersDebounceTime: 50
  });
  const { marketId, walletAddress, positionType } = useParams();
  const [assetId] = useSearchParams('asset_id', '');

  const [data, error] = useApi({
    url: `/markets/${marketId}/positions/${walletAddress}/events/${positionType}/`,
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      event: filters.filtersApplied.eventType,
      order: sorting.currentSorting,
      asset_id: assetId
    }
  });

  const content = useMemo(() => {
    if (data) {
      const columns = [
        {
          header: 'EVENT',
          getCell: (row) => <EventName event={row.event} />
        },
        {
          header: 'BASE AMOUNT',
          getCell: (row) => (
            <Flex alignItems={'center'}>
              <CurrencyValue
                value={row.base_amount}
                options={{
                  currency: row.asset_symbol,
                  iconOpacity: 0.7,
                  leadingCurrencySymbol: false,
                  iconSize: 'xSmall'
                }}
              />
            </Flex>
          ),
          cellAlign: 'flex-end',
          headerAlign: 'flex-end',
          sortingField: 'base_amount'
        },
        {
          header: 'SIZE',
          getCell: (row) => (
            <Flex alignItems={'center'} gap={'space-xs'}>
              <CurrencyValue
                value={row.bond_amount}
                options={{
                  currency: row.asset_symbol,
                  iconOpacity: 0.7,
                  leadingCurrencySymbol: false,
                  iconSize: 'xSmall'
                }}
              />
            </Flex>
          ),
          cellAlign: 'flex-end',
          headerAlign: 'flex-end',
          sortingField: 'bond_amount'
        },
        {
          header: 'TIME',
          getCell: (row) => <RelativeDateTime value={row.datetime} />,
          getCellBottom: (row) => (
            <Flex gap="space-3xs" className="text-muted">
              {row.block_number}
              <a
                href={`${etherscanUrl}tx/${row.tx_hash}`}
                target="_blank"
                rel="noreferrer">
                <EtherScanPictogram size={14} />
              </a>
            </Flex>
          ),
          cellAlign: 'flex-end',
          headerAlign: 'flex-end',
          sortingField: 'order_index'
        }
      ];
      return (
        <Table
          rows={data['results']}
          columns={columns}
          getKey={(row) => `${row.tx_hash}`}
          sorting={sorting}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
          getLinkWrapper={undefined}
        />
      );
    }
    return <TableLoader />;
  }, [data, sorting, pagination, etherscanUrl]);

  if (error) {
    throw error;
  }

  return (
    <Flex direction={['column']} gap={'space-xs'}>
      <Flex justifyContent="space-between" alignItems="center">
        <h4>Events</h4>
        <SelectFilter
          key="event-filter"
          placeholder="Showing all events"
          filterField="eventType"
          options={selectFilterOptions}
          onPageChange={pagination.onPageChange}
          {...filters}
        />
      </Flex>
      {content}
    </Flex>
  );
}
