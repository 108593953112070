import { useSearchParams } from '@/hooks';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import {
  CurrencyFilter,
  Flex,
  useTableProps,
  Grid1X2Icon
} from '@blockanalitica/ui';
import PositionsTableType from './components/PositionsTableType';
import { Page } from '@/components';
import PositionsLpTable from './components/PositionsLpTable';
import { useCallback, useMemo } from 'react';

const displayOptions = [
  { key: 'long', value: 'Long' },
  { key: 'short', value: 'Short' },
  { key: 'lp', value: 'LP' }
];

export default function MarketPositionsWalletAddressPage() {
  const [daysAgo] = useSearchParams('days_ago', '7');
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'long'
  );
  const { filters, sorting, pagination } = useTableProps({
    initialFilters: { state: 'open', currency: 'token' },
    filtersDebounceTime: 50
  });

  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const tabsContent = useMemo(
    () => ({
      long: (
        <PositionsTableType
          daysAgo={daysAgo}
          type={activeDisplayOption}
          filters={filters}
          sorting={sorting}
          pagination={pagination}
        />
      ),
      short: (
        <PositionsTableType
          daysAgo={daysAgo}
          type={activeDisplayOption}
          filters={filters}
          sorting={sorting}
          pagination={pagination}
        />
      ),
      lp: (
        <PositionsLpTable
          daysAgo={daysAgo}
          filters={filters}
          sorting={sorting}
          pagination={pagination}
        />
      )
    }),
    [activeDisplayOption, daysAgo, filters, sorting, pagination]
  );

  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <Grid1X2Icon />
          <h1>Wallet Positions</h1>
        </Flex>
      }
      filters={filters}>
      <Flex
        gap="space-xs"
        direction={['row', 'row', 'column']}
        justifyContent={['space-between', 'space-between', 'space-between']}>
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'flex-start']}
          justifyContent="space-between"
          alignSelf={['center', 'center', 'flex-start']}
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={handleTabClick}
          />
        </Flex>
        {activeDisplayOption != 'lp' && (
          <CurrencyFilter
            key="open-closed"
            width="8rem"
            height="auto"
            style={{ minHeight: '1.6rem' }}
            currency1={{ value: 'open', displayValue: 'OPEN' }}
            currency2={{ value: 'closed', displayValue: 'CLOSED' }}
            filterField="state"
            {...filters}
          />
        )}
        <CurrencyFilter
          key="currency-filter"
          width="8rem"
          height="auto"
          style={{ minHeight: '1.6rem' }}
          currency1={{ value: 'token', displayValue: 'TOKEN' }}
          currency2={{ value: 'USD', displayValue: 'USD' }}
          {...filters}
        />
      </Flex>
      {tabsContent[activeDisplayOption]}
    </Page>
  );
}
