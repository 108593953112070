import classnames from 'classnames';
import { ReactNode, memo, useCallback } from 'react';
import {
  Path,
  NavLink as RouterNavLink,
  useLocation,
  useParams
} from 'react-router-dom';
import styles from './NavLink.module.scss';

type NavLinkProps = {
  isRoot?: boolean;
  isSingleLink?: boolean;
  text: ReactNode;
  href: string | Partial<Path>;
  className?: string;
  forwardSearch?: boolean;
  daysAgo?: boolean;
  force?: boolean;
};

const NavLink = memo(function NavLink({
  isRoot = false,
  isSingleLink = false,
  text,
  href,
  className,
  forwardSearch = false,
  daysAgo = true,
  force = false
}: NavLinkProps) {
  const location = useLocation();
  const { network } = useParams();

  const toPath = typeof href === 'object' ? href.pathname : href;
  const pathIsRoot = toPath === '/';
  let toPathWithNetwork = pathIsRoot ? `/${network}` : `/${network}${toPath}`;

  if (force) {
    toPathWithNetwork = '/';
  }

  const constructToPath = useCallback(() => {
    if (daysAgo) {
      const toSearchParams = new URLSearchParams();
      const currentSearchParams = new URLSearchParams(location.search);

      const daysAgoValue = currentSearchParams.get('days_ago');
      if (daysAgoValue) {
        toSearchParams.set('days_ago', daysAgoValue);
      }

      const searchParamsString = toSearchParams.toString()
        ? `?${toSearchParams.toString()}`
        : '';
      return typeof href === 'object'
        ? { ...href, search: searchParamsString }
        : `${toPathWithNetwork}${searchParamsString}`;
    }
    if (forwardSearch) {
      const hasQueryParams = toPathWithNetwork.includes('?');
      const searchParams = hasQueryParams
        ? location.search.replace('?', '&')
        : location.search;

      return typeof href === 'object'
        ? { ...href, search: searchParams }
        : `${toPathWithNetwork}${searchParams}`;
    }

    return toPathWithNetwork;
  }, [daysAgo, forwardSearch, toPathWithNetwork, location.search, href]);

  return (
    <RouterNavLink
      className={({ isActive }) =>
        classnames(
          'text-2',
          styles.link,
          {
            'text-3': isRoot,
            [styles.active]:
              (isActive && isSingleLink) || (isActive && !isRoot),
            [styles.header]: isRoot
          },
          className
        )
      }
      key={String(text)}
      to={constructToPath()}
      end={pathIsRoot}>
      {text}
    </RouterNavLink>
  );
});

export default NavLink;
