import Svg from './Svg';

export default function ArrowRightIcon({
  size = 32,
  color = 'currentColor',
  ...props
}) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 21 18"
      width={size + 'px'}
      height={size - 3 + 'px'}
      {...props}>
      <path
        d="M11.5157 0.484299C11.1252 0.0937752 10.492 0.0937752 10.1015 0.484299C9.71096 0.874824 9.71096 1.50799 10.1015 1.89851L16.6301 8.00046H1C0.447715 8.00046 0 8.44817 0 9.00046C0 9.55274 0.447715 10.0005 1 10.0005H16.6302L10.1015 16.1025C9.71096 16.493 9.71096 17.1262 10.1015 17.5167C10.492 17.9072 11.1252 17.9072 11.5157 17.5167L20.0319 9.00049L11.5157 0.484299Z"
        fill={color}
      />
    </Svg>
  );
}
