import { useMemo } from 'react';

import styles from './HeatmapActivity.module.scss';

import { formatDatetime, hexToRgb } from '@blockanalitica/ui';
import Tooltip, {
  TooltipContent,
  TooltipOptions,
  TooltipTrigger
} from '@blockanalitica/ui/package/esm/src/components/client-components/Tooltip/Tooltip';
import Box, {
  BoxProps
} from '@blockanalitica/ui/package/esm/src/components/server-components/layout/Box/Box';
import classnames from 'classnames';
import { DateTime } from 'luxon';

export type HeatmapActivityData = {
  date: string;
  value: number;
};

interface HeatmapActivityProps extends BoxProps {
  data: HeatmapActivityData[];
  options?: {
    formatTooltip?: (cell: number, cellDate: DateTime) => string;
    onClick?: (
      event: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
      data: HeatmapActivityData
    ) => void;
    heatColor?: string;
    monthsAgo?: number;
    columnLabelBoxProps?: BoxProps;
    rowBoxProps?: BoxProps;
    rowLabelBoxProps?: BoxProps;
    cellClassName?: string;
    cellStyle?: React.CSSProperties;
    tooltipOptions?: TooltipOptions;
    backgroundColor?: string;
  };
}

function parseUTCDate(dateString: string) {
  return DateTime.fromISO(dateString);
}

type Column = {
  label: string;
  colSpan: number;
};

export default function HeatmapActivity({
  data,
  options,
  className,
  ...boxProps
}: HeatmapActivityProps) {
  const daysInWeek = 7;
  const heatmapData = useMemo(() => {
    const daysOfTheYear = [];
    const today = DateTime.now().startOf('day');
    let monthsAgo = today.minus({ months: options?.monthsAgo ?? 12 });
    monthsAgo = monthsAgo.startOf('week');

    const result: number[][] = Array.from({ length: daysInWeek }, () => []);
    const columns: Column[] = [];

    for (let d = monthsAgo; d <= today; d = d.plus({ days: 1 })) {
      const rowIndex = d.weekday - 1;
      result[rowIndex].push(0);
      daysOfTheYear.push(d);

      const prevDate = d.minus({ days: 1 });

      if (d.month !== prevDate.month) {
        const daysDifference =
          (d.toMillis() - monthsAgo.toMillis()) / (1000 * 60 * 60 * 24);

        const weeks = Math.floor(daysDifference / daysInWeek);
        const colSpanSum = columns.reduce((a, b) => a + b.colSpan, 0);
        const colSpan = weeks - colSpanSum;
        if (colSpan > 1) {
          columns.push({
            label: prevDate.toFormat('MMM'),
            colSpan
          });
        }
      }

      if (d.hasSame(today, 'day')) {
        const colSpanSum = columns.reduce((a, b) => a + b.colSpan, 0);
        const colSpan = result[0].length - colSpanSum;

        if (colSpan > 1) {
          columns.push({
            label: d.toFormat('MMM'),
            colSpan: result[0].length - colSpanSum
          });
        }
      }
    }

    data.forEach((item) => {
      const date = parseUTCDate(item.date);
      const daysDifference =
        (date.toMillis() - monthsAgo.toMillis()) / (1000 * 60 * 60 * 24);

      const rowIndex = date.weekday - 1;
      const columnIndex = Math.floor(daysDifference / daysInWeek);

      if (result[rowIndex][columnIndex] > 0) {
        result[rowIndex][columnIndex] += item.value;
      } else {
        result[rowIndex][columnIndex] = item.value;
      }
    });

    return { data: result, columns, daysOfTheYear };
  }, [data, options?.monthsAgo]);

  const maxVal = useMemo(
    () => Math.max(...heatmapData.data.flat()),
    [heatmapData]
  );
  const heatColor = useMemo(
    () =>
      options?.heatColor ? hexToRgb(options.heatColor) : { r: 0, g: 255, b: 0 },
    [options?.heatColor]
  );

  const backgroundColor = useMemo(
    () =>
      options?.backgroundColor
        ? hexToRgb(options.backgroundColor)
        : { r: 0, g: 255, b: 0 },
    [options?.backgroundColor]
  );

  const rowLabels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <Box {...boxProps} className={classnames(styles.container, className)}>
      <Box as="table" className={styles.table}>
        <thead>
          <Box as="tr" className={styles.headerRow}>
            <Box
              as="td"
              className={styles.label}
              style={{ position: 'relative', width: '30px' }}></Box>
            {heatmapData.columns.map(({ label, colSpan }, index) => {
              return (
                <td colSpan={colSpan} key={index} className={styles.label}>
                  <Box as="span" {...options?.columnLabelBoxProps}>
                    {label}
                  </Box>
                </td>
              );
            })}
          </Box>
        </thead>

        <tbody>
          {heatmapData.data.map((row, rowIndex) => (
            <Box
              as="tr"
              {...options?.rowBoxProps}
              className={classnames(
                styles.dataRow,
                options?.rowBoxProps?.className
              )}
              key={rowIndex}>
              {rowLabels ? (
                <td className={styles.label} style={{ position: 'relative' }}>
                  <Box
                    as="span"
                    {...options?.rowLabelBoxProps}
                    className={classnames(
                      styles.rowLabel,
                      options?.rowLabelBoxProps?.className
                    )}>
                    {rowLabels[rowIndex]}
                  </Box>
                </td>
              ) : null}

              {row.map((cell, cellIndex) => {
                const cellDateIndex = cellIndex * daysInWeek + rowIndex;
                const cellDate =
                  heatmapData.daysOfTheYear[cellDateIndex] || DateTime.now();
                return (
                  <Tooltip key={cellIndex} {...options?.tooltipOptions}>
                    <TooltipTrigger asChild>
                      <td
                        onClick={
                          options?.onClick !== undefined
                            ? (e) =>
                                options.onClick(e, {
                                  date: cellDate.toISO(),
                                  value: cell
                                })
                            : undefined
                        }
                        className={classnames(
                          styles.dataCell,
                          options?.cellClassName
                        )}
                        style={{
                          backgroundColor:
                            cell == 0
                              ? `rgba(${backgroundColor.r}, ${backgroundColor.g}, ${backgroundColor.b}, 0.6)`
                              : `rgba(${heatColor.r}, ${heatColor.g}, ${
                                  heatColor.b
                                }, ${Math.max(cell / maxVal, 0.2)})`,
                          ...options?.cellStyle
                        }}>
                        <span className={styles.cellValue}>{cell}</span>
                      </td>
                    </TooltipTrigger>

                    <TooltipContent>
                      {options?.formatTooltip
                        ? options?.formatTooltip(cell, cellDate)
                        : `${cell} events on ${formatDatetime(cellDate.toISO())}`}
                    </TooltipContent>
                  </Tooltip>
                );
              })}
            </Box>
          ))}
        </tbody>
      </Box>
    </Box>
  );
}
