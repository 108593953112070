import { useApi } from '@/api';
import { Heatmap } from '@/components/charts';
import { LinkButton } from '@/components/forms';
import { CardLoader, Flex, useIsMobile } from '@blockanalitica/ui';

export default function ActivityHeatmap() {
  const isMobile = useIsMobile();
  const monthsAgo = isMobile ? 3 : 12;

  let [data] = useApi({
    url: `/activity/heatmap`
  });

  if (data) {
    return (
      <Flex gap="space-xs" direction="column">
        <Flex gap="space-xs" justifyContent="space-between" alignItems="center">
          <h4>Events Activity</h4>
          <LinkButton to="events">view all</LinkButton>
        </Flex>
        <Flex
          direction={['row', 'column', 'column']}
          gap="space-m"
          justifyContent="center">
          <Heatmap data={data} monthsAgo={monthsAgo} />
        </Flex>
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
