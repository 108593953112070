import { Box, Flex } from '@blockanalitica/ui';
import Card from './Card';
import styles from './CardList.module.scss';
import { CardProps } from '@blockanalitica/ui/package/esm/src/components/server-components/Card/Card';
import { ReactNode } from 'react';

interface CardListProps extends CardProps {
  title?: string;
  actions?: ReactNode;
}

export default function CardList({
  children,
  title,
  actions,
  ...rest
}: CardListProps) {
  return (
    <Card {...rest}>
      {title || actions ? (
        <Flex
          alignItems="center"
          justifyContent="space-between"
          className={styles.header}>
          <Box as="span">{title}</Box>
          {actions}
        </Flex>
      ) : null}

      <Box fontSize="size--1">{children}</Box>
    </Card>
  );
}
