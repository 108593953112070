import { LinkProps } from 'react-router-dom';
import styles from './ExternalLinkButton.module.scss';

export default function ExternalLinkButton({
  children,
  to,
  ...rest
}: LinkProps & React.RefAttributes<HTMLAnchorElement>) {
  return (
    <a
      className={styles.btn}
      target="_blank"
      rel="noopener noreferrer"
      href={to}
      {...rest}>
      {children}
    </a>
  );
}
