import { Flex, Kpi, KpiBody, KpiFooter } from '@blockanalitica/ui';
import { default as classNames, default as classnames } from 'classnames';
import { ReactNode } from 'react';

export type KpiCardProps = {
  title?: string;
  value: ReactNode;
  trend?: ReactNode;
  footer?: ReactNode;
  daysAgo?: number;
  options?: {
    trendSize?: string;
    valueSize?: string;
    footerSize?: string;
  };
};

export default function KpiCard({
  title,
  value,
  trend,
  footer,
  options,
  ...rest
}: KpiCardProps) {
  return (
    <Kpi {...rest}>
      <Flex
        justifyContent={'space-between'}
        className={classNames('text-2', 'text-muted')}
        {...rest}>
        <div>{title}</div>
      </Flex>
      <KpiBody gap="space-xs" alignItems="center" justifyContent="flex-start">
        <div
          className={classnames(options?.valueSize ?? 'text-4', 'text-mono')}>
          {value}
        </div>
        <div
          className={classnames(options?.trendSize ?? 'text-3', 'text-mono')}>
          {trend}
        </div>
      </KpiBody>
      {footer && (
        <KpiFooter>
          <div
            className={classnames(
              options?.footerSize ?? 'text-2',
              'text-mono'
            )}>
            {footer}
          </div>
        </KpiFooter>
      )}
    </Kpi>
  );
}
