export default function LegalDisclaimer() {
  return (
    <label className="text-2">
      This Profit and Loss (PnL) Estimator is provided as a convenience and for
      informational purposes only. The tool is not designed to capture all
      possible scenarios and variances. E.g., it does not take into account the
      AMM formulas utilized by the Hyperdrive protocol, which may introduce
      discrepancies between this tool and the Hyperdrive app’s UI. Estimates are
      based on the input values and selected parameters provided by the user and
      should not be relied upon for the purposes of making investment decisions.
      Actual results may vary. DELV makes no representations or warranties
      regarding the tool, including without limitation its accuracy,
      reliability, or completeness. DELV will not be liable for any losses or
      damages arising from reliance on the tool. Use at your own risk.
    </label>
  );
}
