import { Page } from '@/components';
import { useSearchParams } from '@/hooks';
import {
  Flex,
  useTableProps,
  MarketIcon,
  CurrencyFilter
} from '@blockanalitica/ui';
import MarketsTable from '@/pages/components/MarketsTable';

export default function MarketsPage() {
  const [daysAgo] = useSearchParams('days_ago', '7');

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <MarketIcon />
          <h1>Markets</h1>
        </Flex>
      }>
      <Flex gap="space-m" justifyContent="flex-end">
        <CurrencyFilter
          key="currency-filter"
          width="8rem"
          height="1.65rem"
          currency2={{ value: 'token', displayValue: 'Token' }}
          currency1={{ value: 'USD', displayValue: 'USD' }}
          {...filters}
        />
      </Flex>

      <MarketsTable daysAgo={daysAgo} filters={filters} />
    </Page>
  );
}
