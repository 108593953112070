import {
  Box,
  ChevronDownIcon,
  ChevronRightIcon,
  Flex,
  useToggle
} from '@blockanalitica/ui';
import classnames from 'classnames';
import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './NavigationMenu.module.scss';
import { NavigationData } from '../layouts/FixedSidebarLayout/FixedSidebarLayout';

type MenuItemProps = {
  item: NavigationData;
};

export default function SidebarNavigationItem({ item }: MenuItemProps) {
  const [menuIsOpen, toggleMenu] = useToggle();
  const singleLinkMenu = item.subMenu === undefined && item.isRoot;
  const hasChilds = item.subMenu !== undefined;
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(item.url)) {
      toggleMenu(true);

      return;
    }

    toggleMenu(false);
  }, [item.url, location.pathname, toggleMenu]);

  return (
    <Fragment>
      <Flex
        as="summary"
        alignItems="center"
        justifyContent="space-between"
        className={classnames(styles.headerContainer)}
        onClick={singleLinkMenu ? undefined : () => toggleMenu()}>
        <Flex gap="space-2xs" alignItems="center" width="100%">
          <Flex direction="column" alignItems="center" flex="0 0 20px">
            {item.icon}
          </Flex>
          {item.isRoot && !singleLinkMenu ? (
            <Box className={classnames('text-3')}>{item.title}</Box>
          ) : (
            <Box
              width="100%"
              role="button"
              aria-label={'SideBar Menu title' + item.title}>
              {item.renderLink({
                url: item.url,
                title: item.title,
                icon: item.icon
              })}
            </Box>
          )}
        </Flex>

        {hasChilds && item.isRoot ? (
          menuIsOpen ? (
            <ChevronDownIcon size={14} />
          ) : (
            <ChevronRightIcon size={14} />
          )
        ) : null}
      </Flex>

      {item.subMenu && menuIsOpen ? (
        <Flex
          className={styles.subMenuContainer}
          role="menu"
          aria-label="Sidebar sub menu"
          direction="column"
          gap="space-3xs">
          {item.subMenu.map((item, index) => (
            <SidebarNavigationItem
              key={item.title + '_' + index}
              aria-label={item.title + 'sub menu link'}
              item={item}
            />
          ))}
        </Flex>
      ) : null}
    </Fragment>
  );
}
