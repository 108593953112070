import { useApi } from '@/api';
import { useSearchParams } from '@/hooks';
import {
  ChevronDownIcon,
  ChevronUpIcon,
  CurrencyFilter,
  Flex,
  useTableProps,
  useThemeCustomProperties,
  useToggle
} from '@blockanalitica/ui';

import {
  useParams,
  useSearchParams as useRRSearchParams
} from 'react-router-dom';
import { Fragment, useCallback, useEffect, useState } from 'react';
import CurrencySwitchLineChart, {
  LineChartData
} from '@/components/charts/Linechart/CurrencySwitchLineChart';
import LpPositionPnlEstimatorFormTabs from './components/LpPositionPnlEstimatorFormTabs';
import LegalDisclaimer from '@/pages/components/LegalDisclaimer';
type LpPositionPnlEstimatorProps = {
  currency: string;
};

export default function PositionPnlEstimator({
  currency
}: LpPositionPnlEstimatorProps) {
  const [open, toggle] = useToggle();
  const { marketId, walletAddress } = useParams();
  const [avgLpApy] = useSearchParams('avg_lp_apy');
  const [daysToClose] = useSearchParams('days_to_close');
  const [type] = useSearchParams('type', 'simple');
  const [rateAtClose] = useSearchParams('rate_at_close');
  const [avgVarRate] = useSearchParams('avg_var_rate');
  const [dailyVolume] = useSearchParams('daily_volume');
  const [idleLiquidityPercentage] = useSearchParams(
    'idle_liquidity_percentage'
  );

  const [, setRouterSearchParams] = useRRSearchParams();

  const updateSearchParams = useCallback(
    (searchParams: Record<string, string>) => {
      setRouterSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);

        Object.entries(searchParams).forEach(([key, value]) => {
          if (value !== undefined) {
            updatedSearchParams.set(key, String(value));
          }
        });

        return updatedSearchParams;
      });
    },
    [setRouterSearchParams]
  );

  const [data, error] = useApi({
    url: `/markets/${marketId}/positions/${walletAddress}/lp/pnl-projection/`,
    queryParams: {
      daysToClose: daysToClose,
      rateAtClose: rateAtClose,
      avgVarRate: avgVarRate,
      dailyVolume: dailyVolume,
      idleLiquidityPercentage: idleLiquidityPercentage,
      lpApy: avgLpApy,
      type: type
    }
  });

  const [cachedData, setCachedData] = useState(undefined);

  useEffect(() => {
    if (data !== undefined) {
      setCachedData(data);
    }
  }, [data]);

  const colors = useThemeCustomProperties(['color-primary']);

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  if (error) {
    throw error;
  }

  return (
    <Flex direction="column" gap="space-m">
      <Flex gap="space-xs" alignItems={'center'}>
        <h4>PnL Estimator</h4>
        {open ? (
          <Flex gap="space-m" alignItems={'center'}>
            <CurrencyFilter
              key="currency-filter"
              width="8rem"
              height="1.65rem"
              currency2={{ value: 'token', displayValue: 'Token' }}
              currency1={{ value: 'USD', displayValue: 'USD' }}
              {...filters}
            />
            <ChevronUpIcon
              cursor={'pointer'}
              onClick={() => toggle(false)}
              size={18}
              color="var(--color-primary)"
            />
          </Flex>
        ) : (
          <ChevronDownIcon
            cursor={'pointer'}
            onClick={() => toggle(true)}
            size={18}
            color="var(--color-onSurface400)"
          />
        )}
      </Flex>

      {open ? (
        <Fragment>
          <CurrencySwitchLineChart
            key="pnl-estimator"
            data={cachedData ? (cachedData['results'] as LineChartData[]) : []}
            fields={[
              {
                field: `pnl`,
                label: 'PnL',
                backgroundColor: colors.colorPrimary
              }
            ]}
            currency={currency}
            switchValue={filters.filtersApplied.currency}
            aspectRatio={6}
            options={{
              dynamic: true,
              fill: false
            }}
          />

          <LpPositionPnlEstimatorFormTabs
            loading={data === undefined}
            onSubmit={updateSearchParams}
            initialValues={{
              daysToClose,
              rateAtClose,
              avgVarRate,
              dailyVolume,
              idleLiquidityPercentage,
              avgLpApy,
              dailyVolumeOptions: cachedData
                ? cachedData['dropdown_data']['volume']
                : {},
              idleLiquidityOptions: cachedData
                ? cachedData['dropdown_data']['idle_liquidity']
                : {},
              currentRates: cachedData ? cachedData['stats'] : {}
            }}
          />
          <LegalDisclaimer />
        </Fragment>
      ) : null}
    </Flex>
  );
}
