import { useSearchParams } from '@/hooks';
import classnames from 'classnames';
import { useCallback } from 'react';
import styles from './GlobalTimeFilter.module.scss';

// eslint-disable-next-line react-refresh/only-export-components
export enum TimeOptions {
  '24H' = '1',
  '7D' = '7',
  '30D' = '30',
  '90D' = '90',
  '1Y' = '365'
}

type GlobalTimeFilterProps = {
  defaultValue?: TimeOptions;
};

export default function GlobalTimeFilter({
  defaultValue = TimeOptions['7D']
}: GlobalTimeFilterProps) {
  const [dataTimeFilter, setSearchParams] = useSearchParams(
    'days_ago',
    defaultValue
  );

  const onChangeDataFilter = useCallback(
    (key: keyof typeof TimeOptions) => {
      setSearchParams(TimeOptions[key]);
    },
    [setSearchParams]
  );

  return Object.keys(TimeOptions).map((key) => (
    <div
      role="button"
      aria-label="Data time filter button"
      key={key}
      className={classnames(styles.wrapper, {
        [styles.active]:
          dataTimeFilter === TimeOptions[key as keyof typeof TimeOptions]
      })}
      onClick={() => onChangeDataFilter(key as keyof typeof TimeOptions)}>
      <p
        className={classnames('text-2', {
          regular:
            dataTimeFilter !== TimeOptions[key as keyof typeof TimeOptions]
        })}>
        {key}
      </p>
    </div>
  ));
}
