import { useApi } from '@/api';
import { Page } from '@/components';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import { SearchInput } from '@/components/forms';
import { useSearchParams } from '@/hooks';
import { generateMarketName } from '@/utils/marketNameUtil';
import {
  Box,
  CryptoIcon,
  CurrencyFilter,
  Flex,
  useTableProps
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import FilteredLPTable from './FilteredLPTable';
import FilteredPositionsTable from './FilteredPositionList';
const displayOptions = [
  { key: 'long', value: 'Long' },
  { key: 'short', value: 'Short' },
  { key: 'lp', value: 'LP' }
];

export default function FilteredPositionsPage() {
  const [searchText, setSearchText] = useSearchParams('search', '');
  const [daysAgo] = useSearchParams('days_ago', '7');
  const { marketId } = useParams();
  const [data, error] = useApi({
    url: `/markets/${marketId}/`
  });

  if (error) {
    throw error;
  }
  const { filters, sorting, pagination } = useTableProps({
    initialFilters: { state: 'open', currency: 'token' },
    filtersDebounceTime: 50
  });
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'long'
  );

  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const tabsContent = useMemo(
    () => ({
      long: (
        <FilteredPositionsTable
          daysAgo={daysAgo}
          searchText={searchText}
          market={marketId}
          type={activeDisplayOption}
          sorting={sorting}
          pagination={pagination}
          filters={filters}
        />
      ),
      short: (
        <FilteredPositionsTable
          daysAgo={daysAgo}
          searchText={searchText}
          market={marketId}
          type={activeDisplayOption}
          sorting={sorting}
          pagination={pagination}
          filters={filters}
        />
      ),
      lp: (
        <FilteredLPTable
          daysAgo={daysAgo}
          searchText={searchText}
          market={marketId}
          sorting={sorting}
          pagination={pagination}
          filters={filters}
        />
      )
    }),
    [
      activeDisplayOption,
      daysAgo,
      marketId,
      filters,
      searchText,
      sorting,
      pagination
    ]
  );

  if (data) {
    const titleText = generateMarketName(
      data.asset_symbol,
      data.yield_name,
      data.maturity_time
    );
    const title = (
      <>
        <Flex gap="space-3xs" alignItems={'center'}>
          <DoubleSymbol
            symbol1={
              <CryptoIcon name={data.asset_symbol} size={40} extension="png" />
            }
            symbol2={
              <CryptoIcon
                name={data.yield_source ? data.yield_source : ''}
                size={40}
                extension="png"
              />
            }
          />
          <h2>
            {titleText}{' '}
            {activeDisplayOption.charAt(0).toUpperCase() +
              activeDisplayOption.slice(1)}
          </h2>
        </Flex>
      </>
    );
    return (
      <Page title={title} filters={filters}>
        <Flex
          gap="space-xs"
          direction={['row', 'row', 'column']}
          justifyContent={['space-between', 'space-between', 'space-between']}>
          <Flex
            direction={['row', 'row', 'column']}
            alignItems={['flex-start', 'flex-start', 'flex-start']}
            justifyContent="space-between"
            alignSelf={['center', 'center', 'flex-start']}
            gap="space-xs">
            <DisplaySwitch
              options={displayOptions}
              activeOption={activeDisplayOption}
              onChange={handleTabClick}
            />
          </Flex>
          {activeDisplayOption != 'lp' && (
            <CurrencyFilter
              key="open-closed"
              width="8rem"
              height="auto"
              currency1={{ value: 'open', displayValue: 'OPEN' }}
              currency2={{ value: 'closed', displayValue: 'CLOSED' }}
              filterField="state"
              {...filters}
            />
          )}
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="auto"
            currency1={{ value: 'token', displayValue: 'TOKEN' }}
            currency2={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
          <Box>
            <SearchInput
              style={{ minHeight: '1.6rem' }}
              placeholder="Search by wallet address"
              defaultValue={searchText}
              onValueChange={(value) => setSearchText(value)}
            />
          </Box>
        </Flex>
        {tabsContent[activeDisplayOption]}
      </Page>
    );
  } else {
    return null;
  }
}
