import { useApi } from '@/api';
import { Divider, Page } from '@/components';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';
import { useSearchParams } from '@/hooks';
import {
  Card,
  Flex,
  WalletAddress,
  WalletIcon,
  CurrencyValue
} from '@blockanalitica/ui';
import { default as classnames } from 'classnames';
import { useParams } from 'react-router-dom';
import WalletEvents from './WalletEvents';
import WalletPositions from './WalletPositions';
import { useLocation } from 'react-router-dom';
import { smartLocationParts } from '../../../utils/url.js';

export default function WalletPage() {
  const location = useLocation();
  const network = smartLocationParts(location);
  const [daysAgo] = useSearchParams('days_ago', '7');
  const { walletAddress } = useParams();
  const [data, error] = useApi({
    url: `/wallets/${walletAddress}`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <Flex gap="space-s" alignItems="center">
            <WalletIcon />
            <h1>Wallet</h1>
          </Flex>
        }>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="TVL"
              data={data}
              field="tvl"
              currency="USD"
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large',
                notation: 'compact'
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="PnL"
              data={data}
              field="pnl"
              currency="USD"
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large'
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Card style={{ width: '100%' }}>
              <Flex gap="space-xs" direction="column" alignItems="flex-start">
                <Flex>
                  <span className={classnames('text-2', 'text-muted')}>
                    Wallet
                  </span>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <WalletAddress
                    address={data.wallet_address}
                    options={{
                      size: 'small',
                      showEthScanLink: true,
                      showDebankLink: true,
                      showZapperLink: true,
                      showCopyBtn: true,
                      network: network
                    }}
                  />
                </Flex>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="Total Long"
              data={data}
              field="total_bond_amount_balance_long"
              currency="USD"
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large'
              }}
              extra_footer={
                <Flex
                  justifyContent="space-between"
                  className="text-muted text-2"
                  width="100%">
                  <Flex gap="space-3xs">
                    <span>Total Long Value:</span>
                    <CurrencyValue
                      value={Number(data.total_long)}
                      options={{
                        currency: 'USD',
                        notation: 'compact',
                        dynamic: false,
                        tooltip: true,
                        leadingCurrencySymbol: true,
                        FormattedCurrencyOptions: {
                          maximumFractionDigits: 2
                        }
                      }}
                    />
                  </Flex>
                </Flex>
              }
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="Total Short"
              data={data}
              field="total_bond_amount_balance_short"
              currency="USD"
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large'
              }}
              extra_footer={
                <Flex
                  justifyContent="space-between"
                  className="text-muted text-2"
                  width="100%">
                  <Flex gap="space-3xs">
                    <span>Total Short Value:</span>
                    <CurrencyValue
                      value={Number(data.total_short)}
                      options={{
                        currency: 'USD',
                        notation: 'compact',
                        dynamic: false,
                        tooltip: true,
                        leadingCurrencySymbol: true,
                        FormattedCurrencyOptions: {
                          maximumFractionDigits: 2
                        }
                      }}
                    />
                  </Flex>
                </Flex>
              }
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="Total LP"
              data={data}
              field="total_lp"
              currency="USD"
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large',
                notation: 'compact'
              }}
            />
          </Flex>
        </Flex>
        <Divider />
        <WalletPositions />
        <Divider />
        <WalletEvents />
      </Page>
    );
  }

  return null;
}
