import { useApi } from '@/api';
import { Link } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import Table from '@/components/table/Table';
import {
  CryptoIcon,
  CurrencyValue,
  Flex,
  PercentageValue,
  TableLoader,
  useTableProps
} from '@blockanalitica/ui';

export default function MarketsTable({ daysAgo }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 10,
    initialFilters: { currency: 'USD' }
  });
  const [data, error] = useApi({
    url: '/fixed-rate-borrow/markets/',
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      days_ago: daysAgo,
      order: sorting.currentSorting
    }
  });
  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'PROTOCOL',
        getCell: () => (
          <Flex gap="space-3xs" alignItems={'center'}>
            <CryptoIcon name={'MORPHO'} size={30} />
            {'Morpho'}
          </Flex>
        )
      },
      {
        header: 'MARKET',
        getCell: (row) => (
          <Flex gap="space-3xs" alignItems={'center'}>
            <DoubleSymbol
              symbol1={
                <CryptoIcon name={row.collateral_token_symbol} size={30} />
              }
              symbol2={<CryptoIcon name={row.loan_token_symbol} size={30} />}
            />
            <Flex direction="column">
              <Flex gap="space-3xs">
                {row.collateral_token_symbol}/{row.loan_token_symbol}
              </Flex>
              <Flex gap="space-3xs" fontSize="size--2">
                LLTV: <PercentageValue value={row.lltv} />
              </Flex>
            </Flex>
          </Flex>
        )
      },

      {
        header: 'COVERAGE CAPACITY',
        getCell: (row) => (
          <CurrencyValue
            value={row.coverage_capacity}
            options={{
              dynamic: false
            }}
          />
        ),

        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'coverage_capacity'
      },

      {
        header: 'BORROW RATE',
        getCell: (row) => (
          <PercentageValue
            value={row.borrow_rate_apy}
            options={{ dashIfZero: true, tooltip: true }}
          />
        ),

        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'borrow_rate_apy'
      },
      {
        header: 'MAX FIXED BORROW RATE',
        getCell: (row) => (
          <PercentageValue
            value={row.max_fixed_borrow_rate}
            options={{ dashIfZero: true, tooltip: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'max_fixed_borrow_rate'
      }
    ];

    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.uid}
        getLinkWrapper={(row) => (
          <Link daysAgo={true} to={`/fixed-borrow/markets/${row.uid}`} />
        )}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return <TableLoader />;
}
