import { useApi } from '@/api';
import { EventFormatter } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import EventName from '@/components/events/EventName';
import SelectFilter from '@/components/filters/SelectFilter';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import { ALL_EVENTS, EVENT_TYPE_MAPPING } from '@/constants';
import {
  CryptoIcon,
  EtherScanPictogram,
  Flex,
  RelativeDateTime,
  useTableProps
} from '@blockanalitica/ui';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { smartEtherscanUrl } from '../../../utils/url.js';

export default function WalletEvents() {
  const location = useLocation();
  const etherscanUrl = smartEtherscanUrl(location);

  const selectFilterOptions = useMemo(() => {
    let options = [{ label: 'All events', value: '' }];
    options.push(
      ...ALL_EVENTS.map((event) => ({
        label: EVENT_TYPE_MAPPING[event],
        value: event
      }))
    );
    return options;
  }, []);

  const { sorting, filters, pagination } = useTableProps({
    pageSize: 25,
    filtersDebounceTime: 50
  });

  const { walletAddress } = useParams();
  const [data, error] = useApi({
    url: `/wallets/${walletAddress}/events`,
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      event: filters.filtersApplied.eventType,
      order: sorting.currentSorting
    }
  });

  const content = useMemo(() => {
    if (data) {
      const columns = [
        {
          header: 'MARKET',
          getCell: (row) => (
            <Flex gap="space-3xs" alignItems={'center'}>
              <DoubleSymbol
                symbol1={<CryptoIcon name={row.asset_symbol} extension="png" />}
                symbol2={
                  <CryptoIcon
                    name={row.yield_source ? row.yield_source : ''}
                    extension="png"
                  />
                }
              />
              {row.name}
            </Flex>
          )
        },
        {
          header: 'EVENT',
          getCell: (row) => <EventName event={row.event} />
        },
        {
          header: 'DETAILS',
          getCell: (row) => <EventFormatter type={row.event} data={row} />,
          cellSize: '2.5fr'
        },
        {
          header: 'TIME',
          getCell: (row) => <RelativeDateTime value={row.datetime} />,
          getCellBottom: (row) => (
            <Flex gap="space-3xs" className="text-muted">
              {row.block_number}
              <a
                href={`${etherscanUrl}tx/${row.tx_hash}`}
                target="_blank"
                rel="noreferrer">
                <EtherScanPictogram size={14} />
              </a>
            </Flex>
          ),
          cellAlign: 'flex-end',
          headerAlign: 'flex-end',
          sortingField: 'order_index'
        }
      ];
      return (
        <Table
          rows={data['results']}
          columns={columns}
          getKey={(row) => `${row.tx_hash}`}
          sorting={sorting}
          pagination={{
            ...pagination,
            totalRecords: data.count
          }}
          getLinkWrapper={undefined}
        />
      );
    }
    return <TableLoader />;
  }, [data, sorting, pagination, etherscanUrl]);

  if (error) {
    throw error;
  }

  return (
    <Flex direction={['column']} gap={'space-xs'}>
      <Flex justifyContent="space-between" alignItems="center">
        <h4>Events</h4>
        <SelectFilter
          key="event-filter"
          placeholder="Showing all events"
          filterField="eventType"
          options={selectFilterOptions}
          onPageChange={pagination.onPageChange}
          {...filters}
        />
      </Flex>
      {content}
    </Flex>
  );
}
