import { Chart, ChartConfiguration, ChartDataset, ChartType } from 'chart.js';
import {
  useThemeCustomProperties,
  ChartJS,
  Flex,
  CurrencyValue
} from '@blockanalitica/ui';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { DeepPartial } from 'utility-types';
import ChartKpis from './ChartKpis/ChartKpis';
import ReactDOMServer from 'react-dom/server';
type DefaultData = ChartPoint & { disabled?: boolean };

export type KpisConfig = {
  defaultData: DefaultData[];
};

type LineChartData = {
  labels?: string[];
  datasets: ChartDataset<'line', ChartPoint[]>[];
};

interface ChartPoint {
  x?: number | string;
  y: number;
  label: string;
}

type LineChartProps = {
  data: LineChartData;
  kpisConfig: KpisConfig;
  config?: DeepPartial<
    ChartConfiguration<ChartType, number[] | ChartPoint[], string>
  >;
};

export default function BubbleChart({
  data,
  kpisConfig,
  config
}: LineChartProps) {
  const colors = useThemeCustomProperties(['color-text', 'color-primary']);
  const [defaultKpisData, setDefaultKpisData] = useState(
    kpisConfig.defaultData
  );

  const [chartInstance, setChartInstance] = useState<Chart | null>(null);

  const renderCustomTooltip = (context) => {
    let tooltipEl = document.getElementById('chartjs-tooltip');
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = 'chartjs-tooltip';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.transition = 'opacity 0.2s';
      document.body.appendChild(tooltipEl);
    }

    if (context.tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    const tooltipModel = context.tooltip;
    if (tooltipModel.dataPoints && tooltipModel.dataPoints.length > 0) {
      const datasetIndex = tooltipModel.dataPoints[0].datasetIndex;
      const dataIndex = tooltipModel.dataPoints[0].dataIndex;
      const dataItem =
        context.chart.data.datasets[datasetIndex].data[dataIndex];

      // Collect and format tooltip data dynamically based on the 'tooltipXLabel' and 'tooltipX' pairs
      const tooltipContent = dataItem.tooltips.map((tooltip) => {
        const valueComponent = (
          <CurrencyValue
            value={tooltip.value}
            options={{ currency: tooltip.currency }}
          />
        );
        const body = (
          <Flex
            direction="row"
            alignItems="flex-start"
            gap="space-3xs"
            className="text-2">
            <span>{`${tooltip.key}:`}</span>
            <span>{valueComponent}</span>
          </Flex>
        );
        // Convert the entire JSX containing the CurrencyValue component to a string
        return ReactDOMServer.renderToString(body);
      });
      const content = ReactDOMServer.renderToString(
        <Flex
          direction="column"
          gap="space-3xs"
          style={{
            padding: '10px',
            background: 'rgba(0, 0, 0, 0.7)',
            borderRadius: '4px',
            color: 'white'
          }}>
          <div style={{ fontWeight: 'bold' }} className="text-2">
            {dataItem.tooltipHeader}
          </div>
          {tooltipContent.map((line, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: line }} />
          ))}
        </Flex>
      );

      tooltipEl.innerHTML = content;

      // Position the tooltip
      const canvasRect = context.chart.canvas.getBoundingClientRect();
      tooltipEl.style.opacity = '1';
      tooltipEl.style.left =
        canvasRect.left + window.pageXOffset + tooltipModel.caretX + 'px';
      tooltipEl.style.top =
        canvasRect.top + window.pageYOffset + tooltipModel.caretY - 10 + 'px'; // Moves tooltip 10px higher

      tooltipEl.style.transform = 'translate(-50%, -100%)';
    } else {
      tooltipEl.style.opacity = '0';
    }
  };

  // Usage: You need to pass this function to the 'external' property of the tooltip configuration in your Chart.js setup.

  const toggleDataset = useCallback(
    (dataSerieIndexToToggle: number) => {
      if (chartInstance) {
        const isHidden =
          !chartInstance.data.datasets[dataSerieIndexToToggle].hidden;
        chartInstance.data.datasets[dataSerieIndexToToggle].hidden = isHidden;
        chartInstance.update();

        setDefaultKpisData((prevKpis) =>
          prevKpis.map((kpi, index) => {
            if (index === dataSerieIndexToToggle) {
              return { ...kpi, disabled: isHidden };
            }
            return kpi;
          })
        );
      }
    },
    [chartInstance]
  );

  const kpisData = useMemo(() => {
    // Merge the dataset details with the disabled state from defaultKpisData
    return data.datasets.map((ds) => {
      const defaultKpi =
        defaultKpisData.find((kpi) => kpi.label === ds.label) || {};
      return {
        label: ds.label,
        color: ds.backgroundColor,
        disabled: defaultKpi.disabled || false // Ensuring disabled defaults to false if not set
      };
    });
  }, [data, defaultKpisData]); // Include defaultKpisData in the dependency array

  //@ts-ignore
  const mergedConfig: DeepPartial<
    ChartConfiguration<ChartType, number[], string>
  > = useMemo(() => {
    const defaultConfig = {
      type: 'bubble',
      data: data,
      options: {
        aspectRatio: 3.4,
        cubicInterpolationMode: 'monotone',
        responsive: true,
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            align: 'center',
            labels: {
              usePointStyle: true,
              pointStyle: 'circle',
              color: colors.colorText
            }
          },
          tooltip: {
            enabled: false,
            external: renderCustomTooltip
          }
        },
        scales: {
          x: {
            grid: {
              display: false
            }
          },
          y: {
            grid: {
              display: false
            }
          }
        },
        elements: {
          point: {
            radius: 0
          }
        }
      }
    };

    return _.merge(defaultConfig, config);
  }, [data, colors.colorText, config]);

  return (
    <Flex direction="column" gap="space-xs">
      <Flex
        gap="space-xs"
        alignItems="flex-start"
        justifyContent="space-between">
        <Flex direction="column" gap="space-3xs" width="100%">
          <Flex
            direction="row"
            alignItems="flex-start"
            justifyContent="space-between">
            <ChartKpis kpisData={kpisData} onClick={toggleDataset} />
          </Flex>
        </Flex>
      </Flex>

      <ChartJS config={mergedConfig} chartInstanceSeter={setChartInstance} />
    </Flex>
  );
}
