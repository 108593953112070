import { Link } from '@/components';
import { transformString } from '@/utils';
import { ArrowRightIcon, Flex, textShortener } from '@blockanalitica/ui';
import { ReactNode, useMemo } from 'react';

type TCrumb = {
  href: string;
  text: ReactNode;
  force?: boolean;
};

type BreadcrumbsProps = {
  crumbs: TCrumb[];
};

export default function Breadcrumbs({ crumbs }: BreadcrumbsProps) {
  return (
    <Flex gap="space-2xs" display="inline-flex" alignItems="center">
      {crumbs.map((crumb, index) => (
        <Crumb
          key={crumb.href}
          last={crumbs.length === 1 || index === crumbs.length - 1}
          force={crumb.force}
          {...crumb}
        />
      ))}
    </Flex>
  );
}

type TCrumbProps = TCrumb & {
  last: boolean;
};

function Crumb({ href, text, last, force }: TCrumbProps) {
  const crumbChildren = useMemo(() => {
    if (typeof text === 'string') {
      if (text.startsWith('0x')) {
        return textShortener(text, {
          threshold: 11,
          firstSliceLength: 6,
          secondSliceLength: 4
        });
      }

      if (text.toLowerCase() === 'lp') {
        return 'LP';
      }

      return textShortener(transformString(text), {
        threshold: 63,
        firstSliceLength: 4,
        secondSliceLength: 4
      });
    }

    return text;
  }, [text]);

  if (last) {
    return <p className="text-2">{crumbChildren}</p>;
  }

  return (
    <Flex display="inline-flex" gap="space-2xs" alignItems="center">
      <Link daysAgo={true} to={href} force={force} className="text-2">
        {crumbChildren}
      </Link>
      <ArrowRightIcon size={12} />
    </Flex>
  );
}
