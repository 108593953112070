import { formatPercentage } from '@blockanalitica/ui';
import styles from './PercentageBar.module.scss';

type PercentageBarProps = {
  value: number;
};

const PercentageBar = ({ value }: PercentageBarProps) => {
  return (
    <div className={styles.container}>
      {value === null ? (
        <div className={styles.gray} style={{ width: '100%' }}></div>
      ) : (
        <>
          {value > 0 && (
            <div
              className={styles.green}
              style={{ width: formatPercentage(value) }}></div>
          )}
          {value < 100 && (
            <div
              className={styles.red}
              style={{ width: formatPercentage(1 - value) }}></div>
          )}
        </>
      )}
    </div>
  );
};

export default PercentageBar;
