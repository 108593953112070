import apiFetcher, { apiFetcherArgs } from '@/api/apiFetcher';
import { NETWORKS } from '@/constants';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

export default function useApi<T>(
  args: apiFetcherArgs
): [T | undefined, Error | undefined] {
  const { network } = useParams();

  if (NETWORKS.includes(network)) {
    args.queryParams = { ...args.queryParams, network };
  }
  const { data, error } = useSWR<T, Error, apiFetcherArgs>(args, apiFetcher);

  return [data, error];
}
