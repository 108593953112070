import { DEFAULT_NETWORK, NETWORKS } from '@/constants';
import { useLocation, Navigate, useParams } from 'react-router-dom';
import { FixedSidebarLayout as Layout } from '@/components';

export default function ValidateNetwork() {
  const location = useLocation();
  const { network } = useParams();

  /* 
    If network is invalid, the assumption is network is missing so we add default network to path.
    Otherwise, if network or path is invalid it will throw a 404.
  */
  if (!NETWORKS.includes(network)) {
    const newPath = `/${DEFAULT_NETWORK}${location.pathname}`;

    return <Navigate to={newPath} replace />;
  }

  return <Layout />;
}
