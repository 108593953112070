import { useApi } from '@/api';
import { CardList, Link } from '@/components';
import {
  CardLoader,
  CurrencyValue,
  EmptyBox,
  Flex,
  List,
  PercentageValue,
  WalletAddress
} from '@blockanalitica/ui';

export default function PositionLPList({ marketId }) {
  const [data, error] = useApi({
    url: `/markets/${marketId}/positions/`,
    queryParams: {
      type: 'lp',
      p_size: '4',
      order: '-base_amount_balance'
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    let content = undefined;
    if (data['results'].length > 0) {
      content = (
        <List>
          {data['results'].map((item) => (
            <Link
              daysAgo={true}
              key={item.wallet_address}
              to={`/markets/${marketId}/positions/${item.wallet_address}/lp`}>
              <Flex justifyContent="space-between">
                <Flex
                  justifyContent="space-between"
                  style={{ flexBasis: '66%' }}>
                  <WalletAddress
                    address={item.wallet_address}
                    options={{ size: 'xSmall' }}
                  />
                  <Flex
                    direction="column"
                    gap="space-3xs"
                    alignItems="flex-end">
                    <PercentageValue value={item.share} />
                    <span className="text-2 text-muted">share</span>
                  </Flex>
                </Flex>
                <Flex direction="column" gap="space-3xs" alignItems="flex-end">
                  <CurrencyValue
                    value={item.balance}
                    options={{
                      currency: item.asset_symbol,
                      iconOpacity: 0.7,
                      leadingCurrencySymbol: false,
                      iconSize: 'xSmall'
                    }}
                  />
                  <span className="text-2 text-muted">
                    <CurrencyValue
                      value={item.balance_usd}
                      options={{ currency: 'USD' }}
                    />
                  </span>
                </Flex>
              </Flex>
            </Link>
          ))}
        </List>
      );
    } else {
      content = <EmptyBox />;
    }

    return (
      <Flex direction="column" gap="space-xs" width={'100%'}>
        <Flex justifyContent="flex-start" gap="space-xs" alignItems="center">
          <h4>Top LPs</h4>
        </Flex>
        <CardList>{content}</CardList>
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
