import { Flex } from '@blockanalitica/ui';
import { ReactNode } from 'react';
import styles from './DoubleSymbol.module.scss';

export type DoubleSymbolProps = {
  symbol1: ReactNode;
  symbol2?: ReactNode;
  children?: ReactNode;
};

export default function DoubleSymbol({
  symbol1,
  symbol2,
  children,
  ...rest
}: DoubleSymbolProps) {
  return (
    <Flex alignItems="center" gap="space-2xs" {...rest}>
      <div className={styles.logos}>
        <div className={styles.firstSymbol}>{symbol1}</div>
        <div className={styles.secondSymbol}>{symbol2}</div>
      </div>
      {children}
    </Flex>
  );
}
