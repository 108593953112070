import {
  Box,
  CryptoIcon,
  CurrencyValue,
  Flex,
  InfoTooltip,
  Kpi,
  KpiBody,
  KpiFooter,
  KpiTitle,
  PercentageValue,
  Tooltip,
  TooltipContent,
  TooltipTrigger
} from '@blockanalitica/ui';

import { default as classnames } from 'classnames';
import { ReactNode, useMemo } from 'react';
import { KpiCardProps } from './KpiCardProps';

type KpiCurrencyCardProps = Partial<KpiCardProps> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  field: string;
  currency: string;
  trend?: boolean;
  extra_footer?: ReactNode;
  kpiIcon?: ReactNode;
  tooltipText?: string;
  optionalContentTop?: ReactNode;
  optionalContentBottom?: ReactNode;
};

function AddInfoTooltip({ tooltipText }) {
  if (tooltipText) {
    return <InfoTooltip text={tooltipText} />;
  } else {
    return null;
  }
}

export default function KpiCurrencyCard({
  title,
  data,
  field,
  currency,
  trend = true,
  extra_footer = null,
  kpiIcon = null,
  tooltipText = null,
  optionalContentTop = undefined,
  optionalContentBottom = undefined,
  options,
  ...rest
}: KpiCurrencyCardProps) {
  let trendContent = null;
  let footerTrendContent = null;

  const fieldBase = field;
  const trendBase = `${fieldBase}_change`;
  const trendPercBase = `${trendBase}_percentage`;

  const fieldBaseUsd = `${fieldBase}_usd`;
  const trendBaseUsd = `${fieldBaseUsd}_change`;
  const trendPercBaseUsd = `${trendBaseUsd}_percentage`;

  function getFooterContent() {
    if (!footerTrendContent && extra_footer) {
      return extra_footer;
    }
    return footerTrendContent;
  }

  if (trend && Math.abs(Number(data[trendPercBase])) > 0) {
    trendContent = (
      <div className={classnames(options?.trendSize ?? 'text-3', 'text-mono')}>
        <Flex>
          <Tooltip placement="right">
            <TooltipTrigger>
              <PercentageValue
                value={Number(data[trendPercBase])}
                options={{
                  trend: true,
                  tooltip: false,
                  formatPercentageOptions: {
                    maximumFractionDigits: options?.fractionDigits ?? 2,
                    notation: 'compact'
                  }
                }}
              />
            </TooltipTrigger>
            <TooltipContent>
              <CurrencyValue
                value={Number(data[trendBase])}
                options={{
                  currency: currency,
                  iconSize: 10,
                  dynamic: false,
                  notation: 'standard',
                  tooltip: false,
                  leadingCurrencySymbol: true,
                  FormattedCurrencyOptions: {
                    maximumFractionDigits: options?.fractionDigits ?? 2
                  }
                }}
              />
            </TooltipContent>
          </Tooltip>
        </Flex>
      </div>
    );
  }

  if (trend && Math.abs(Number(data[trendPercBaseUsd])) > 0) {
    footerTrendContent = (
      <div className={classnames('text-2', 'text-mono')}>
        <Flex>
          <Tooltip placement="right">
            <TooltipTrigger>
              <PercentageValue
                value={Number(data[trendPercBaseUsd])}
                options={{
                  trend: true,
                  tooltip: false,
                  formatPercentageOptions: {
                    maximumFractionDigits: options?.fractionDigits ?? 2,
                    notation: 'compact'
                  }
                }}
              />
            </TooltipTrigger>
            <TooltipContent>
              <CurrencyValue
                value={Number(data[trendBaseUsd])}
                options={{
                  currency: 'USD',
                  dynamic: false,
                  notation: 'standard',
                  leadingCurrencySymbol: true,
                  FormattedCurrencyOptions: {
                    maximumFractionDigits: options?.fractionDigits ?? 2
                  }
                }}
              />
            </TooltipContent>
          </Tooltip>
        </Flex>
      </div>
    );
  }

  const tooltip = AddInfoTooltip({ tooltipText });

  const kpiBodyContent = useMemo(() => {
    const value = Number(data[fieldBase]);

    if (currency === 'USD') {
      if (value !== 0 && Math.abs(value) < 0.01) {
        return value < 0 ? '>$-0.01' : '<$0.01';
      }
    } else {
      if (value !== 0 && Math.abs(value) < 0.00001) {
        const val = value < 0 ? '>-0.00001' : '<0.00001';
        return (
          <>
            <Flex direction={'row'} gap={'space-3xs'}>
              <CryptoIcon
                size={options.iconSize}
                name={currency}
                extension="png"
              />
              {val}
            </Flex>
          </>
        );
      }
    }

    return (
      <CurrencyValue
        value={value}
        options={{
          currency: currency,
          iconSize: options.iconSize,
          notation: options?.notation ?? 'compact',
          dynamic: options?.dynamic ?? true,
          tooltip: true,
          leadingCurrencySymbol: true,
          FormattedCurrencyOptions: {
            maximumFractionDigits:
              Math.abs(value) < 1
                ? options?.fractionDigits ?? 4
                : options?.fractionDigits ?? 2
          }
        }}
      />
    );
  }, [data, fieldBase, currency, options]);

  const KpiFooterContent =
    Number(data[fieldBaseUsd]) !== 0 &&
    Math.abs(Number(data[fieldBaseUsd])) < 0.01 ? (
      Number(data[fieldBaseUsd]) < 0 ? (
        '>$-0.01'
      ) : (
        '<$0.01'
      )
    ) : (
      <CurrencyValue
        value={Number(data[fieldBaseUsd])}
        options={{
          currency: 'USD',
          iconSize: options.iconSize,
          notation: options?.notation ?? 'compact',
          dynamic: options?.dynamic ?? false,
          tooltip: true,
          leadingCurrencySymbol: true,
          FormattedCurrencyOptions: {
            maximumFractionDigits:
              Math.abs(Number(data[fieldBaseUsd])) < 1
                ? options?.fractionDigits ?? 4
                : options?.fractionDigits ?? 2
          }
        }}
      />
    );

  return (
    <Kpi {...rest} border="none">
      <Flex direction={'column'} gap={'space-2xs'}>
        <Flex direction={'row'} justifyContent={'space-between'}>
          <KpiTitle className="text-muted text-2">
            <Box>
              {title}
              {tooltip}
            </Box>
          </KpiTitle>
          {optionalContentTop && optionalContentTop}
        </Flex>
        <KpiBody
          gap="space-xs"
          alignItems="baseline"
          justifyContent="flex-start">
          <div
            className={classnames(options?.valueSize ?? 'text-4', 'text-mono')}>
            {kpiBodyContent}
          </div>
          {trendContent}
        </KpiBody>
        <Flex
          direction={'row'}
          justifyContent={'space-between'}
          className={classnames('text-2', 'text-muted')}>
          <KpiFooter
            gap={!footerTrendContent && extra_footer ? undefined : 'space-3xs'}
            alignItems="baseline">
            <Flex className={classnames(options?.footerSize ?? 'text-2')}>
              {KpiFooterContent}
            </Flex>
            <Flex justifyContent="space-between" gap="space-3xs" width={'100%'}>
              <span>{getFooterContent()}</span>
              <Flex justifyContent="space-between" gap="space-3xs">
                {footerTrendContent && extra_footer}
              </Flex>
            </Flex>
          </KpiFooter>
          {optionalContentBottom && optionalContentBottom}
        </Flex>

        {kpiIcon && <Flex style={{ opacity: 0.2 }}>{kpiIcon}</Flex>}
      </Flex>
    </Kpi>
  );
}
