import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const StyledInput = styled.input<InputProps>`
  opacity: 0.7;
  background-color: var(--color-background);
  color: var(--color-muted);
  width: 100%;
  max-width: 250px;
  border-radius: 5px;
  outline: none;
  transition: opacity 0.2s;
  position: relative;
  border: 1px solid var(--color-surface);
  padding: var(--space-3xs);

  &:hover {
    opacity: 1;
  }

  &:focus {
    opacity: 1;
    border-color: var(--color-primary);
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;

    &::-webkit-slider-thumb {
      cursor: not-allowed;
    }

    &::-moz-range-thumb {
      cursor: not-allowed;
    }
  }

  &:invalid {
    border-color: red;
  }
`;

interface InputProps extends Omit<InputHTMLAttributes<'text'>, 'onChange'> {
  value: number | string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
  step?: string;
  isValid?: boolean;
}

export default function Input({
  value,
  onChange,
  min = 0,
  step,
  ...rest
}: InputProps) {
  return (
    <StyledInput
      type="text"
      min={min}
      step={step}
      value={Number(value)}
      onChange={onChange}
      {...rest}
    />
  );
}
