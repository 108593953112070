import { useApi } from '@/api';
import { Divider, Page } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';
import { useSearchParams } from '@/hooks';
import { generateMarketName } from '@/utils/marketNameUtil';
import { Card, CryptoIcon, Flex, WalletAddress } from '@blockanalitica/ui';
import { default as classnames } from 'classnames';
import { useLocation, useParams } from 'react-router-dom';
import { smartLocationParts } from '../../../../../utils/url.js';
import LpPositionPnlEstimator from './components/LpPositionPnlEstimator/LpPositionPnlEstimator';
import MarketLpPositionEventsTable from './components/MarketLpPositionEventsTable';

export default function MarketPositionsWalletAddressLPPage() {
  const location = useLocation();
  const network = smartLocationParts(location);
  const [daysAgo] = useSearchParams('days_ago', '7');
  const { marketId, walletAddress } = useParams();
  const [data, error] = useApi({
    url: `/markets/${marketId}/positions/${walletAddress}/lp`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const titleText = generateMarketName(
      data.asset_symbol,
      data.yield_name,
      data.maturity_time
    );
    return (
      <Page
        title={
          <Flex direction="row" gap="space-3xs">
            <DoubleSymbol
              symbol1={
                <CryptoIcon
                  name={data.asset_symbol}
                  size={40}
                  extension="png"
                />
              }
              symbol2={
                <CryptoIcon
                  name={data.yield_source ? data.yield_source : ''}
                  size={40}
                  extension="png"
                />
              }
            />
            <h2>{titleText}</h2>
          </Flex>
        }>
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="LP Value"
              data={data}
              field="balance"
              currency={data.asset_symbol}
              options={{
                valueSize: 'text-5',
                dynamic: true,
                iconSize: 'large'
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <KpiCurrencyCard
              title="LP Token Balance"
              data={data}
              field="lp_balance"
              currency=""
              options={{
                valueSize: 'text-5',
                trendSize: 'text-3',
                footerSize: 'text-2',
                dynamic: true
              }}
            />
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Card style={{ width: '100%' }}>
              <Flex gap="space-xs" direction="column" alignItems="flex-start">
                <Flex>
                  <span className={classnames('text-2', 'text-muted')}>
                    Wallet
                  </span>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <WalletAddress
                    address={data.wallet_address}
                    options={{
                      size: 'small',
                      showEthScanLink: true,
                      showDebankLink: true,
                      showZapperLink: true,
                      showCopyBtn: true,
                      network: network
                    }}
                  />
                </Flex>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        <Divider />

        <LpPositionPnlEstimator currency={data.asset_symbol} />

        <Divider />
        <MarketLpPositionEventsTable />
      </Page>
    );
  }

  return null;
}
