import { Page } from '@/components';
import { useSearchParams } from '@/hooks';

import { Flex, MarketIcon } from '@blockanalitica/ui';
import MarketsTable from './MarketsTable';

export default function FRBMarketsPage() {
  const [daysAgo] = useSearchParams('days_ago', '7');

  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <MarketIcon />
          <h1>Markets</h1>
        </Flex>
      }>
      <MarketsTable daysAgo={daysAgo} />
    </Page>
  );
}
