import BubbleChart from '@/components/charts/BarChart/TestChart/BubbleChart';
import { useApi } from '@/api'; // Ensure this hook is implemented correctly
import { formatCurrency } from '@/utils';
import { useMemo } from 'react';
import { CardLoader } from '@blockanalitica/ui';
import { textShortener, useThemeCustomProperties } from '@blockanalitica/ui';
export default function WalletsBubble() {
  const [data, error] = useApi({ url: '/activity/wallets' });
  const scaleTvlToRadius = (
    tvl,
    minTvl,
    maxTvl,
    minRadius = 2,
    maxRadius = 50
  ) => {
    const normalized = (tvl - minTvl) / (maxTvl - minTvl);
    return normalized * (maxRadius - minRadius) + minRadius;
  };
  const colors = useThemeCustomProperties([
    'color-primary',
    'color-variable',
    'color-short',
    'color-lp'
  ]);

  const bubbleChartData = useMemo(() => {
    const getColorByCategory = (category) => {
      const colorMap = {
        Long: colors.colorPrimary,
        LP: colors.colorLp,
        Short: colors.colorShort,
        default: 'rgba(201, 203, 207, 0.6)' // Default color for unspecified categories
      };
      return colorMap[category] || colorMap.default;
    };
    const transformDataForChart = (apiData) => {
      const minTvl = Math.min(...apiData.map((entry) => entry.tvl));
      const maxTvl = Math.max(...apiData.map((entry) => entry.tvl));

      const categoriesData = apiData.reduce((acc, entry) => {
        const category = entry.category;
        if (!acc[category]) {
          acc[category] = {
            label: category,
            data: [],
            backgroundColor: getColorByCategory(category)
          };
        }
        acc[category].data.push({
          x: entry.events,
          y: entry.pnl,
          tooltipHeader: textShortener(entry.wallet_address),
          tooltips: [
            { key: 'TVL', value: entry.tvl, currency: 'USD' },
            { key: 'Events', value: entry.events, currency: '' },
            { key: 'PnL', value: entry.pnl, currency: 'USD' }
          ],
          r: scaleTvlToRadius(entry.tvl, minTvl, maxTvl),
          label: entry.wallet_address
        });
        return acc;
      }, {});

      return {
        datasets: Object.values(categoriesData) // Converts category objects into an array
      };
    };
    return data ? transformDataForChart(data) : { datasets: [] };
  }, [data, colors.colorPrimary, colors.colorLp, colors.colorShort]);
  // Prepare props for BubbleChart
  const kpisConfig = useMemo(() => {
    if (data) {
      // Map through each dataset and grab the label from the last data point
      const defaultData = bubbleChartData.datasets.map((ds) => ({
        label: ds.label,
        color: ds.backgroundColor
      }));

      return {
        defaultData: defaultData
      };
    }

    return null; // Return null if there's no chartData available
  }, [bubbleChartData, data]);

  if (error) {
    throw error;
  }

  const config = {
    options: {
      onClick: function (event, elements) {
        if (elements.length > 0) {
          // Assuming each data point has a property 'wallet_address'
          const elementIndex = elements[0].index;
          const datasetIndex = elements[0].datasetIndex;
          const walletAddress =
            this.data.datasets[datasetIndex].data[elementIndex].label;

          // Navigate to a new page using the wallet address
          // Ensure that your environment allows window navigation like this
          window.location.href = `/wallets/${walletAddress}`;
        }
      },
      onHover: function (event, elements) {
        const canvas = this.canvas;
        // Change cursor to 'pointer' if hovering over a point
        canvas.style.cursor = elements.length > 0 ? 'pointer' : 'default';
      },
      scales: {
        x: {
          title: {
            display: true,
            text: 'Interactions with the Protocol'
          },
          ticks: {
            callback: function (value) {
              if (Number.isInteger(value)) {
                return value; // Only show integer values
              }
              return ''; // Skip non-integer values
            }
          },
          min: 0
        },
        y: {
          title: {
            display: true,
            text: 'PnL'
          },
          ticks: {
            callback: (value) => formatCurrency(value, 'USD')
          }
        }
      }
    }
  }; // Optional: Define any specific Chart.js configurations
  if (data) {
    return (
      <>
        <h4>Protocol Wallets</h4>
        <BubbleChart
          data={bubbleChartData}
          kpisConfig={kpisConfig}
          config={config}
        />
      </>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
