import Input from '@/components/forms/Input';
import Slider from '@/components/forms/Slider';
import { ApplyButton } from '@/components/forms';
import { Flex, Text, formatCurrency } from '@blockanalitica/ui';
import { useCallback, useEffect, useState } from 'react';

export type PositionPnlEstimatorFormValues = {
  daysToClose: string | number;
  rateAtClose: string | number;
  positionSize: string | number;
};

type PositionPnlEstimatorFormProps = {
  loading: boolean;
  maturityTime: number;
  onSubmit: (values: Record<string, string>) => void;
  currentRate: number | string;
  initialValues?: PositionPnlEstimatorFormValues;
};

export default function PositionPnlEstimatorForm({
  loading,
  maturityTime,
  onSubmit,
  currentRate,
  initialValues
}: PositionPnlEstimatorFormProps) {
  const [daysToClose, setDaysToClose] = useState<number>(
    Number(initialValues.daysToClose ?? 1)
  );
  const [rateAtClose, setRateAtClose] = useState<number>(
    Number(initialValues.rateAtClose ?? 0.01)
  );
  const [positionSize, setPositionSize] = useState<number>(
    Number(initialValues.positionSize ?? 1)
  );
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    const daysToCloseValidation = !isNaN(daysToClose) && daysToClose >= 1;
    const rateAtCloseValidation = !isNaN(rateAtClose) && rateAtClose >= 0.01;
    const positionSizeValidation = !isNaN(positionSize) && positionSize >= 1;

    if (
      daysToCloseValidation &&
      rateAtCloseValidation &&
      positionSizeValidation
    ) {
      setFormIsValid(true);
      return;
    }

    setFormIsValid(false);
  }, [daysToClose, positionSize, rateAtClose]);

  const handleDaysToCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDaysToClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleRateAtCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRateAtClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handlePositionSizeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (value === '' || parseFloat(value) >= 1) {
        setPositionSize(parseFloat(event.target.value));
      }
      setIsFormUpdated(true);
    },
    []
  );

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      onSubmit({
        days_to_close:
          String(daysToClose) !== String(initialValues.daysToClose)
            ? String(daysToClose)
            : undefined,
        rate_at_close:
          String(rateAtClose) !== String(initialValues.rateAtClose)
            ? String(rateAtClose)
            : undefined,
        position_size:
          String(positionSize) !== String(initialValues.positionSize)
            ? String(positionSize)
            : undefined
      });

      setIsFormUpdated(false);
    },
    [
      positionSize,
      daysToClose,
      initialValues.positionSize,
      initialValues.daysToClose,
      initialValues.rateAtClose,
      onSubmit,
      rateAtClose
    ]
  );

  return (
    <form onSubmit={submit}>
      <Flex
        justifyContent={'flex-start'}
        gap="space-m"
        direction={['row', 'column', 'column']}
        backgroundColor="color-background"
        borderRadius="size--4"
        padding={'space-xs'}
        alignItems={'flex-start'}>
        <Flex
          direction="column"
          flexBasis={'100%'}
          gap="space-xs"
          alignItems={'flex-start'}>
          <Flex gap="space-3xs" justifyContent={'center'} alignItems={'center'}>
            <label className="text-2">
              {formatCurrency(isNaN(positionSize) ? 0 : positionSize)}
            </label>
            <label className="text-1 text-muted">Bond Value</label>
          </Flex>

          <Input
            type="number"
            step="1"
            min={1}
            required
            value={positionSize}
            onChange={handlePositionSizeChange}
          />
        </Flex>

        <Slider
          label="days to Close"
          value={daysToClose}
          onChange={handleDaysToCloseChange}
          min={1}
          max={maturityTime}
        />

        <Flex
          flexBasis={'100%'}
          direction="column"
          gap="space-3xs"
          alignItems={'center'}>
          <Slider
            label="Expected Fixed Rate at Close"
            value={rateAtClose}
            onChange={handleRateAtCloseChange}
            min={0.01}
            max={100}
            isPercentage={true}
            step={'0.01'}
            disabled={daysToClose === Number(maturityTime)}
          />
          <Text className="text-1 text-muted">
            Current Fixed Rate: {currentRate}%
          </Text>
        </Flex>

        <ApplyButton type="submit" disabled={!isFormUpdated || !formIsValid}>
          {loading ? 'Loading...' : 'Apply'}
        </ApplyButton>
      </Flex>
    </form>
  );
}
