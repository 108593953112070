import {
  CollapsableSidebar,
  Flex,
  Grid1X2Icon,
  ListIcon,
  useToggle
} from '@blockanalitica/ui';
import { NavigationData } from '@blockanalitica/ui/package/esm/src/components/client-components/sidebars/FixedSidebar/components/Sidebar/Sidebar';
import { Fragment, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Footer, NavLink, SidebarNavigationItem } from '../..';
import styles from './CollapsableSidebarLayout.module.scss';
// @ts-ignore
import Logo from '@/assets/logos/BA-logo-full-white.svg?react';
import classnames from 'classnames';

type CollapsableSidebarLayoutProps = {
  title: string;
};

const navigationData: NavigationData[] = [
  {
    title: 'Home',
    url: '/',
    isRoot: true,
    icon: <Grid1X2Icon size={16} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} isSingleLink={true} href={url} text={title} />
    )
  },
  {
    title: 'Section 1',
    url: '/section-1',
    isRoot: true,
    icon: <Grid1X2Icon size={16} className={styles.icon} />,
    renderLink: ({ url, title }) => (
      <NavLink isRoot={true} href={url} text={title} />
    ),
    subMenu: [
      {
        title: 'Sub section 1',
        url: '/section-1/sub-section-1',
        icon: <Grid1X2Icon size={16} className={styles.icon} />,
        renderLink: ({ url, title }) => <NavLink href={url} text={title} />
      }
    ]
  }
];

export default function CollapsableSidebarLayout({
  title
}: CollapsableSidebarLayoutProps) {
  const [sidebarOpen, toggleSidebar] = useToggle();
  const location = useLocation();

  useEffect(() => {
    toggleSidebar(false);
  }, [location.pathname, toggleSidebar]);

  return (
    <Fragment>
      <Flex
        aria-label={`${title} Sidebar`}
        direction="column"
        className={styles.container}
        gap="space-0">
        <CollapsableSidebar
          logo={<Logo className={classnames(styles.logo)} height={60} />}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}>
          {navigationData.map((navigationItem) => (
            <SidebarNavigationItem
              key={navigationItem.title}
              item={navigationItem}
            />
          ))}
        </CollapsableSidebar>

        <Flex
          className={styles.contentContainer}
          gap="space-m"
          direction="column"
          justifyContent="space-between">
          <Flex justifyContent="space-between" alignItems="center">
            <h2 className={styles.title}>{title}</h2>

            <div className={styles.sidebarButtonContainer}>
              <ListIcon
                role="button"
                aria-label="Open sidebar button"
                onClick={() => toggleSidebar()}
                width={25}
                height={25}
              />
            </div>
          </Flex>

          <main>
            <Outlet />
          </main>

          <Footer />
        </Flex>
      </Flex>

      {sidebarOpen ? (
        <div
          onClick={() => toggleSidebar()}
          className={styles.alphaBackgroundLayer}
        />
      ) : null}
    </Fragment>
  );
}
