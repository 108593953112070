import { useApi } from '@/api';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import {
  CurrencySwitchBarChart,
  CurrencySwitchLineChart,
  PercentageLineChart
} from '@/components/charts';
import PercentageBarChart from '@/components/charts/BarChart/PercentageBarChart';
import { useSearchParams } from '@/hooks';
import { timeMapping } from '@/utils';
import {
  CardLoader,
  CurrencyFilter,
  Flex,
  useTableProps,
  useThemeCustomProperties
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export default function ChartTabs({ currency, daysAgo, trading }) {
  const { marketId } = useParams();
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'overview'
  );
  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );
  const colors = useThemeCustomProperties([
    'color-primary',
    'color-variable',
    'color-short',
    'color-lp'
  ]);

  const [data, error] = useApi({
    url: `/markets/${marketId}/snapshots/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  const { filters } = useTableProps({
    initialFilters: { currency: 'USD' },
    filtersDebounceTime: 50
  });

  let yField = 'volume_1d';

  const switchValue = filters.filtersApplied.currency;

  const tabsContent = useMemo(
    () => ({
      overview: (
        <CurrencySwitchLineChart
          key={activeDisplayOption + switchValue}
          data={data}
          fields={[
            {
              field: 'liquidity',
              label: 'Liquidity',
              backgroundColor: colors.colorLp
            },
            {
              field: 'total_long',
              label: 'Open Longs',
              backgroundColor: colors.colorPrimary
            },
            {
              field: 'total_short',
              label: 'Open Shorts',
              backgroundColor: colors.colorShort
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          daysAgo={daysAgo}
          options={{ dynamic: false }}
        />
      ),
      price: (
        <CurrencySwitchLineChart
          key={activeDisplayOption + switchValue}
          data={data}
          fields={[
            {
              field: 'share_price',
              label: 'Liquidity'
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          daysAgo={daysAgo}
          options={{ dynamic: false, notation: 'standard' }}
        />
      ),
      skew: (
        <PercentageBarChart
          data={data}
          fields={[
            {
              field: 'skew_long',
              label: 'Long',
              backgroundColor: colors.colorPrimary
            },
            {
              field: 'skew_short',
              label: 'Short',
              backgroundColor: colors.colorShort
            }
          ]}
          aspectRatio={6}
          daysAgo={daysAgo}
        />
      ),
      volume: (
        <CurrencySwitchBarChart
          data={data}
          fields={[
            {
              field: yField,
              label: 'Trading Volume',
              backgroundColor: colors.colorPrimary
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          daysAgo={daysAgo}
          options={{
            dynamic: true,
            defaultValue: trading,
            defaultSubValue: `Trading Volume ${timeMapping[daysAgo]}`
          }}
        />
      ),
      rates: (
        <PercentageLineChart
          key={activeDisplayOption + switchValue}
          data={data}
          fields={[
            {
              field: 'fixed_rate',
              label: 'Fixed',
              backgroundColor: colors.colorPrimary
            },
            {
              field: 'var_rate',
              label: 'Variable',
              backgroundColor: colors.colorVariable
            },
            {
              field: 'lp_rate',
              label: 'LP',
              backgroundColor: colors.colorLp
            }
          ]}
          currency={currency}
          switchValue={switchValue}
          aspectRatio={6}
          daysAgo={daysAgo}
        />
      )
    }),
    [activeDisplayOption, currency, data, switchValue, colors, daysAgo]
  );

  if (error) {
    throw error;
  }

  if (data) {
    const displayOptions = [
      {
        key: 'overview',
        value: 'Overview'
      },
      {
        key: 'rates',
        value: 'Rates'
      },
      {
        key: 'volume',
        value: 'Trading Volume'
      },
      {
        key: 'skew',
        value: 'Skew'
      },
      {
        key: 'price',
        value: `hy${currency} Price`
      }
    ];

    return (
      <Flex direction="column" gap="space-xs">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={handleTabClick}
          />
          <CurrencyFilter
            key="currency-filter"
            width="8rem"
            height="1.65rem"
            currency2={{ value: 'token', displayValue: 'Token' }}
            currency1={{ value: 'USD', displayValue: 'USD' }}
            {...filters}
          />
        </Flex>

        {tabsContent[activeDisplayOption]}
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
