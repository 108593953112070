import { useApi } from '@/api';
import { Divider, Page } from '@/components';
import { useSearchParams } from '@/hooks';
import { ActivityIcon, Flex } from '@blockanalitica/ui';
import ActivityHeatmap from './components/ActivityHeatmap';
import WalletTabs from './components/WalletTabs';
import WalletsBubble from './components/WalletsBubble';
export default function ActivityPage() {
  const [daysAgo] = useSearchParams('days_ago', '7');

  const [data, error] = useApi({
    url: `/overall`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    return (
      <Page
        title={
          <Flex gap="space-s" alignItems="center">
            <ActivityIcon />
            <h1>Activity</h1>
          </Flex>
        }>
        <Divider />
        <WalletTabs
          daysAgo={daysAgo}
          activeWallets={data[`active_wallets_${daysAgo}d`]}
          newWallets={data[`new_wallets_${daysAgo}d`]}
        />
        <Divider />
        <ActivityHeatmap />
        <Divider />
        <WalletsBubble />
      </Page>
    );
  }
  return null;
}
