import { Flex } from '@blockanalitica/ui';
import classnames from 'classnames';
import { useCallback } from 'react';
import styles from './DisplaySwitch.module.scss';

export default function DisplaySwitch({ options, activeOption, onChange }) {
  const onOptionClick = useCallback(
    (option) => {
      if (onChange) {
        onChange(option);
      }
    },
    [onChange]
  );

  return (
    <Flex className={styles.container}>
      {options.map((option) => (
        <div
          role="button"
          key={option.key}
          className={classnames('text-2', styles.wrapper, {
            [styles.active]: option.key === activeOption
          })}
          onClick={() => onOptionClick(option.key)}>
          {option.value}
        </div>
      ))}
    </Flex>
  );
}
