import { useApi } from '@/api';
import { useSearchParams } from '@/hooks';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';

import { Flex, Box } from '@blockanalitica/ui';

import {
  useParams,
  useSearchParams as useRRSearchParams
} from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import NewShortPositionPnlEstimatorForm from './components/NewShortPositionPnlEstimatorForm';

const initialData: Partial<MarketShortData> = {
  pnl: '0',
  pnl_usd: '0',
  value_at_close: '0',
  value_at_close_usd: '0',
  cost_to_open: '0',
  cost_to_open_usd: '0'
};

export type ShortMarketStat = { var_apy: string; fixed_rate: string };

type MarketShortData = {
  pnl: string;
  pnl_usd: string;
  value_at_close: string;
  value_at_close_usd: string;
  cost_to_open: string;
  cost_to_open_usd: string;
  market_maturity_time: string;
  asset_symbol: string;
  stats: ShortMarketStat[];
};

export default function NewShortPositionPnlEstimator() {
  const [assetId] = useSearchParams('asset_id', '');
  const { marketId } = useParams();

  const [daysToClose] = useSearchParams('days_to_close');
  const [rateAtClose] = useSearchParams('rate_at_close');
  const [positionSize] = useSearchParams('position_size');
  const [avgVarRate] = useSearchParams('avg_var_rate');
  const [, setRouterSearchParams] = useRRSearchParams();

  const updateSearchParams = useCallback(
    (searchParams: Record<string, string>) => {
      setRouterSearchParams((prevSearchParams) => {
        const updatedSearchParams = new URLSearchParams(prevSearchParams);

        Object.entries(searchParams).forEach(([key, value]) => {
          if (value !== undefined) {
            updatedSearchParams.set(key, String(value));
          }
        });

        return updatedSearchParams;
      });
    },
    [setRouterSearchParams]
  );

  const [data, error] = useApi<MarketShortData>({
    url: `/markets/${marketId}/open-short/`,
    queryParams: {
      asset_id: assetId,
      daysToClose: daysToClose,
      rateAtClose: rateAtClose,
      positionSize: positionSize,
      avgVarRate: avgVarRate
    }
  });

  const [cachedData, setCachedData] =
    useState<Partial<MarketShortData>>(initialData);

  useEffect(() => {
    if (data !== undefined) {
      setCachedData((prevState) => ({ ...prevState, ...data }));
    }
  }, [data]);

  if (error) {
    throw error;
  }

  return (
    <Flex direction="column" gap="space-m">
      <Flex direction={['row', 'column', 'column']} gap="space-xs">
        <Box flexBasis={['33%', '100%', '100%']}>
          <KpiCurrencyCard
            title="Estimated Opening Cost"
            data={cachedData}
            field="cost_to_open"
            trend={false}
            currency={cachedData?.asset_symbol}
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              iconSize: 'large',
              dynamic: false
            }}
          />
        </Box>
        <Box flexBasis={['33%', '100%', '100%']}>
          <KpiCurrencyCard
            title="Estimated Value at Close"
            data={cachedData}
            field="value_at_close"
            trend={false}
            currency={cachedData?.asset_symbol}
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              iconSize: 'large',
              dynamic: false
            }}
          />
        </Box>
        <Box flexBasis={['33%', '100%', '100%']}>
          <KpiCurrencyCard
            title="Estimated PnL"
            data={cachedData}
            field="pnl"
            trend={false}
            currency={cachedData?.asset_symbol}
            options={{
              valueSize: 'text-5',
              trendSize: 'text-3',
              footerSize: 'text-2',
              iconSize: 'large',
              dynamic: false
            }}
          />
        </Box>
      </Flex>

      <NewShortPositionPnlEstimatorForm
        loading={data === undefined}
        currentRates={cachedData?.stats}
        maturityTime={Number(cachedData?.market_maturity_time)}
        onSubmit={updateSearchParams}
        initialValues={{
          daysToClose,
          rateAtClose,
          positionSize,
          avgVarRate
        }}
      />
    </Flex>
  );
}
