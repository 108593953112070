import { ReactNode, useCallback } from 'react';
import {
  LinkProps,
  Link as ReactRouterLink,
  useLocation,
  useParams
} from 'react-router-dom';

type LinkWithGlobalFilterProps = LinkProps & {
  forwardSearch?: boolean;
  children?: ReactNode;
  className?: string;
  daysAgo?: boolean;
  force?: boolean;
};

export default function Link({
  forwardSearch = true,
  daysAgo = true,
  force = false,
  children,
  className,
  to,
  ...linkProps
}: LinkWithGlobalFilterProps) {
  const location = useLocation();
  const { network } = useParams();

  const constructToPath = useCallback(() => {
    const path = typeof to === 'object' ? to.pathname : to;
    const isLocalPath = path.startsWith('/');
    const pathIsRoot = path === '/';
    let toPath = pathIsRoot
      ? `/${network}`
      : isLocalPath
        ? `/${network}${path}`
        : `${location.pathname}/${path}`;

    if (force) {
      toPath = '/';
    }

    const currentToSearchParams = new URLSearchParams(
      typeof to === 'object' ? to.search : null
    );

    if (daysAgo) {
      const toSearchParams = new URLSearchParams();
      const currentSearchParams = new URLSearchParams(location.search);

      const daysAgo = currentSearchParams.get('days_ago');
      if (daysAgo) {
        toSearchParams.set('days_ago', daysAgo);
      }
      if (currentToSearchParams.get('tab')) {
        toSearchParams.set('tab', currentToSearchParams.get('tab'));
      }
      const prefix = toPath?.includes('?') ? '&' : '?';
      const searchParamsString = toSearchParams.toString()
        ? `${prefix}${toSearchParams.toString()}`
        : '';
      return typeof to === 'object'
        ? { ...to, search: searchParamsString }
        : `${toPath}${searchParamsString}`;
    }
    if (forwardSearch) {
      if (typeof to === 'object' && to.search) {
        return to;
      }

      const toPath = typeof to === 'object' ? to.pathname : to;
      const hasQueryParams = toPath?.includes('?');

      const searchParams = hasQueryParams
        ? location.search.replace('?', '&')
        : location.search;

      return typeof to === 'object'
        ? { ...to, search: searchParams }
        : `${toPath}${searchParams}`;
    }

    return to;
  }, [forwardSearch, location.search, to, daysAgo, network, location.pathname]);

  return (
    <ReactRouterLink
      {...linkProps}
      className={className}
      to={constructToPath()}>
      {children}
    </ReactRouterLink>
  );
}
