import Slider from '@/components/forms/Slider';
import { Flex, Text } from '@blockanalitica/ui';
import { useCallback, useState } from 'react';
import { PositionType } from '../PositionPnlEstimator';
import styled from 'styled-components';

export type PositionPnlEstimatorFormValues = {
  daysToClose: string | number;
  rateAtClose: string | number;
  avgVarRate: string | number;
};

type PositionPnlEstimatorFormProps = {
  loading: boolean;
  maturityTime: number;
  type: PositionType;
  onSubmit: (values: Record<string, string>) => void;
  currentRates: Array<{ var_apy: string; fixed_rate: string }>;
  initialValues?: PositionPnlEstimatorFormValues;
};

const ApplyButton = styled.button`
  padding: var(--space-2xs);
  background-color: ${({ disabled }) =>
    disabled ? 'var(--color-background)' : 'var(--color-primary)'};
  color: ${({ disabled }) =>
    disabled ? 'var(--color-primary)' : 'var(--color-background)'};
  border-radius: var(--size--4);
  border: 1px solid var(--color-primary);
  opacity: 0.7;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    &:hover {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
`;

export default function PositionPnlEstimatorForm({
  loading,
  maturityTime,
  type,
  onSubmit,
  currentRates,
  initialValues
}: PositionPnlEstimatorFormProps) {
  const [daysToClose, setDaysToClose] = useState<number>(
    Number(initialValues.daysToClose ?? 0)
  );
  const [rateAtClose, setRateAtClose] = useState<number>(
    Number(initialValues.rateAtClose ?? 0.01)
  );
  const [avgVarRate, setAvgVarRate] = useState<number>(
    type === 'Short' ? Number(initialValues.avgVarRate ?? 0.01) : null
  );
  const [isFormUpdated, setIsFormUpdated] = useState(false);

  const handleDaysToCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDaysToClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleRateAtCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRateAtClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleAvgVarRateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAvgVarRate(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      onSubmit({
        days_to_close:
          String(daysToClose) !== String(initialValues.daysToClose)
            ? String(daysToClose)
            : undefined,
        rate_at_close:
          String(rateAtClose) !== String(initialValues.rateAtClose)
            ? String(rateAtClose)
            : undefined,
        avg_var_rate:
          String(avgVarRate) !== String(initialValues.avgVarRate)
            ? String(avgVarRate)
            : undefined
      });

      setIsFormUpdated(false);
    },
    [
      avgVarRate,
      daysToClose,
      initialValues.avgVarRate,
      initialValues.daysToClose,
      initialValues.rateAtClose,
      onSubmit,
      rateAtClose
    ]
  );

  return (
    <form onSubmit={submit}>
      <Flex
        justifyContent={'space-between'}
        gap="space-s"
        direction={['row', 'column', 'column']}
        backgroundColor="color-background"
        borderRadius="size--4"
        padding={'space-xs'}
        alignItems={'flex-start'}>
        <Slider
          label="days to Close"
          value={daysToClose}
          onChange={handleDaysToCloseChange}
          min={0}
          max={maturityTime}
        />

        <Flex
          width={'100%'}
          direction="column"
          gap="space-3xs"
          alignItems={'center'}>
          <Slider
            label="Expected Fixed Rate at Close"
            value={rateAtClose}
            onChange={handleRateAtCloseChange}
            min={0.01}
            max={100}
            isPercentage={true}
            step={'0.01'}
            disabled={daysToClose === maturityTime}
          />

          <Text className="text-1 text-muted">
            Current Fixed Rate: {currentRates['fixed_rate']}%
          </Text>
        </Flex>

        {type === 'Short' ? (
          <Flex
            width={'100%'}
            direction="column"
            gap="space-3xs"
            alignItems={'center'}>
            <Slider
              label="Expected Avg. Variable Rate"
              value={avgVarRate}
              isPercentage={true}
              onChange={handleAvgVarRateChange}
              min={0.01}
              max={100}
              step={'0.01'}
            />

            <Text className="text-1 text-muted">
              Current Var. Rate: {currentRates['var_apy']}%
            </Text>
          </Flex>
        ) : null}

        <ApplyButton type="submit" disabled={!isFormUpdated}>
          {loading ? 'Loading...' : 'Apply'}
        </ApplyButton>
      </Flex>
    </form>
  );
}
