import { useApi } from '@/api';
import { Link } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import TableLoader from '@/components/loaders/TableLoader';
import Table from '@/components/table/Table';
import {
  CryptoIcon,
  CurrencyValue,
  Flex,
  PercentageValue,
  RelativeDateTime
} from '@blockanalitica/ui';

export default function LPTable({
  daysAgo,
  walletAddress,
  sorting,
  pagination,
  filters
}) {
  const [data, error] = useApi({
    url: `/positions/`,
    queryParams: {
      type: 'lp',
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      order: sorting.currentSorting,
      days_ago: daysAgo,
      wallet_address: walletAddress
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <Flex gap="space-3xs" alignItems={'center'}>
            <DoubleSymbol
              symbol1={<CryptoIcon name={row.asset_symbol} extension="png" />}
              symbol2={
                <CryptoIcon
                  name={row.yield_source ? row.yield_source : ''}
                  extension="png"
                />
              }
            />
            {row.asset_symbol} {row.market_maturity_time / 86400}d
          </Flex>
        )
      },
      {
        header: 'AMOUNT',
        getCell: (row) => (
          <>
            {filters.filtersApplied.currency === 'USD' ? (
              <CurrencyValue
                value={row.balance_usd}
                options={{ currency: 'USD' }}
              />
            ) : (
              <CurrencyValue
                value={row.balance}
                options={{
                  currency: row.asset_symbol,
                  iconOpacity: 0.7,
                  leadingCurrencySymbol: false,
                  iconSize: 'xSmall'
                }}
              />
            )}
          </>
        ),
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.balance_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'balance'
      },
      {
        header: 'LATEST ACTIVITY',
        getCell: (row) => <RelativeDateTime value={row.last_activity} />,
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'last_activity'
      }
    ];

    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.wallet_address}
        getLinkWrapper={(row) => (
          <Link
            daysAgo={true}
            to={`/markets/${row.address}/positions/${row.wallet_address}/lp`}
          />
        )}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return <TableLoader />;
}
