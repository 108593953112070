import { Page } from '@/components';
import { useSearchParams } from '@/hooks';
import { Flex, MarketIcon } from '@blockanalitica/ui';
import MarketsTable from './markets/MarketsTable';

export default function FixedRateBorrowPage() {
  const [daysAgo] = useSearchParams('days_ago', '7');

  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <MarketIcon />
          <h1>Fixed Borrow</h1>
        </Flex>
      }>
      <MarketsTable daysAgo={daysAgo} />
    </Page>
  );
}
