import { Flex } from '@blockanalitica/ui';
import { useCallback } from 'react';
import { useSearchParams } from '@/hooks';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { useSearchParams as useRRSearchParams } from 'react-router-dom';
import LpPositionPnlEstimatorForm from './LpPositionPnlEstimatorForm';

export type DailyVolumeOptions =
  | 'average_volume_1d'
  | 'average_volume_7d'
  | 'average_volume_30d'
  | 'average_volume_90d'
  | 'average_volume_365d';

export type IdleLiquidityOptions =
  | 'average_idle_liquidity_1d'
  | 'average_idle_liquidity_7d'
  | 'average_idle_liquidity_30d'
  | 'average_idle_liquidity_90d'
  | 'average_idle_liquidity_365d';

export type LpPositionPnlEstimatorFormValues = {
  daysToClose: string | number;
  rateAtClose: string | number;
  avgVarRate: string | number;
  idleLiquidityPercentage: string | number;
  dailyVolume: string | number;
  avgLpApy: string | number;
  dailyVolumeOptions: DailyVolumeOptions;
  idleLiquidityOptions: IdleLiquidityOptions;
  currentRates: Array<{
    var_apy: string | number;
    fixed_rate: string | number;
    lp_apy: string | number;
  }>;
};

const displayOptions = [
  { key: 'simple', value: 'Simple' },
  { key: 'advanced', value: 'Advanced' }
];

type LpPositionPnlEstimatorFormProps = {
  loading: boolean;
  onSubmit: (values: Record<string, string>) => void;
  initialValues?: LpPositionPnlEstimatorFormValues;
};

export default function LpPositionPnlEstimatorFormTabs({
  loading,
  onSubmit,
  initialValues
}: LpPositionPnlEstimatorFormProps) {
  const [, setRouterSearchParams] = useRRSearchParams();
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'type',
    'simple'
  );

  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
      const newSearchParams = new URLSearchParams();
      newSearchParams.set('type', key);
      setRouterSearchParams(newSearchParams);
    },

    [setActiveDisplayOption, setRouterSearchParams]
  );

  return (
    <Flex direction="column" gap="space-m">
      <Flex
        direction={['row', 'row', 'column']}
        alignItems={['flex-start', 'flex-start', 'center']}
        justifyContent="space-between"
        gap="space-xs">
        <DisplaySwitch
          options={displayOptions}
          activeOption={activeDisplayOption}
          onChange={handleTabClick}
        />
      </Flex>

      <LpPositionPnlEstimatorForm
        loading={loading}
        onSubmit={onSubmit}
        initialValues={initialValues}
        advanced={activeDisplayOption === 'advanced'}
      />
    </Flex>
  );
}
