import { useApi } from '@/api';
import { Link } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import Table from '@/components/table/Table';
import {
  CryptoIcon,
  CurrencyValue,
  Flex,
  PercentageValue,
  TableLoader,
  useTableProps
} from '@blockanalitica/ui';

export default function MarketsTable({
  daysAgo,
  filters,
  asset_address = null
}) {
  const { sorting, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-liquidity_usd',
    initialFilters: { currency: 'USD' }
  });
  const [data, error] = useApi({
    url: '/markets/',
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      days_ago: daysAgo,
      order: sorting.currentSorting,
      asset_address: asset_address
    }
  });
  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'MARKET',
        getCell: (row) => (
          <Flex gap="space-3xs" alignItems={'center'}>
            <DoubleSymbol
              symbol1={
                <CryptoIcon name={row.asset_symbol} size={30} extension="png" />
              }
              symbol2={
                <CryptoIcon
                  name={row.yield_symbol ? row.yield_symbol : ''}
                  size={30}
                  extension="png"
                />
              }
            />
            {row.name}
          </Flex>
        )
      },
      {
        header: 'YIELD SOURCE',
        getCell: (row) => (
          <Flex gap="space-3xs" alignItems={'center'}>
            <CryptoIcon
              name={row.yield_source ? row.yield_source : ''}
              size={30}
              extension="png"
            />
            {row.yield_name}
          </Flex>
        )
      },
      {
        header: 'LIQUIDITY',
        getCell: (row) => {
          const liquidityValue =
            filters.filtersApplied.currency === 'USD'
              ? Number(row.liquidity_usd)
              : Number(row.liquidity);

          if (filters.filtersApplied.currency === 'USD') {
            if (liquidityValue !== 0 && Math.abs(liquidityValue) < 0.01) {
              return liquidityValue < 0 ? '>$-0.01' : '<$0.01';
            }
          } else {
            if (liquidityValue !== 0 && Math.abs(liquidityValue) < 0.00001) {
              const val = liquidityValue < 0 ? '>-0.00001' : '<0.00001';
              return (
                <Flex direction={'row'} gap={'space-3xs'}>
                  <CryptoIcon
                    size={'xSmall'}
                    name={row.asset_symbol}
                    extension="png"
                  />
                  {val}
                </Flex>
              );
            }
          }

          return (
            <CurrencyValue
              value={liquidityValue}
              options={{
                currency:
                  filters.filtersApplied.currency === 'USD'
                    ? 'USD'
                    : row.asset_symbol,
                dynamic: false,
                leadingCurrencySymbol:
                  filters.filtersApplied.currency !== 'USD',
                iconOpacity: 0.7,
                iconSize: 'xSmall'
              }}
            />
          );
        },

        getCellBottom: (row) =>
          filters.filtersApplied.currency === 'USD' ? (
            <PercentageValue
              value={Number(row.liquidity_usd_change_percentage)}
              options={{ trend: true, hideIfZero: true }}
            />
          ) : (
            <PercentageValue
              value={Number(row.liquidity_change_percentage)}
              options={{ trend: true, hideIfZero: true }}
            />
          ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField:
          filters.filtersApplied.currency === 'USD'
            ? 'liquidity_usd'
            : 'liquidity'
      },
      {
        header: 'TOTAL LONG',
        getCell: (row) => {
          const totalLongValue =
            filters.filtersApplied.currency === 'USD'
              ? Number(row.total_long_usd)
              : Number(row.total_long);

          if (filters.filtersApplied.currency === 'USD') {
            if (totalLongValue !== 0 && Math.abs(totalLongValue) < 0.01) {
              return totalLongValue < 0 ? '>$-0.01' : '<$0.01';
            }
          } else {
            if (totalLongValue !== 0 && Math.abs(totalLongValue) < 0.00001) {
              const val = totalLongValue < 0 ? '>-0.00001' : '<0.00001';
              return (
                <Flex direction={'row'} gap={'space-3xs'}>
                  <CryptoIcon
                    size={'xSmall'}
                    name={row.asset_symbol}
                    extension="png"
                  />
                  {val}
                </Flex>
              );
            }
          }

          return (
            <CurrencyValue
              value={totalLongValue}
              options={{
                currency:
                  filters.filtersApplied.currency === 'USD'
                    ? 'USD'
                    : row.asset_symbol,
                dynamic: false,
                leadingCurrencySymbol:
                  filters.filtersApplied.currency !== 'USD',
                iconOpacity: 0.7,
                iconSize: 'xSmall'
              }}
            />
          );
        },
        getCellBottom: (row) =>
          filters.filtersApplied.currency === 'USD' ? (
            <PercentageValue
              value={Number(row.total_long_usd_change_percentage)}
              options={{ trend: true, hideIfZero: true }}
            />
          ) : (
            <PercentageValue
              value={Number(row.total_long_change_percentage)}
              options={{ trend: true, hideIfZero: true }}
            />
          ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField:
          filters.filtersApplied.currency === 'USD'
            ? 'total_long_usd'
            : 'total_long'
      },

      {
        header: 'TOTAL SHORT',
        getCell: (row) => {
          const totalShortValue =
            filters.filtersApplied.currency === 'USD'
              ? Number(row.total_short_usd)
              : Number(row.total_short);

          if (filters.filtersApplied.currency === 'USD') {
            if (totalShortValue !== 0 && Math.abs(totalShortValue) < 0.01) {
              return totalShortValue < 0 ? '>$-0.01' : '<$0.01';
            }
          } else {
            if (totalShortValue !== 0 && Math.abs(totalShortValue) < 0.00001) {
              const val = totalShortValue < 0 ? '>-0.00001' : '<0.00001';
              return (
                <Flex direction={'row'} gap={'space-3xs'}>
                  <CryptoIcon
                    size={'xSmall'}
                    name={row.asset_symbol}
                    extension="png"
                  />
                  {val}
                </Flex>
              );
            }
          }

          return (
            <CurrencyValue
              value={totalShortValue}
              options={{
                currency:
                  filters.filtersApplied.currency === 'USD'
                    ? 'USD'
                    : row.asset_symbol,
                dynamic: false,
                leadingCurrencySymbol:
                  filters.filtersApplied.currency !== 'USD',
                iconOpacity: 0.7,
                iconSize: 'xSmall'
              }}
            />
          );
        },
        getCellBottom: (row) =>
          filters.filtersApplied.currency === 'USD' ? (
            <PercentageValue
              value={Number(row.total_short_usd_change_percentage)}
              options={{ trend: true, hideIfZero: true }}
            />
          ) : (
            <PercentageValue
              value={Number(row.total_short_change_percentage)}
              options={{ trend: true, hideIfZero: true }}
            />
          ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField:
          filters.filtersApplied.currency === 'USD'
            ? 'total_short_usd'
            : 'total_short'
      },
      {
        header: 'FIXED APR',
        getCell: (row) => (
          <PercentageValue
            value={row.fixed_rate}
            options={{ dashIfZero: true, tooltip: true }}
          />
        ),
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.fixed_rate_change)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'fixed_rate'
      },
      {
        header: `VARIABLE APY`,
        getCell: (row) => (
          <PercentageValue
            value={Number(row.var_rate_24h)}
            options={{ dashIfZero: true, tooltip: true }}
          />
        ),
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.var_rate_24h_change)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'var_rate_24h'
      },
      {
        header: 'LP APY',
        getCell: (row) => (
          <PercentageValue
            value={Number(row.lp_rate_24h)}
            options={{ dashIfZero: true, tooltip: true }}
          />
        ),
        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.lp_rate_24h_change)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),

        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'lp_rate_24h'
      }
    ];

    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.uid}
        getLinkWrapper={(row) => (
          <Link daysAgo={true} to={`/markets/${row.address}`} />
        )}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return <TableLoader />;
}
