import {
  BarChart as BarChartComponent,
  EmptyBox,
  formatDatetime,
  formatPercentage,
  PercentageValue
} from '@blockanalitica/ui';
import { ChartPoint } from '@blockanalitica/ui/package/esm/src/components/client-components/charts/ChartJS';
import { EmptyBoxProps } from '@blockanalitica/ui/package/esm/src/components/client-components/EmptyBox/EmptyBox';
import { ChartConfiguration, ChartType } from 'chart.js';
import { useMemo, useState } from 'react';
import { DeepPartial } from 'utility-types';

type Field = {
  label: string;
  field: string;
  backgroundColor: string | number;
};

type BarChartData = {
  date: string;
} & {
  [key in string as Exclude<key, 'date'>]: number;
};

type BarChartOptions = {
  emptyBoxProps?: EmptyBoxProps;
};

interface BarChartProps {
  data: BarChartData[];
  fields: Field[];
  currency?: string;
  switchValue?: string;
  aspectRatio?: number;
  options?: BarChartOptions;
  daysAgo?: number;
}

export default function PercentageBarChart({
  data,
  fields,
  aspectRatio = 4,
  options,
  daysAgo = 30
}: BarChartProps) {
  const [oldAspectRatio, setOldAspectRatio] = useState<number | null>(null);

  const chartData = useMemo(() => {
    if (data.length) {
      return {
        datasets: fields.map((fieldInfo) => ({
          label: fieldInfo.label,
          data: data.map((item) => ({
            x: Number(daysAgo) === 1 ? item.datetime : item.date,
            y: item[fieldInfo.field],
            label: fieldInfo.label
          })),
          backgroundColor: String(fieldInfo.backgroundColor)
        }))
      };
    }

    return null;
  }, [data, fields, daysAgo]);

  const kpisConfig = useMemo(() => {
    const valueFormatter = (value: number) => (
      <span className="text-mono">
        <PercentageValue value={value} />
      </span>
    );

    if (chartData) {
      const defaultData = chartData.datasets.map((ds) => ({
        y: ds.data[ds.data.length - 1].y,
        x: ds.data[ds.data.length - 1].x,
        label: ds.data[ds.data.length - 1].label
      }));
      return {
        valueFormatter: valueFormatter,
        subValueFormatter: (value: string | number) => (
          <span>{formatDatetime(value)}</span>
        ),
        defaultData: defaultData
      };
    }

    return null;
  }, [chartData]);

  const config: DeepPartial<
    ChartConfiguration<ChartType, number[] | ChartPoint[], string>
  > = useMemo(
    () => ({
      options: {
        aspectRatio: aspectRatio,
        onResize: (chart) => {
          const windowWidth = window.innerWidth;
          if (windowWidth < 576 && chart.options.aspectRatio !== 1.5) {
            if (oldAspectRatio === null) {
              setOldAspectRatio(chart.options.aspectRatio);
            }
            chart.options.aspectRatio = 1.5;
          } else if (windowWidth >= 576 && oldAspectRatio !== null) {
            chart.options.aspectRatio = oldAspectRatio;
            setOldAspectRatio(null); // Reset oldAspectRatio
          }
          chart.update();
        },
        scales: {
          x: {
            stacked: true,
            type: 'time',
            time: {
              unit: Number(daysAgo) === 1 ? 'hour' : 'day'
            },
            ticks: {
              callback: (value: number) =>
                formatDatetime(value, {
                  format: Number(daysAgo) === 1 ? 'H:mm' : 'MMM d'
                })
            }
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            stacked: true,
            ticks: {
              format: { notation: 'compact' },
              callback: (value: number) => formatPercentage(value)
            }
          }
        }
      }
    }),
    [aspectRatio, oldAspectRatio, daysAgo]
  );

  if (chartData && kpisConfig) {
    return (
      <BarChartComponent
        data={chartData}
        kpisConfig={kpisConfig}
        config={config}
      />
    );
  }

  return <EmptyBox {...options?.emptyBoxProps} />;
}
