import { useApi } from '@/api';
import Table from '@/components/table/Table';
import { symbolMapping, titleCase } from '@/utils';
import {
  CryptoIcon,
  CurrencyValue,
  Flex,
  PercentageValue,
  TableLoader,
  useTableProps
} from '@blockanalitica/ui';
import { Link } from 'react-router-dom';

export default function ChainsTable({ daysAgo }) {
  const { sorting, pagination } = useTableProps({
    pageSize: 10,
    initialSorting: '-liquidity_usd',
    initialFilters: { currency: 'USD' }
  });
  const [data, error] = useApi({
    url: '/chains/',
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      days_ago: daysAgo,
      order: sorting.currentSorting
    }
  });
  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'NETWORK',
        getCell: (row) => (
          <Flex gap="space-xs" alignItems={'center'}>
            <CryptoIcon name={symbolMapping[row.network]} size={30} />
            {titleCase(row.network)}
          </Flex>
        )
      },
      {
        header: 'LIQUIDITY',
        getCell: (row) => {
          const liquidityValue = Number(row.liquidity);

          if (liquidityValue !== 0 && Math.abs(liquidityValue) < 0.01) {
            return liquidityValue < 0 ? '>$-0.01' : '<$0.01';
          }

          return (
            <CurrencyValue
              value={liquidityValue}
              options={{
                currency: 'USD',
                dynamic: false
              }}
            />
          );
        },

        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.liquidity_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),

        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'liquidity'
      },
      {
        header: 'TVL',
        getCell: (row) => {
          const value = Number(row.tvl);

          if (value !== 0 && Math.abs(value) < 0.01) {
            return value < 0 ? '>$-0.01' : '<$0.01';
          }

          return (
            <CurrencyValue
              value={value}
              options={{
                currency: 'USD',
                dynamic: false
              }}
            />
          );
        },

        getCellBottom: (row) => (
          <PercentageValue
            value={Number(row.tvl_change_percentage)}
            options={{ trend: true, hideIfZero: true }}
          />
        ),

        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'tvl'
      },
      {
        header: 'TRADING VOLUME',
        getCell: (row) => {
          const value = Number(row.volume);

          if (value !== 0 && Math.abs(value) < 0.01) {
            return value < 0 ? '>$-0.01' : '<$0.01';
          }

          return (
            <CurrencyValue
              value={value}
              options={{
                currency: 'USD',
                dynamic: false
              }}
            />
          );
        },

        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'volume'
      }
    ];

    return (
      <Table
        rows={data}
        columns={columns}
        getKey={(row) => row.uid}
        getLinkWrapper={(row) => <Link to={`/${row.network}`} />}
      />
    );
  }
  return <TableLoader />;
}
