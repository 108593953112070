import { useApi } from '@/api';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { BarChart, LineChart } from '@/components/charts';
import { useSearchParams } from '@/hooks';
import { timeMapping } from '@/utils';
import { CardLoader, Flex, useThemeCustomProperties } from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';

const displayOptions = [
  { key: 'active_wallets', value: 'Active Wallets' },
  { key: 'active_wallets_cumulative', value: 'Cumulative' },
  { key: 'new_wallets', value: 'New Wallets' }
];

export default function WalletTabs({ daysAgo, activeWallets, newWallets }) {
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'active_wallets'
  );

  let [data] = useApi({
    url: `/activity/wallets-activity/`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  const colors = useThemeCustomProperties(['color-primary']);

  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const tabsContent = useMemo(() => {
    if (activeDisplayOption == 'active_wallets') {
      return (
        <BarChart
          key={activeDisplayOption + daysAgo}
          data={data != undefined ? data : []}
          fields={[
            {
              field: 'active_wallets',
              label: '',
              backgroundColor: colors.colorPrimary
            }
          ]}
          aspectRatio={6}
          valueFormatter={(value) => value}
          daysAgo={daysAgo}
          options={{
            dynamic: true,
            defaultValue: activeWallets,
            defaultSubValue: `Active Wallets ${timeMapping[daysAgo]}`
          }}
        />
      );
    } else if (activeDisplayOption == 'new_wallets') {
      return (
        <BarChart
          key={activeDisplayOption + daysAgo}
          data={data != undefined ? data : []}
          fields={[
            {
              field: 'new_wallets',
              label: '',
              backgroundColor: colors.colorPrimary
            }
          ]}
          aspectRatio={6}
          valueFormatter={(value) => value}
          daysAgo={daysAgo}
          options={{
            dynamic: true,
            defaultValue: newWallets,
            defaultSubValue: `New Wallets ${timeMapping[daysAgo]}`
          }}
        />
      );
    } else {
      return (
        <LineChart
          key={activeDisplayOption + daysAgo}
          data={data != undefined ? data : []}
          field={'active_wallets_cumulative'}
          aspectRatio={6}
          valueFormatter={(value) => value}
          daysAgo={daysAgo}
        />
      );
    }
  }, [activeDisplayOption, data, colors, daysAgo]);

  if (data) {
    return (
      <Flex direction="column" gap="space-m">
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'center']}
          justifyContent="space-between"
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={handleTabClick}
          />
        </Flex>

        {tabsContent}
      </Flex>
    );
  }

  return <CardLoader headerHeight="3rem" bodyHeight="7rem" />;
}
