import { Box, Flex, Select, Text, formatCurrency } from '@blockanalitica/ui';
import { useCallback, useEffect, useState } from 'react';
import styles from './LpPositionPnlEstimatorForm.module.scss';
import { snakeToSpaceCase } from '@/utils';
import classnames from 'classnames';
import {
  LPIdleLiquidity,
  LPMarketStat,
  LPMarketVolume
} from '../NewLpPositionPnlEstimator';
import { ApplyButton, Input, Slider } from '@/components/forms';

export type NewLpPositionPnlEstimatorFormValues = {
  daysToClose: string | number;
  positionSize: string | number;
  rateAtClose: string | number;
  avgVarRate: string | number;
  idleLiquidityPercentage: string | number;
  dailyVolume: string | number;
  dailyVolumeOptions: Partial<LPMarketVolume>;
  idleLiquidityOptions: Partial<LPIdleLiquidity>;
  currentRates: Partial<LPMarketStat>;
  avgLpApy: string | number;
};

type NewLpPositionPnlEstimatorFormProps = {
  loading: boolean;
  onSubmit: (values: Record<string, string>) => void;
  onReset: () => void;
  initialValues?: NewLpPositionPnlEstimatorFormValues;
  advanced?: boolean;
};

export default function LpPositionPnlEstimatorForm({
  loading,
  onSubmit,
  onReset,
  initialValues,
  advanced
}: NewLpPositionPnlEstimatorFormProps) {
  const [daysToClose, setDaysToClose] = useState<number>(
    Number(initialValues.daysToClose ?? 1)
  );

  const [rateAtClose, setRateAtClose] = useState<number>(
    Number(initialValues.rateAtClose ?? 0.01)
  );
  const [avgVarRate, setAvgVarRate] = useState<number>(
    Number(initialValues.avgVarRate ?? 0.01)
  );
  const [idleLiquidityPercentage, setIdleLiquidityPercentage] =
    useState<number>(Number(initialValues.idleLiquidityPercentage ?? 0.01));

  const [dailyVolume, setDailyVolume] = useState<number>(
    Number(initialValues.dailyVolume ?? 0)
  );
  const [positionSize, setPositionSize] = useState<number>(
    Number(initialValues.positionSize ?? 1)
  );
  const [avgLpApy, setAvgLpApy] = useState<number>(
    Number(initialValues.avgLpApy ?? 0.01)
  );

  const [showIdleLiquiditySlider, setShowIdleLiquiditySlider] = useState(false);
  const [showDailyVolumeSlider, setShowDailyVolumeSlider] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advanced]);

  useEffect(() => {
    const daysToCloseValidation =
      !isNaN(daysToClose) && daysToClose >= 1 && daysToClose <= 365;
    const rateAtCloseValidation =
      !isNaN(rateAtClose) && rateAtClose >= 0.01 && rateAtClose <= 100;
    const avgVarRateValidation =
      !isNaN(avgVarRate) && avgVarRate >= 0.01 && avgVarRate <= 100;
    const idleLiquidityPercentageValidation =
      !isNaN(idleLiquidityPercentage) &&
      idleLiquidityPercentage >= 0.01 &&
      idleLiquidityPercentage <= 100;
    const dailyVolumeValidation = !isNaN(dailyVolume) && dailyVolume >= 0;
    const positionSizeValidation = !isNaN(positionSize) && positionSize >= 1;

    if (
      daysToCloseValidation &&
      rateAtCloseValidation &&
      avgVarRateValidation &&
      idleLiquidityPercentageValidation &&
      dailyVolumeValidation &&
      positionSizeValidation
    ) {
      setFormIsValid(true);
      return;
    }

    setFormIsValid(false);
  }, [
    avgVarRate,
    dailyVolume,
    daysToClose,
    idleLiquidityPercentage,
    positionSize,
    rateAtClose
  ]);

  const resetForm = useCallback(() => {
    // reset form values
    setPositionSize(1);
    setDaysToClose(1);
    setRateAtClose(0.01);
    setAvgVarRate(0.01);
    setIdleLiquidityPercentage(0.01);
    setDailyVolume(0);
    setAvgLpApy(0.01);

    // reset data
    onReset();
    setIsFormUpdated(false);
  }, [onReset]);

  const handleDaysToCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDaysToClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleRateAtCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRateAtClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleAvgVarRateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAvgVarRate(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleIdleLiquidityPercentageChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'custom') {
        setShowIdleLiquiditySlider(true);

        return;
      }
      setIdleLiquidityPercentage(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleIdleLiquidityPercentageCustomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIdleLiquidityPercentage(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleDailyVolumeChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      if (event.target.value === 'custom') {
        setShowDailyVolumeSlider(true);

        return;
      }

      setDailyVolume(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleDailyVolumeCustomChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDailyVolume(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handlePositionSizeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (value === '' || parseFloat(value) >= 1) {
        setPositionSize(parseFloat(event.target.value));
      }
      setIsFormUpdated(true);
    },
    []
  );

  const handleAvgLpApyChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAvgLpApy(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      onSubmit({
        days_to_close:
          String(daysToClose) !== String(initialValues.daysToClose)
            ? String(daysToClose)
            : undefined,
        rate_at_close:
          String(rateAtClose) !== String(initialValues.rateAtClose)
            ? String(rateAtClose)
            : undefined,
        avg_var_rate:
          String(avgVarRate) !== String(initialValues.avgVarRate)
            ? String(avgVarRate)
            : undefined,
        idle_liquidity_percentage:
          String(idleLiquidityPercentage) !==
          String(initialValues.idleLiquidityPercentage)
            ? String(idleLiquidityPercentage)
            : undefined,
        daily_volume:
          String(dailyVolume) !== String(initialValues.dailyVolume)
            ? String(dailyVolume)
            : undefined,
        position_size:
          String(positionSize) !== String(initialValues.positionSize)
            ? String(positionSize)
            : undefined,
        avg_lp_apy:
          String(avgLpApy) !== String(initialValues.avgLpApy)
            ? String(avgLpApy)
            : undefined
      });

      setIsFormUpdated(false);
    },
    [
      avgVarRate,
      daysToClose,
      positionSize,
      avgLpApy,
      initialValues.positionSize,
      initialValues.avgVarRate,
      initialValues.daysToClose,
      initialValues.rateAtClose,
      initialValues.avgLpApy,
      onSubmit,
      idleLiquidityPercentage,
      dailyVolume,
      rateAtClose,
      initialValues.idleLiquidityPercentage,
      initialValues.dailyVolume
    ]
  );

  return (
    <form onSubmit={submit}>
      {advanced ? (
        <Flex
          direction={['column', 'column', 'column']}
          gap="space-xs"
          backgroundColor="color-background"
          borderRadius="size--4">
          <Flex
            backgroundColor="color-background"
            borderRadius="size--4"
            padding={'space-xs'}
            gap="space-s"
            justifyContent={'space-between'}
            direction={['row', 'row', 'column']}>
            <Flex direction={'column'} gap="space-s" flex={'1'}>
              <Flex
                justifyContent={'flex-start'}
                gap="space-s"
                direction={['row', 'column', 'column']}
                alignItems={['flex-start', 'flex-start', 'flex-start']}>
                <Flex
                  direction="column"
                  flex="1 1 33%"
                  gap="space-xs"
                  alignItems={'flex-start'}>
                  <Flex gap="space-3xs" alignItems={'center'}>
                    <label className="text-2">
                      {formatCurrency(isNaN(positionSize) ? 0 : positionSize)}
                    </label>
                    <label className="text-1 text-muted">Position Size</label>
                  </Flex>

                  <Input
                    type="number"
                    step="1"
                    min={1}
                    required
                    value={positionSize}
                    onChange={handlePositionSizeChange}
                  />
                </Flex>

                {showIdleLiquiditySlider ? (
                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    gap={'space-3xs'}
                    flex="1 1 33%">
                    <Slider
                      label="Idle Liquidity %"
                      value={idleLiquidityPercentage}
                      onChange={handleIdleLiquidityPercentageCustomChange}
                      isPercentage={true}
                      min={0.01}
                      max={100}
                      step={'0.01'}
                    />

                    <Text
                      className="text-1"
                      color={'color-link'}
                      opacity={0.7}
                      hover={{
                        opacity: '1',
                        cursor: 'pointer'
                      }}
                      onClick={() => setShowIdleLiquiditySlider(false)}>
                      select option
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    alignItems={'flex-start'}
                    flex="1 1 33%"
                    gap="space-xs"
                    justifyContent={'center'}>
                    <Flex
                      gap="space-2xs"
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <label className="text-2">
                        {idleLiquidityPercentage}%
                      </label>
                      <label className="text-1 text-muted">
                        Idle Liquidity %
                      </label>
                    </Flex>

                    <Select
                      placeholder="Select Liquidity"
                      options={[
                        ...Object.keys(initialValues.idleLiquidityOptions).map(
                          (key) => ({
                            label: snakeToSpaceCase(key),
                            value: String(
                              initialValues.idleLiquidityOptions[key]
                            )
                          })
                        ),
                        { label: 'Custom', value: 'custom' }
                      ]}
                      onChange={handleIdleLiquidityPercentageChange}
                      className={classnames(
                        styles.lpPositionEstimator,
                        styles.select,
                        styles.focused
                      )}
                    />
                  </Flex>
                )}

                {showDailyVolumeSlider ? (
                  <Flex
                    direction={'column'}
                    alignItems={'center'}
                    gap={'space-3xs'}
                    flex="1 1 33%">
                    <Slider
                      label="daily volume"
                      value={dailyVolume}
                      onChange={handleDailyVolumeCustomChange}
                      min={0}
                      max={999000000}
                      step={'1000000'}
                      isCurrency={true}
                    />

                    <Text
                      className="text-1"
                      color={'color-link'}
                      opacity={0.7}
                      hover={{
                        opacity: '1',
                        cursor: 'pointer'
                      }}
                      onClick={() => setShowDailyVolumeSlider(false)}>
                      select option
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    alignItems={'flex-start'}
                    flex="1 1 33%"
                    gap="space-xs"
                    justifyContent={'center'}>
                    <Flex
                      gap="space-2xs"
                      justifyContent={'center'}
                      alignItems={'center'}>
                      <label className="text-2">
                        {formatCurrency(dailyVolume)}
                      </label>
                      <label className="text-1 text-muted">daily volume</label>
                    </Flex>
                    <Select
                      placeholder="Select Volume"
                      options={[
                        ...Object.keys(initialValues.dailyVolumeOptions).map(
                          (key) => ({
                            label: snakeToSpaceCase(key),
                            value: String(initialValues.dailyVolumeOptions[key])
                          })
                        ),
                        { label: 'Custom', value: 'custom' }
                      ]}
                      onChange={handleDailyVolumeChange}
                      className={classnames(
                        styles.lpPositionEstimator,
                        styles.select,
                        styles.focused
                      )}
                    />
                  </Flex>
                )}
              </Flex>

              <Flex
                justifyContent={'space-between'}
                gap="space-s"
                direction={['row', 'column', 'column']}
                alignItems={['flex-start', 'flex-start', 'center']}>
                <Box flex="1 1 33%">
                  <Slider
                    label="days to Close"
                    value={daysToClose}
                    onChange={handleDaysToCloseChange}
                    min={1}
                    max={365}
                  />
                </Box>

                <Flex
                  flex="1 1 33%"
                  direction="column"
                  gap="space-3xs"
                  alignItems={'center'}>
                  <Slider
                    label="Expected Avg. Variable Rate"
                    value={avgVarRate}
                    onChange={handleAvgVarRateChange}
                    isPercentage={true}
                    min={0.01}
                    max={100}
                    step={'0.01'}
                  />
                  <Text className="text-1 text-muted">
                    Current Var. Rate: {initialValues.currentRates['var_apy']}%
                  </Text>
                </Flex>

                <Flex
                  flex="1 1 33%"
                  direction="column"
                  gap="space-3xs"
                  alignItems={'center'}>
                  <Slider
                    label="Expected Fixed Rate at Close"
                    value={rateAtClose}
                    onChange={handleRateAtCloseChange}
                    isPercentage={true}
                    min={0.01}
                    max={100}
                    step={'0.01'}
                  />
                  <Text className="text-1 text-muted">
                    Current Fixed Rate:{' '}
                    {initialValues.currentRates['fixed_rate']}%
                  </Text>
                </Flex>
              </Flex>
            </Flex>

            <ApplyButton
              type="submit"
              disabled={!isFormUpdated || !formIsValid}>
              {loading ? 'Loading...' : 'Apply'}
            </ApplyButton>
          </Flex>
        </Flex>
      ) : (
        <Flex
          justifyContent={'space-between'}
          gap="space-m"
          direction={['row', 'column', 'column']}
          backgroundColor="color-background"
          borderRadius="size--4"
          padding={'space-xs'}
          alignItems={['flex-start', 'flex-start', 'flex-start']}>
          <Flex
            direction="column"
            width={'100%'}
            gap="space-xs"
            alignItems={'flex-start'}>
            <Flex
              gap="space-3xs"
              justifyContent={'center'}
              alignItems={'center'}>
              <label className="text-2">
                {formatCurrency(isNaN(positionSize) ? 0 : positionSize)}
              </label>
              <label className="text-1 text-muted">Position Size</label>
            </Flex>

            <Input
              type="number"
              step="1"
              min={1}
              required
              value={positionSize}
              onChange={handlePositionSizeChange}
            />
          </Flex>

          <Slider
            label="days to Close"
            value={daysToClose}
            onChange={handleDaysToCloseChange}
            min={1}
            max={365}
          />

          <Flex
            width={'100%'}
            direction="column"
            gap="space-3xs"
            alignItems={'center'}>
            <Slider
              label="Expected Average LP APY"
              value={avgLpApy}
              onChange={handleAvgLpApyChange}
              isPercentage={true}
              min={0.01}
              max={100}
              step={'0.01'}
            />
            <Text className="text-1 text-muted">
              Current LP APY: {initialValues.currentRates['lp_apy']}%
            </Text>
          </Flex>

          <ApplyButton type="submit" disabled={!isFormUpdated}>
            {loading ? 'Loading...' : 'Apply'}
          </ApplyButton>
        </Flex>
      )}
    </form>
  );
}
