import { useApi } from '@/api';
import { ArrowRight, Divider, Link, Page } from '@/components';
import DoubleSymbol from '@/components/DoubleSymbol/DoubleSymbol';
import KpiCurrencyCard from '@/components/Kpi/KpiCurrencyCard';
import KpiPercentageCard from '@/components/Kpi/KpiPercentageCard';
import PercentageBar from '@/components/PercentageBar/PercentageBar';
import { useSearchParams } from '@/hooks';
import { generateMarketName } from '@/utils/marketNameUtil';
import {
  CryptoIcon,
  Flex,
  Kpi,
  KpiBody,
  KpiTitle,
  PercentageValue
} from '@blockanalitica/ui';
import { useParams } from 'react-router-dom';
import styles from './MarketDetailsPage.module.scss';
import ChartTabs from './components/ChartTabs';
import NewPositionPnlEstimatorTabs from './components/NewPositionPnlEstimator/NewPositionPnlEstimatorTabs';
export default function MarketDetailsPage() {
  const { marketId } = useParams();
  const [daysAgo] = useSearchParams('days_ago', '7');
  const [data, error] = useApi({
    url: `/markets/${marketId}`,
    queryParams: {
      days_ago: daysAgo
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const titleText = generateMarketName(
      data.asset_symbol,
      data.yield_name,
      data.maturity_time
    );

    let yieldIcon = data.yield_source ? data.yield_source : '';
    return (
      <Page>
        <Flex justifyContent={'space-between'}>
          <Flex gap="space-3xs">
            <DoubleSymbol
              symbol1={
                <CryptoIcon
                  name={data.asset_symbol}
                  size={40}
                  extension="png"
                />
              }
              symbol2={
                <CryptoIcon name={yieldIcon} size={40} extension="png" />
              }
            />
            <h2>{titleText}</h2>
          </Flex>
        </Flex>
        <Flex direction="column" gap="space-xs">
          <Flex direction={['row', 'column', 'column']} gap="space-xs">
            <Flex flexBasis={['33%', '100%', '100%']}>
              <KpiCurrencyCard
                title={`hy${data.asset_symbol} Price`}
                data={data}
                field="share_price"
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2',
                  iconSize: 'large'
                }}
              />
            </Flex>
            <Flex flexBasis={['33%', '100%', '100%']}>
              <KpiCurrencyCard
                title="Trading Volume (All Time)"
                data={data}
                field={`volume_365d`}
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2',
                  iconSize: 'large'
                }}
              />
            </Flex>
            <Flex flexBasis={['33%', '100%', '100%']}>
              <Kpi>
                <KpiTitle className="text-muted text-2">Skew</KpiTitle>
                <KpiBody direction={'column'} gap="space-2xs">
                  <PercentageBar value={data.market_net_long} />
                  <Flex justifyContent={'space-between'}>
                    <span
                      className="text-2 text-mono"
                      style={{ color: 'var(--color-primary)' }}>
                      <Flex gap="space-3xs">
                        <PercentageValue value={data.market_net_long} /> Long
                      </Flex>
                    </span>
                    <span
                      className="text-2 text-mono"
                      style={{ color: 'var(--color-short)' }}>
                      <Flex gap="space-3xs">
                        <PercentageValue
                          value={
                            data.market_net_long > 0
                              ? 1 - data.market_net_long
                              : 0
                          }
                        />
                        Short
                      </Flex>
                    </span>
                  </Flex>
                </KpiBody>
              </Kpi>
            </Flex>
          </Flex>
          <Flex direction={['row', 'column', 'column']} gap="space-xs">
            <Flex flexBasis={['100%', '100%', '100%']} gap="space-xs">
              <KpiPercentageCard
                title="Fixed APR"
                data={data}
                field="fixed_rate"
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2',
                  fractionDigits: 2
                }}
              />
              <KpiPercentageCard
                title={'Variable APY'}
                data={data}
                field={'var_rate_24h'}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2',
                  fractionDigits: 2
                }}
              />
              <KpiPercentageCard
                title={'LP APY'}
                data={data}
                field={'lp_rate_24h'}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2',
                  fractionDigits: 2
                }}
              />
            </Flex>
          </Flex>
        </Flex>

        <Divider />

        <ChartTabs
          currency={data.asset_symbol}
          daysAgo={Number(daysAgo)}
          trading={data[`volume_${daysAgo}d`]}
        />
        <Divider />
        <Flex direction={['row', 'column', 'column']} gap="space-xs">
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Link
              daysAgo={true}
              to={`/markets/${marketId}/lp`}
              className={styles.wrapper}>
              <KpiCurrencyCard
                title={'Liquidity'}
                data={data}
                field="liquidity"
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2',
                  iconSize: 'large'
                }}
                optionalContentTop={<ArrowRight size={20} />}
                optionalContentBottom="view LPs"
              />
            </Link>
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Link
              daysAgo={true}
              to={`/markets/${marketId}/long`}
              className={styles.wrapper}>
              <KpiCurrencyCard
                title="Open Longs"
                data={data}
                field="total_long"
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2',
                  iconSize: 'large'
                }}
                optionalContentTop={<ArrowRight size={20} />}
                optionalContentBottom="view longs"
              />
            </Link>
          </Flex>
          <Flex flexBasis={['33%', '100%', '100%']}>
            <Link
              daysAgo={true}
              to={`/markets/${marketId}/short`}
              className={styles.wrapper}>
              <KpiCurrencyCard
                title="Open Shorts"
                data={data}
                field="total_short"
                currency={data.asset_symbol}
                options={{
                  valueSize: 'text-5',
                  trendSize: 'text-3',
                  footerSize: 'text-2',
                  iconSize: 'large'
                }}
                optionalContentTop={<ArrowRight size={20} />}
                optionalContentBottom="view shorts"
              />
            </Link>
          </Flex>
        </Flex>
        <Divider />
        <NewPositionPnlEstimatorTabs currency={data.asset_symbol} />
      </Page>
    );
  }

  return null;
}
