import { Flex, Text, formatCurrency } from '@blockanalitica/ui';
import { useCallback, useEffect, useState } from 'react';
import { ShortMarketStat } from '../NewShortPositionPnlEsimator';
import { ApplyButton, Input, Slider } from '@/components/forms';

export type NewShortPositionPnlEstimatorFormValues = {
  daysToClose: string | number;
  rateAtClose: string | number;
  positionSize: string | number;
  avgVarRate: string | number;
};

type NewShortPositionPnlEstimatorFormProps = {
  loading: boolean;
  maturityTime: number;
  onSubmit: (values: Record<string, string>) => void;
  currentRates: ShortMarketStat[];
  initialValues?: NewShortPositionPnlEstimatorFormValues;
};

export default function NewShortPositionPnlEstimatorForm({
  loading,
  maturityTime,
  onSubmit,
  currentRates,
  initialValues
}: NewShortPositionPnlEstimatorFormProps) {
  const [daysToClose, setDaysToClose] = useState<number>(
    Number(initialValues.daysToClose ?? 1)
  );
  const [rateAtClose, setRateAtClose] = useState<number>(
    Number(initialValues.rateAtClose ?? 0.01)
  );
  const [positionSize, setPositionSize] = useState<number>(
    Number(initialValues.rateAtClose ?? 1)
  );
  const [avgVarRate, setAvgVarRate] = useState<number>(
    Number(initialValues.avgVarRate ?? 0.01)
  );
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);

  useEffect(() => {
    const daysToCloseValidation = !isNaN(daysToClose) && daysToClose >= 1;
    const rateAtCloseValidation = !isNaN(rateAtClose) && rateAtClose >= 0.01;
    const positionSizeValidation = !isNaN(positionSize) && positionSize >= 1;

    if (
      daysToCloseValidation &&
      rateAtCloseValidation &&
      positionSizeValidation
    ) {
      setFormIsValid(true);
      return;
    }

    setFormIsValid(false);
  }, [daysToClose, positionSize, rateAtClose]);

  const handleDaysToCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDaysToClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handleRateAtCloseChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRateAtClose(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );

  const handlePositionSizeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      if (value === '' || parseFloat(value) >= 1) {
        setPositionSize(parseFloat(event.target.value));
      }
      setIsFormUpdated(true);
    },
    []
  );

  const handleAvgVarRateChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setAvgVarRate(Number(event.target.value));
      setIsFormUpdated(true);
    },
    []
  );
  const submit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      onSubmit({
        days_to_close:
          String(daysToClose) !== String(initialValues.daysToClose)
            ? String(daysToClose)
            : undefined,
        rate_at_close:
          String(rateAtClose) !== String(initialValues.rateAtClose)
            ? String(rateAtClose)
            : undefined,
        position_size:
          String(positionSize) !== String(initialValues.positionSize)
            ? String(positionSize)
            : undefined,
        avg_var_rate:
          String(avgVarRate) !== String(initialValues.avgVarRate)
            ? String(avgVarRate)
            : undefined
      });

      setIsFormUpdated(false);
    },
    [
      positionSize,
      daysToClose,
      avgVarRate,
      initialValues.positionSize,
      initialValues.daysToClose,
      initialValues.rateAtClose,
      initialValues.avgVarRate,
      onSubmit,
      rateAtClose
    ]
  );

  return (
    <form onSubmit={submit}>
      <Flex
        justifyContent={'space-between'}
        gap="space-m"
        direction={['row', 'column', 'column']}
        backgroundColor="color-background"
        borderRadius="size--4"
        padding={'space-xs'}
        alignItems={'flex-start'}>
        <Flex
          direction="column"
          width={'100%'}
          gap="space-xs"
          alignItems={'flex-start'}>
          <Flex
            gap="space-3xs"
            justifyContent={'flex-start'}
            alignItems={'center'}>
            <label className="text-2">
              {formatCurrency(isNaN(positionSize) ? 0 : positionSize)}
            </label>
            <label className="text-1 text-muted">Bond Value</label>
          </Flex>

          <Input
            type="number"
            step="1"
            min={1}
            required
            value={positionSize}
            onChange={handlePositionSizeChange}
          />
        </Flex>

        <Slider
          label="days to Close"
          value={daysToClose}
          onChange={handleDaysToCloseChange}
          min={1}
          max={maturityTime}
        />

        <Flex
          width={'100%'}
          direction="column"
          gap="space-2xs"
          alignItems={'center'}>
          <Slider
            label="Expected Avg. Variable Rate"
            value={avgVarRate}
            onChange={handleAvgVarRateChange}
            min={0.01}
            max={100}
            isPercentage={true}
            step={'0.01'}
          />
          <Text className="text-1 text-muted">
            Current Var. Rate: {currentRates?.['var_apy']}%
          </Text>
        </Flex>

        <Flex
          width={'100%'}
          direction="column"
          gap="space-2xs"
          alignItems={'center'}>
          <Slider
            label="Expected Fixed Rate at Close"
            value={rateAtClose}
            onChange={handleRateAtCloseChange}
            min={0.01}
            max={100}
            isPercentage={true}
            step={'0.01'}
            disabled={daysToClose === Number(maturityTime)}
          />
          <Text className="text-1 text-muted">
            Current Fixed Rate: {currentRates?.['fixed_rate']}%
          </Text>
        </Flex>

        <ApplyButton type="submit" disabled={!isFormUpdated || !formIsValid}>
          {loading ? 'Loading...' : 'Apply'}
        </ApplyButton>
      </Flex>
    </form>
  );
}
