import styled from 'styled-components';

const ApplyButton = styled.button`
  padding: var(--space-2xs);
  background-color: ${({ disabled }) =>
    disabled ? 'var(--color-background)' : 'var(--color-primary)'};
  color: ${({ disabled }) =>
    disabled ? 'var(--color-primary)' : 'var(--color-background)'};
  border-radius: var(--size--4);
  border: 1px solid var(--color-primary);
  opacity: 0.7;
  cursor: pointer;
  align-self: flex-end;

  &:hover {
    opacity: 1;
  }

  &:disabled {
    &:hover {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
`;

export default ApplyButton;
