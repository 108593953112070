import { Page } from '@/components';
import WalletsTable from './components/WalletsTable';
import { useSearchParams } from '@/hooks';
import { SearchInput } from '@/components/forms';
import { Flex, WalletIcon } from '@blockanalitica/ui';

export default function WalletsPage() {
  const [searchText, setSearchText] = useSearchParams('search', '');
  //   const { filters } = useTableProps({
  //     initialFilters: { currency: 'TOKEN' },
  //     filtersDebounceTime: 50
  //   });

  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <WalletIcon />
          <h1>Wallets</h1>
        </Flex>
      }>
      <Flex justifyContent="flex-end">
        <SearchInput
          placeholder="Search by address"
          defaultValue={searchText}
          onValueChange={(value) => setSearchText(value)}
        />
      </Flex>
      <WalletsTable searchText={searchText} />
    </Page>
  );
}
