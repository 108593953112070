import { ErrorBoundary, FixedSidebarLayout as Layout } from '@/components';
import OverallLayout from '@/components/layouts/OverallLayout';
import {
  ActivityPage,
  EventsPage,
  HomePage,
  LPPage,
  LongPage,
  MarketDetailsPage,
  MarketEventsPage,
  MarketPositionsWalletAddressLPPage,
  MarketPositionsWalletAddressPage,
  MarketPositionsWalletAddressTypePage,
  MarketsPage,
  PositionsPage,
  ShortPage,
  TokenDetailsPage,
  TokensPage,
  WalletPage,
  WalletsPage
} from '@/pages';
import FixedRateBorrowPage from '@/pages/fixed-rate-borrow';
import FRBMarketsPage from '@/pages/fixed-rate-borrow/markets';
import FRBMarketPage from '@/pages/fixed-rate-borrow/markets/market';
import HyperdriveOverall from '@/pages/HyperdriveOverall';
import * as Sentry from '@sentry/react';
import { createBrowserRouter } from 'react-router-dom';
import FilteredPositionsPage from '../pages/positions/components/FilteredPositionsPage';
import NotFound from './NotFound';
import ValidateNetwork from './ValidateNetwork';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    errorElement: (
      <Layout>
        <ErrorBoundary />
      </Layout>
    ),
    children: [
      {
        element: <OverallLayout />,
        children: [
          {
            path: '/',
            element: <HyperdriveOverall />
          }
        ]
      },
      {
        path: '/:network',
        element: <ValidateNetwork />,
        children: [
          {
            index: true,
            element: <HomePage />
          },
          {
            path: 'markets',
            element: <MarketsPage />
          },
          {
            path: 'markets/:marketId',
            element: <MarketDetailsPage />
          },
          {
            path: 'markets/:marketId/long',
            element: <LongPage />
          },
          {
            path: 'markets/:marketId/short',
            element: <ShortPage />
          },
          {
            path: 'markets/:marketId/lp',
            element: <LPPage />
          },
          {
            path: 'markets/:marketId/events',
            element: <MarketEventsPage />
          },
          {
            path: 'markets/:marketId/positions/:walletAddress',
            element: <MarketPositionsWalletAddressPage />
          },

          {
            path: 'markets/:marketId/positions/:walletAddress/lp',
            element: <MarketPositionsWalletAddressLPPage />
          },
          {
            path: 'markets/:marketId/positions/:walletAddress/:positionType',
            element: <MarketPositionsWalletAddressTypePage />
          },
          {
            path: 'wallets',
            element: <WalletsPage />
          },
          {
            path: 'wallets/:walletAddress',
            element: <WalletPage />
          },
          {
            path: 'positions',
            element: <PositionsPage />
          },
          {
            path: 'activity',
            element: <ActivityPage />
          },
          {
            path: 'activity/events',
            element: <EventsPage />
          },
          {
            path: 'tokens',
            element: <TokensPage />
          },
          {
            path: 'tokens/:asset_address',
            element: <TokenDetailsPage />
          },
          {
            path: 'markets/:marketId/positions',
            element: <FilteredPositionsPage />
          },
          {
            path: 'fixed-borrow',
            element: <FixedRateBorrowPage />
          },
          {
            path: 'fixed-borrow/markets',
            element: <FRBMarketsPage />
          },
          {
            path: 'fixed-borrow/markets/:marketUid',
            element: <FRBMarketPage />
          },
          {
            path: '*',
            //@ts-ignore
            element: <NotFound />
          }
        ]
      }
    ]
  }
]);

export default router;
