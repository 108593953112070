import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import { useSearchParams } from '@/hooks';
import { CurrencyFilter, Flex, useTableProps } from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import LPTable from './components/LPTable';
import PositionsTable from './components/PositionsTable';

const displayOptions = [
  { key: 'long', value: 'Long' },
  { key: 'short', value: 'Short' },
  { key: 'lp', value: 'LP' }
];
export default function WalletPositions() {
  const { walletAddress } = useParams();
  const [daysAgo] = useSearchParams('days_ago', '7');
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'long'
  );
  const { filters, sorting, pagination } = useTableProps({
    initialFilters: { state: 'open', currency: 'token' },
    filtersDebounceTime: 50,
    pageSize: 5
  });

  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const tabsContent = useMemo(
    () => ({
      long: (
        <PositionsTable
          daysAgo={daysAgo}
          activeTab={activeDisplayOption}
          walletAddress={walletAddress}
          sorting={sorting}
          pagination={pagination}
          filters={filters}
        />
      ),
      short: (
        <PositionsTable
          daysAgo={daysAgo}
          activeTab={activeDisplayOption}
          walletAddress={walletAddress}
          sorting={sorting}
          pagination={pagination}
          filters={filters}
        />
      ),
      lp: (
        <LPTable
          daysAgo={daysAgo}
          walletAddress={walletAddress}
          sorting={sorting}
          pagination={pagination}
          filters={filters}
        />
      )
    }),
    [activeDisplayOption, daysAgo, walletAddress, sorting, pagination, filters]
  );

  return (
    <Flex direction={['column']} gap={'space-xs'}>
      <Flex>
        <DisplaySwitch
          options={displayOptions}
          activeOption={activeDisplayOption}
          onChange={handleTabClick}
        />
      </Flex>
      <Flex
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap="space-xs">
        <Flex>
          <h4>Positions</h4>
        </Flex>
        <Flex gap={'space-xs'}>
          <Flex>
            {activeDisplayOption != 'lp' && (
              <CurrencyFilter
                key="open-closed"
                width="8rem"
                height="auto"
                style={{ minHeight: '1.6rem' }}
                currency1={{ value: 'open', displayValue: 'OPEN' }}
                currency2={{ value: 'closed', displayValue: 'CLOSED' }}
                filterField="state"
                {...filters}
              />
            )}
          </Flex>
          <Flex>
            <CurrencyFilter
              key="currency-filter"
              width="8rem"
              height="auto"
              style={{ minHeight: '1.6rem' }}
              currency1={{ value: 'token', displayValue: 'TOKEN' }}
              currency2={{ value: 'USD', displayValue: 'USD' }}
              {...filters}
            />
          </Flex>
        </Flex>
      </Flex>
      {tabsContent[activeDisplayOption]}
    </Flex>
  );
}
