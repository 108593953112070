import { Page } from '@/components';
import { Flex } from '@blockanalitica/ui';
import EventsTable from './components/EventsTable';

export default function EventsPage() {
  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <h1>Protocol Events</h1>
        </Flex>
      }
      isTimeSwitch={false}>
      <EventsTable />
    </Page>
  );
}
