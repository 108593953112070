export const EVENT_TYPE_MAPPING = {
  OpenLong: 'Open Long',
  CloseLong: 'Close Long',
  OpenShort: 'Open Short',
  CloseShort: 'Close Short',
  AddLiquidity: 'Add Liquidity',
  RemoveLiquidity: 'Remove Liquidity'
};

export const POSITION_EVENTS = [
  'OpenLong',
  'CloseLong',
  'OpenShort',
  'CloseShort'
];

export const LP_EVENTS = ['AddLiquidity', 'RemoveLiquidity'];

export const ALL_EVENTS = [...POSITION_EVENTS, ...LP_EVENTS];

export const NETWORKS = ['ethereum', 'gnosis', 'linea', 'base'];

export const DEFAULT_NETWORK = 'ethereum';
