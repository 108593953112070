import { Flex, getResponsiveStyles } from '@blockanalitica/ui';
import { FlexProps } from '@blockanalitica/ui/package/esm/src/components/server-components/layout/Flex/Flex';
import { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Flex)<FlexProps & { isStuck: boolean }>`
  position: sticky;
  ${getResponsiveStyles([0, 0, 0, 84], 'top', (value) => value + 'px')}

  background-color: ${(props) =>
    props.isStuck ? 'var(--color-onSurface)' : undefined};
  padding: ${(props) =>
    props.isStuck ? 'var(--space-xs) 0' : 'var(--space-xs) 0'};
  z-index: 10;
`;

export default function PageHeader({ children }) {
  const [isHeaderStuck, setIsHeaderStuck] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrollTop = window.scrollY > 20;

      setIsHeaderStuck(isScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Wrapper
      alignSelf={['stretch', 'center']}
      width={['unset', '100%']}
      id="header"
      isStuck={isHeaderStuck}
      gap="space-3xs">
      {children}
    </Wrapper>
  );
}
