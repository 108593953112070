import { formatCurrency } from '@/utils';
import {
  BarChart as BarChartComponent,
  CurrencyValue,
  EmptyBox,
  formatDatetime
} from '@blockanalitica/ui';
import { EmptyBoxProps } from '@blockanalitica/ui/package/esm/src/components/client-components/EmptyBox/EmptyBox';
import { useMemo } from 'react';

type Field = {
  label: string;
  field: string;
  backgroundColor: string | number;
};

type BarChartData = {
  date: string;
} & {
  [key in string as Exclude<key, 'date'>]: number;
};

type BarChartOptions = {
  emptyBoxProps?: EmptyBoxProps;
  dynamic?: boolean;
  mode?: string;
  plugins?: string;
  defaultValue?: string;
  defaultSubValue?: string;
};

interface BarChartProps {
  data: BarChartData[];
  fields: Field[];
  currency: string;
  switchValue?: string;
  aspectRatio?: number;
  daysAgo?: number;
  options?: BarChartOptions;
}

function darkenHex(hex: string, percent: number): string {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  const adjustment = 1 - percent / 100;

  r = Math.floor(r * adjustment);
  g = Math.floor(g * adjustment);
  b = Math.floor(b * adjustment);

  return (
    '#' +
    r.toString(16).padStart(2, '0') +
    g.toString(16).padStart(2, '0') +
    b.toString(16).padStart(2, '0')
  );
}

export default function CurrencySwitchBarChart({
  data,
  fields,
  currency,
  switchValue,
  aspectRatio = 4,
  options,
  daysAgo = 30
}: BarChartProps) {
  let unit = 'week';
  let dateFormat = 'MMM d';
  let dateFormatKPI = 'MMM d, yyyy';
  switch (Number(daysAgo)) {
    case 1:
      unit = 'hour';
      dateFormat = 'H:mm';
      dateFormatKPI = 'MMM d H:mm';
      break;
    case 7:
      unit = 'day';
      break;
    case 365:
      unit = 'week';
      dateFormat = 'MMM d';
      break;
  }
  const chartData = useMemo(() => {
    if (data.length) {
      return {
        datasets: fields.map((fieldInfo) => {
          const fieldName = switchValue
            ? switchValue === 'USD'
              ? `${fieldInfo.field}_usd`
              : fieldInfo.field
            : fieldInfo.field;
          const label = switchValue
            ? switchValue === 'USD'
              ? `${fieldInfo.label} (USD)`
              : fieldInfo.label
            : fieldInfo.label;

          return {
            label: label,
            data: data.map((item) => ({
              x: Number(daysAgo) === 1 ? item.datetime : item.date,
              y: item[fieldName],
              label: label
            })),
            backgroundColor: String(fieldInfo.backgroundColor),
            hoverBackgroundColor: darkenHex(
              String(fieldInfo.backgroundColor),
              50
            )
          };
        })
      };
    }

    return null;
  }, [data, fields, daysAgo, switchValue]);

  const kpisConfig = useMemo(() => {
    const valueFormatter = (value: number) =>
      switchValue && switchValue == 'token' ? (
        <span className="text-mono">
          <CurrencyValue
            value={value}
            options={{ currency: currency, dynamic: options?.dynamic ?? false }}
          />
        </span>
      ) : (
        <span className="text-mono">
          <CurrencyValue
            value={value}
            options={{ currency: 'USD', dynamic: options?.dynamic ?? false }}
          />
        </span>
      );

    if (chartData) {
      const defaultData = chartData.datasets.map((ds) => ({
        y:
          options?.defaultValue !== undefined
            ? options?.defaultValue
            : ds.data[ds.data.length - 1].y,
        x:
          options?.defaultValue !== undefined
            ? 'custom'
            : ds.data[ds.data.length - 1].x,
        label: ds.data[ds.data.length - 1].label
      }));
      return {
        valueFormatter: valueFormatter,
        subValueFormatter: (value: string | number) => (
          <span>
            {value === 'custom'
              ? options?.defaultSubValue
              : formatDatetime(value, { format: dateFormatKPI })}
          </span>
        ),
        defaultData: defaultData
      };
    }

    return null;
  }, [chartData, switchValue, currency, options, dateFormatKPI]);

  const config = useMemo(
    () => ({
      options: {
        aspectRatio: aspectRatio,
        // @ts-ignore
        onResize: (chart) => {
          const ratio = chart.config.options.aspectRatio;
          if (window.innerWidth < 576 && ratio !== 1.5) {
            chart.config.options.oldAspectRatio = ratio;
            chart.config.options.aspectRatio = 1.5;
          } else if (
            window.innerWidth >= 576 &&
            chart.config.options.oldAspectRatio > 0 &&
            ratio !== chart.config.options.oldAspectRatio
          ) {
            chart.config.options.aspectRatio =
              chart.config.options.oldAspectRatio;
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: unit
            },
            ticks: {
              callback: (value: number) =>
                formatDatetime(value, {
                  format: dateFormat
                })
            }
          },
          y: {
            type: 'linear',
            beginAtZero: true,
            ticks: {
              format: { notation: 'compact' },
              callback: (value: number) =>
                switchValue == 'token'
                  ? formatCurrency(value, currency)
                  : formatCurrency(value, 'USD')
            }
          }
        },
        interaction: {
          mode: options?.mode ?? 'nearest'
        }
      },
      ...(options?.plugins ?? {})
    }),
    [aspectRatio, switchValue, currency, options, unit, dateFormat]
  );

  if (chartData && kpisConfig) {
    return (
      <BarChartComponent
        data={chartData}
        kpisConfig={kpisConfig}
        config={config}
        key={switchValue}
      />
    );
  }

  return <EmptyBox {...options?.emptyBoxProps} />;
}
