import { Select } from '@blockanalitica/ui';
import { useCallback } from 'react';

export default function SelectFilter({
  updateFilters,
  filterField = 'selectedOption',
  placeholder,
  onPageChange,
  options
}) {
  const onValueChange = useCallback(
    (e) => {
      onPageChange(1);
      updateFilters({
        [filterField]: e.target.value
      });
    },
    [filterField, updateFilters, onPageChange]
  );

  return (
    <Select
      placeholder={placeholder}
      options={options}
      onChange={onValueChange}
      style={{ border: 'none' }}
    />
  );
}
