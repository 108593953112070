import { useApi } from '@/api';
import { Link } from '@/components';
import Table from '@/components/table/Table';
import { useSearchParams } from '@/hooks';
import {
  CryptoIcon,
  CurrencyValue,
  Flex,
  PercentageValue,
  TableLoader
} from '@blockanalitica/ui';

export default function TokensTable({ filters, sorting, pagination }) {
  const [daysAgo] = useSearchParams('days_ago', '7');

  const [data, error] = useApi({
    url: '/tokens/',
    queryParams: {
      p: String(pagination.currentPage),
      p_size: String(pagination.pageSize),
      days_ago: daysAgo,
      order: sorting.currentSorting
    }
  });

  if (error) {
    throw error;
  }

  if (data) {
    const columns = [
      {
        header: 'TOKEN',
        getCell: (row) => (
          <Flex gap="space-2xs" alignItems="center">
            <CryptoIcon name={row.asset_symbol} size="large" extension="png" />
            {row.asset_symbol}
          </Flex>
        )
      },
      {
        header: 'TVL',
        getCell: (row) => {
          const tvlValue =
            filters.filtersApplied.currency === 'USD'
              ? Number(row.tvl_usd)
              : Number(row.tvl);

          if (filters.filtersApplied.currency === 'USD') {
            if (tvlValue !== 0 && Math.abs(tvlValue) < 0.01) {
              return tvlValue < 0 ? '>$-0.01' : '<$0.01';
            }
          } else {
            if (tvlValue !== 0 && Math.abs(tvlValue) < 0.00001) {
              const val = tvlValue < 0 ? '>-0.00001' : '<0.00001';
              return (
                <Flex gap="space-3xs">
                  <CryptoIcon
                    size="xSmall"
                    name={row.asset_symbol}
                    extension="png"
                  />
                  {val}
                </Flex>
              );
            }
          }

          return (
            <Flex gap="space-s">
              <CurrencyValue
                value={tvlValue}
                options={{
                  dynamic: false,
                  currency:
                    filters.filtersApplied.currency === 'USD'
                      ? 'USD'
                      : row.asset_symbol,
                  leadingCurrencySymbol:
                    filters.filtersApplied.currency !== 'USD',
                  iconOpacity: 0.7,
                  iconSize: 'xSmall'
                }}
              />
            </Flex>
          );
        },
        getCellBottom: (row) => (
          <Flex gap="space-s">
            {filters.filtersApplied.currency === 'USD' ? (
              <PercentageValue
                value={Number(row.tvl_usd_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            ) : (
              <PercentageValue
                value={Number(row.tvl_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            )}
          </Flex>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField:
          filters.filtersApplied.currency === 'USD' ? 'tvl_usd' : 'tvl'
      },
      {
        header: 'TOTAL LONG',
        getCell: (row) => {
          const totalLongValue =
            filters.filtersApplied.currency === 'USD'
              ? Number(row.total_long_usd)
              : Number(row.total_long);

          if (filters.filtersApplied.currency === 'USD') {
            if (totalLongValue !== 0 && Math.abs(totalLongValue) < 0.01) {
              return totalLongValue < 0 ? '>$-0.01' : '<$0.01';
            }
          } else {
            if (totalLongValue !== 0 && Math.abs(totalLongValue) < 0.00001) {
              const val = totalLongValue < 0 ? '>-0.00001' : '<0.00001';
              return (
                <Flex direction={'row'} gap={'space-3xs'}>
                  <CryptoIcon
                    size={'xSmall'}
                    name={row.asset_symbol}
                    extension="png"
                  />
                  {val}
                </Flex>
              );
            }
          }

          return (
            <CurrencyValue
              value={totalLongValue}
              options={{
                dynamic: false,
                currency:
                  filters.filtersApplied.currency === 'USD'
                    ? 'USD'
                    : row.asset_symbol,
                leadingCurrencySymbol:
                  filters.filtersApplied.currency !== 'USD',
                iconOpacity: 0.7,
                iconSize: 'xSmall'
              }}
            />
          );
        },
        getCellBottom: (row) => (
          <Flex gap="space-s">
            {filters.filtersApplied.currency === 'USD' ? (
              <PercentageValue
                value={Number(row.total_long_usd_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            ) : (
              <PercentageValue
                value={Number(row.total_long_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            )}
          </Flex>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField:
          filters.filtersApplied.currency === 'USD'
            ? 'total_long_usd'
            : 'total_long'
      },
      {
        header: 'TOTAL SHORT',
        getCell: (row) => {
          const totalShortValue =
            filters.filtersApplied.currency === 'USD'
              ? Number(row.total_short_usd)
              : Number(row.total_short);

          if (filters.filtersApplied.currency === 'USD') {
            if (totalShortValue !== 0 && Math.abs(totalShortValue) < 0.01) {
              return totalShortValue < 0 ? '>$-0.01' : '<$0.01';
            }
          } else {
            if (totalShortValue !== 0 && Math.abs(totalShortValue) < 0.00001) {
              const val = totalShortValue < 0 ? '>-0.00001' : '<0.00001';
              return (
                <Flex direction={'row'} gap={'space-3xs'}>
                  <CryptoIcon
                    size={'xSmall'}
                    name={row.asset_symbol}
                    extension="png"
                  />
                  {val}
                </Flex>
              );
            }
          }

          return (
            <CurrencyValue
              value={totalShortValue}
              options={{
                dynamic: false,
                currency:
                  filters.filtersApplied.currency === 'USD'
                    ? 'USD'
                    : row.asset_symbol,
                leadingCurrencySymbol:
                  filters.filtersApplied.currency !== 'USD',
                iconOpacity: 0.7,
                iconSize: 'xSmall'
              }}
            />
          );
        },
        getCellBottom: (row) => (
          <Flex gap="space-s">
            {filters.filtersApplied.currency === 'USD' ? (
              <PercentageValue
                value={Number(row.total_short_usd_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            ) : (
              <PercentageValue
                value={Number(row.total_short_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            )}
          </Flex>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField:
          filters.filtersApplied.currency === 'USD'
            ? 'total_short_usd'
            : 'total_short'
      },
      {
        header: 'TOTAL LP',
        getCell: (row) => {
          const totalLpValue =
            filters.filtersApplied.currency === 'USD'
              ? Number(row.total_lp_usd)
              : Number(row.total_lp);

          if (filters.filtersApplied.currency === 'USD') {
            if (totalLpValue !== 0 && Math.abs(totalLpValue) < 0.01) {
              return totalLpValue < 0 ? '>$-0.01' : '<$0.01';
            }
          } else {
            if (totalLpValue !== 0 && Math.abs(totalLpValue) < 0.00001) {
              const val = totalLpValue < 0 ? '>-0.00001' : '<0.00001';
              return (
                <Flex direction={'row'} gap={'space-3xs'}>
                  <CryptoIcon
                    size={'xSmall'}
                    name={row.asset_symbol}
                    extension="png"
                  />
                  {val}
                </Flex>
              );
            }
          }

          return (
            <CurrencyValue
              value={totalLpValue}
              options={{
                dynamic: false,
                currency:
                  filters.filtersApplied.currency === 'USD'
                    ? 'USD'
                    : row.asset_symbol,
                leadingCurrencySymbol:
                  filters.filtersApplied.currency !== 'USD',
                iconOpacity: 0.7,
                iconSize: 'xSmall'
              }}
            />
          );
        },
        getCellBottom: (row) => (
          <Flex gap="space-s">
            {filters.filtersApplied.currency === 'USD' ? (
              <PercentageValue
                value={Number(row.total_lp_usd_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            ) : (
              <PercentageValue
                value={Number(row.total_lp_change_percentage)}
                options={{ trend: true, hideIfZero: true }}
              />
            )}
          </Flex>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField:
          filters.filtersApplied.currency === 'USD'
            ? 'total_lp_usd'
            : 'total_lp'
      },

      {
        header: '# OF MARKETS',
        getCell: (row) => (
          <Flex gap="space-s">
            <CurrencyValue value={row.markets} />
          </Flex>
        ),
        getCellBottom: (row) => (
          <Flex gap="space-s">
            <PercentageValue
              value={Number(row.markets_change_percentage)}
              options={{ trend: true, hideIfZero: true }}
            />
          </Flex>
        ),
        cellAlign: 'flex-end',
        headerAlign: 'flex-end',
        sortingField: 'markets'
      }
    ];

    return (
      <Table
        rows={data['results']}
        columns={columns}
        getKey={(row) => row.asset_address}
        getLinkWrapper={(row) => (
          <Link daysAgo={true} to={`${row.asset_address}`} />
        )}
        sorting={sorting}
        pagination={{
          ...pagination,
          totalRecords: data.count
        }}
      />
    );
  }
  return <TableLoader />;
}
