import { Flex } from '@blockanalitica/ui';
import styles from './FixedSidebarLayout/FixedSidebarLayout.module.scss';
// @ts-ignore
import { Outlet } from 'react-router-dom';

export default function OverallLayout({ children }) {
  return (
    <Flex
      className={styles.homeContentContainer}
      gap="space-0"
      direction="column"
      justifyContent="space-between">
      <main>{children ? children : <Outlet />}</main>
      <footer>
        <Flex justifyContent="center" paddingTop="1rem">
          <a
            href="https://blockanalitica.com/terms-of-service/"
            className="text-muted text-1"
            target="_blank"
            rel="noreferrer">
            Terms of Service
          </a>
        </Flex>
      </footer>
    </Flex>
  );
}
