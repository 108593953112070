import { ErrorBoundary } from '@/components';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';

Sentry.init({
  // @ts-ignore
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: 'production',
  integrations: [],
  tracesSampleRate: 0
});

// @ts-ignore
ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
      <App />
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
