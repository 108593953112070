import { Page } from '@/components';
import DisplaySwitch from '@/components/DisplaySwitch/DisplaySwitch';
import SelectMarket from '@/components/SelectMarket/SelectMarket';
import { SearchInput } from '@/components/forms';
import { useSearchParams } from '@/hooks';
import {
  Box,
  CurrencyFilter,
  Flex,
  Grid1X2Icon,
  useTableProps
} from '@blockanalitica/ui';
import { useCallback, useMemo } from 'react';
import LPTable from './components/LPTable';
import PositionsTable from './components/PositionsTable';

const displayOptions = [
  { key: 'long', value: 'Long' },
  { key: 'short', value: 'Short' },
  { key: 'lp', value: 'LP' }
];

export default function PositionsPage() {
  const [daysAgo] = useSearchParams('days_ago', '7');
  const [activeDisplayOption, setActiveDisplayOption] = useSearchParams(
    'tab',
    'long'
  );

  const [searchText, setSearchText] = useSearchParams('search', '');
  const { filters, sorting, pagination } = useTableProps({
    initialFilters: { state: 'open', currency: 'token' },
    filtersDebounceTime: 50,
    initialSorting: '-balance'
  });

  const handleTabClick = useCallback(
    (key) => {
      setActiveDisplayOption(key);
    },
    [setActiveDisplayOption]
  );

  const tabsContent = useMemo(
    () => ({
      long: (
        <PositionsTable
          daysAgo={daysAgo}
          searchText={searchText}
          type={activeDisplayOption}
          filters={filters}
          sorting={sorting}
          pagination={pagination}
        />
      ),
      short: (
        <PositionsTable
          daysAgo={daysAgo}
          searchText={searchText}
          type={activeDisplayOption}
          filters={filters}
          sorting={sorting}
          pagination={pagination}
        />
      ),
      lp: (
        <LPTable
          daysAgo={daysAgo}
          searchText={searchText}
          filters={filters}
          sorting={sorting}
          pagination={pagination}
        />
      )
    }),
    [activeDisplayOption, daysAgo, filters, searchText, sorting, pagination]
  );

  return (
    <Page
      title={
        <Flex gap="space-s" alignItems="center">
          <Grid1X2Icon />
          <h1>Positions</h1>
        </Flex>
      }
      filters={filters}>
      <Flex
        gap="space-xs"
        direction={['row', 'row', 'column']}
        justifyContent={['space-between', 'space-between', 'space-between']}>
        <Flex
          direction={['row', 'row', 'column']}
          alignItems={['flex-start', 'flex-start', 'flex-start']}
          justifyContent="space-between"
          alignSelf={['center', 'center', 'flex-start']}
          gap="space-xs">
          <DisplaySwitch
            options={displayOptions}
            activeOption={activeDisplayOption}
            onChange={handleTabClick}
          />
        </Flex>
        <Flex
          gap="space-xs"
          justifyContent="flex-end"
          direction={['row', 'row', 'column']}>
          <Flex justifyContent="space-between">
            {activeDisplayOption != 'lp' && (
              <CurrencyFilter
                key="open-closed"
                width="8rem"
                height="auto"
                style={{ minHeight: '1.6rem' }}
                currency1={{ value: 'open', displayValue: 'OPEN' }}
                currency2={{ value: 'closed', displayValue: 'CLOSED' }}
                filterField="state"
                {...filters}
              />
            )}
            <CurrencyFilter
              key="currency-filter"
              width="8rem"
              height="auto"
              style={{ minHeight: '1.6rem' }}
              currency1={{ value: 'token', displayValue: 'TOKEN' }}
              currency2={{ value: 'USD', displayValue: 'USD' }}
              {...filters}
            />
          </Flex>
          <Flex
            direction={['row', 'row', 'row']}
            alignItems={['flex-start', 'flex-start', 'flex-start']}
            justifyContent="space-between"
            gap="space-xs">
            <Box>
              <SearchInput
                style={{ minHeight: '1.6rem' }}
                placeholder="Search by wallet address"
                defaultValue={searchText}
                onValueChange={(value) => setSearchText(value)}
              />
            </Box>

            <SelectMarket pagination={pagination} filters={filters} />
          </Flex>
        </Flex>
      </Flex>
      {tabsContent[activeDisplayOption]}
    </Page>
  );
}
