import {
  formatNumber,
  formatCurrency as uiFormatCurrency
} from '@blockanalitica/ui';

export function formatCurrency(value: number, currency = 'USD') {
  const maximumFractionDigits = Math.abs(value) < 0.01 ? 5 : 2;

  if (currency === 'USD') {
    return uiFormatCurrency(value, {
      maximumFractionDigits: maximumFractionDigits,
      currency: currency
    });
  }

  const formatted = formatNumber(value, {
    maximumFractionDigits: maximumFractionDigits
  });

  return `${formatted} ${currency}`;
}

export function makeSeries(seriesCount: number, points: number) {
  const series = Array.from({ length: seriesCount }, () => {
    let lastValue = Math.max(250, Math.floor(Math.random() * 3000));

    return {
      data: Array.from({ length: points }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - i);

        // Small random change
        const change = (Math.random() - 0.5) * 100;

        // Occasionally introduce a larger change
        if (Math.random() < 0.2) {
          // 10% chance of a larger change
          lastValue += change * 5; // Adjust the multiplier for larger changes
        } else {
          lastValue += change;
        }

        // Ensure value stays within a reasonable range
        lastValue = Math.max(250, Math.min(lastValue, 3000));

        return {
          date: date.toISOString().split('T')[0],
          value: lastValue,
          value_usd: lastValue * 1000,
          value1: lastValue,
          value1_usd: lastValue * 1000,
          value2: lastValue,
          value2_usd: lastValue * 1000,
          value3: lastValue,
          value3_usd: lastValue * 1000,
          value4: lastValue * -1,
          value4_usd: lastValue * -1000
        };
      }).reverse()
    };
  });

  // Return only the data array if seriesCount is 1
  return seriesCount === 1 ? series[0].data : series;
}

export function makePercentSeries(seriesCount: number, points: number) {
  const series = Array.from({ length: seriesCount }, () => {
    // Initial value set within the desired range to ensure percentages are between 0.02 and 0.08
    let basePercent = Math.max(
      0.02,
      Math.min(Math.random() * 0.06 + 0.02, 0.08)
    );

    return {
      data: Array.from({ length: points }, (_, i) => {
        const date = new Date();
        date.setDate(date.getDate() - i);

        // Base change for the date
        const baseChange = (Math.random() - 0.5) * 0.01;

        // Adjust basePercent for the day
        if (Math.random() < 0.2) {
          // Larger change within limits
          basePercent += baseChange * 5;
        } else {
          basePercent += baseChange;
        }

        // Ensure basePercent stays within the specified range
        basePercent = Math.max(0.02, Math.min(basePercent, 0.08));

        // Generate unique values for each field on the same date
        const values = Array.from({ length: 4 }, (_, index) => {
          // Each subsequent value is slightly adjusted to ensure uniqueness
          const adjustment = (index + 1) * 0.001; // Small adjustment to ensure uniqueness
          const percent = Math.max(
            0.02,
            Math.min(basePercent + adjustment, 0.08)
          );
          return percent;
        });

        return {
          date: date.toISOString().split('T')[0],
          value: values[0],
          value1: values[1] - 0.007,
          value2: values[2] - 0.015,
          value3: values[3]
        };
      }).reverse()
    };
  });

  // Return only the data array if seriesCount is 1
  return seriesCount === 1 ? series[0].data : series;
}
export function generateMockData(numberOfEntries: number) {
  const createItem = () => ({
    uid: 'WETH',
    field_1: (Math.random() * 10000).toFixed(18),
    field_2: (Math.random() * 10000).toFixed(18),
    field_3: (Math.random() * 10000).toFixed(18),
    symbol: 'WETH'
  });

  return {
    count: numberOfEntries,
    next: null,

    previous: null,
    results: Array.from({ length: numberOfEntries }, createItem)
  };
}

export function snakeToSpaceCase(snakeStr) {
  const spacedStr = snakeStr.replace(/_/g, ' ');

  const spaceCaseStr = spacedStr.replace(/\b\w/g, (char) => char.toUpperCase());

  return spaceCaseStr;
}

export function transformString(str: string): string {
  return str
    .split('-') // Split the string into an array of words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
    .join(' '); // Join the words with a space
}

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(' ');
}

export const timeMapping = {
  1: '24H',
  7: '7D',
  30: '30D',
  90: '90D',
  365: '1Y'
};

export const symbolMapping = {
  ethereum: 'ETH',
  gnosis: 'GNO',
  linea: 'LINEA',
  base: 'BASE'
};
